import { Reducer, AnyAction } from 'redux';
import { SelectionState, SelectionActionTypes, Item } from './types';

// Initial state
const initialState : SelectionState = {
  providerFilter: {
    label: 'Provider',
    active: true,
    items: [
      //{active: false, id: ProviderIds.Facebook, kind: 'Toggle', label: 'Facebook', value: false},
      //{active: false, id: ProviderIds.Google, kind: 'Toggle', label: 'Google', value: false},
      //{active: false, id: ProviderIds.Instagram, kind: 'Toggle', label: 'Instagram', value: false},
      //{active: false, id: ProviderIds.Twitter, kind: 'Toggle', label: 'Twitter', value: false}
    ]
  },
  folderFilter: {
    label: 'Files',
    active: false,
    items: []
  },
  nodeFilter: {
    label: 'Details',
    active: false,
    items: [
      //{active: false, id: 'x_range', kind: 'Range', type: 'date', label: 'X Range', value: false, start: 0, end: 0},
      //{active: true, id: 'y_range', kind: 'Range', type: 'time', label: 'Day Selection', value: 0},
      //{active: false, id: 'lasso', kind: 'Lasso', label: 'Lasso', value: false, items: [], amount: 0}
    ]
  },
  currentVis: 'default',
  currentColor: 'provider',
  currentColors: [],
  currentSelection: null,
  currentElement: null,
};


function updateObjectInArray(array : Item[], id : string, value: any) {
  return array.map((item) => {
    if (item.id !== id) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...{value: value === undefined ? !item.value : value, active: true}
    };
  });
}

function setValuesInArrayTo(array : Item[], value : boolean) {
  return array.map((item) => {
    return {
      ...item,
      ...{value: value, active: true}
    };
  });
}

// The reducer function
const reducer: Reducer<SelectionState> = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SelectionActionTypes.SELECT: {
      // actually handles select and deselect currently
      const {filter, id, value} = action.payload;
      switch(filter){
        case state.providerFilter.label:
          return Object.assign({}, state, {providerFilter : {...state.providerFilter,
            items: updateObjectInArray(state.providerFilter.items, id, value)}});
        case state.folderFilter.label:
          return Object.assign({}, state, {folderFilter : {...state.folderFilter,
            items: updateObjectInArray(state.folderFilter.items, id, value)}});
        case state.nodeFilter.label:
          return Object.assign({}, state, {nodeFilter : {...state.nodeFilter,
            items: updateObjectInArray(state.nodeFilter.items, id, value)}});
        default:
          throw new Error('Unkown filter label for SelectionActionTypes.SELECT: ' + filter);
      }
    }
    case SelectionActionTypes.DESELECT: {
      return Object.assign({}, state, {});
    }
    case SelectionActionTypes.SET_ALL_FOLDERS: {
      // handles deselect all folders and select all folders
      const {value} = action.payload;
      return Object.assign({}, state, {folderFilter: {
        ...state.folderFilter, items: setValuesInArrayTo(state.folderFilter.items, value)
      }});
    }
    case SelectionActionTypes.SET_FOLDERS: {
      /*

      // updates the list of folders according to the current selected providers
      const provider = action.payload;

      let labels : string[] = [];

      state.providerFilter.items.forEach(item => {

        // is item selected?
        if(item.value){
          provider.forEach((providerData : ProviderData) => {

            // find matching provider for filter
            if(providerData.id === item.id){

              // which grouping method for provider?
              if(providerData.groupFilesBy === 'root'){
                labels = labels.concat(providerData.children
                  //.filter((file : ProviderFile) => file.data.length > 0)
                  .map(d => d.folder === 'root' ? d.fileName : d.folder));
              }else if(providerData.groupFilesBy === 'folder'){
                labels = labels.concat(providerData.children
                  //.filter((file : ProviderFile) => file.data.length > 0)
                  .map(d => d.folder));
              }
            }
          });
        }
      });

      // get unique names and create Items
      let result : Item[] = Array.from(new Set(labels))
        .sort((a,b) => a.localeCompare(b))
        .map(d => {
          return {active: true, id: d, kind: "Toggle", label: d, value: true};
        });
        */

      return Object.assign({}, state, {folderFilter: { ...state.folderFilter, items: []}});
    }

    default: {
      return state;
    }
  }
};

export { reducer as selectionReducer };