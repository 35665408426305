import { ProviderDefinition } from './types';
import { ProcessFile, UnparsedFile, ProviderParser } from './types';

import Instagram from './instagram/Instagram';

import DefaultDefinition from './default/strings.json';
import InstagramDefinition from './instagram/strings.json';

export function getProviders(): [ProviderDefinition, ProviderParser][] {
  return [
    [InstagramDefinition, Instagram],
  ];
}

export function getProvider(id: string): ProviderDefinition {
  const list = getProviders();
  for (const [def, _] of list) {
    if (def.id === id)
      return def;
  }
  if (id === 'sample') {
    return DefaultDefinition;
  }
  throw Error('Unkown Provider Id given: ' + id);
}

export function findDataProvider(source: UnparsedFile[], callback: (message: string) => void): [ProviderDefinition, ProcessFile] | null {
  const e = -0.1;
  const topLevels = source
    .map(d => d.path.toLowerCase())
    .map(d => d.substr(0, d.indexOf('/') < 0 ? d.length : d.indexOf('/') + 1));
  const uniquePaths = [... new Set(topLevels)];
  let relevance;
  let best = 0;
  let result = null;
  const k = 1 / uniquePaths.length;

  console.log('uniquePaths', uniquePaths);

  for (const [provider, parser] of getProviders()) {
    relevance = 0.0;
    for (let j = 0; j < uniquePaths.length; j++) {
      if (parser.fileStructure.includes(uniquePaths[j])) {
        relevance += k;
      }
    }
    callback('Files and folders are ' + Math.floor(relevance * 100) + '% matching with parser for ' + provider.name);
    //+ " (" + provider.files + " against " +  uniquePaths + ").");

    if (relevance > e && relevance > best) {
      result = [provider, parser.processFile] as [ProviderDefinition, ProcessFile];
      best = relevance;
    }
  }
  return result;
}