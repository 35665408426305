import React from 'react';

// Component-specific props.
interface ComponentProps {
  color: string,
  size: number | string,
  margin: number | string,
  active?: boolean
}

export default function Node(props : ComponentProps){
  return <div style={{
    width: props.size + 'px',
    height: props.size + 'px',
    marginRight: props.margin + 'px',
    borderRadius: '50%',
    backgroundColor: !props.active ? undefined  : props.color,
    border: !props.active ? 'solid 2px white' : undefined
  }}
  />;
}

Node.defaultProps= {
  size: 20,
  margin: 2,
  active: true
};