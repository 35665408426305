import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Theme } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Clear from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

import { ApplicationState } from 'store/index';
import { search } from 'store/options/actions';
import useLanguageHook from 'containers/App/useLanguageHook';


const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const SearchField: FC = () => {
  const searchString = useSelector((state: ApplicationState) => state.options.searchString);
  const dispatch = useDispatch();
  const { t } = useLanguageHook();

  return (
    <Box>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          value={searchString}
          placeholder={t.Vis.Search}
          inputProps={{ 'aria-label': t.Vis.Search }}
          onChange={d => dispatch(search(d.target.value))}
        />
        <Tooltip title={t.Vis.ClearSearch}>
          <Button onClick={() => dispatch(search(''))}>
            <Clear />
          </Button>
        </Tooltip>
      </Search>
    </Box>
  );
};

export default SearchField;
