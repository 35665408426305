import React, { Component, FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import * as d3 from 'd3';
import { HierarchyRectangularNode } from 'd3';

import { ApplicationState } from '@store/index';

import { Category, DataPoint, ProviderFile } from 'providers/types';
import { catColor } from 'constants/color';
import { Group, groupColor, GroupMapping } from './group';
import TimelineSelector from '../TimelineSelector';

export interface VisualizationSettings {
  width: number,
  height: number,
  padding: { left: number, top: number, right: number, bottom: number },
  innerRadiusFactor: number,
  ringSpacing: number,
  paddingAngle: number,
  tooltipOpacity: number,
  tooltipMaxWidth: number,
  hoverOpacity: number,
  iconSize: number
}

export const VisualizationSettingsDefaults: VisualizationSettings = {
  width: 1024,
  height: 1024,
  padding: { left: 20, top: 20, right: 20, bottom: 20 },
  innerRadiusFactor: 0.1,
  ringSpacing: 3,
  paddingAngle: 0.015,
  tooltipOpacity: 0.9,
  tooltipMaxWidth: 320,
  hoverOpacity: 0.25,
  iconSize: 64
};

class SunBurstTree extends Map<string, SunBurstTree> {
  public name: string;
  public size: number;
  public depth: number;
  public parent?: string;

  constructor(params?: {
    name?: string,
    size?: number,
    depth?: number,
    parent?: string
  }) {
    super();
    const { name, size, depth, parent } = params || {};
    this.name = name || '';
    this.size = size || 0;
    this.depth = depth || 0;
    if (parent)
      this.parent = parent;
  }
}

// Component-specific props.
interface ComponentProps {
  //data: ProviderFile[],
  settings: Partial<VisualizationSettings>
}

/**
 * See also: https://observablehq.com/@d3/zoomable-sunburst
 * @param props 
 * @returns 
 */
const SunBurst: FC<ComponentProps> = (props: ComponentProps) => {
  const settings = getSettings();
  const categories = useSelector((state: ApplicationState) => state.categories.catColors);
  const items = useSelector((state: ApplicationState) => state.provider.items);

  const d3Container = useRef<SVGSVGElement>(null);
  const tooltipContainer = useRef<HTMLDivElement>(null);
  const [selection, setSelection] = useState<[Date, Date] | null>(null);
  const cats = categories.filter(d => d.value).map(d => d.id);
  const data = selector();

  // update radius
  const innerRadius = Math.min(settings.width, settings.height) * settings.innerRadiusFactor;

  useEffect(() => {
    drawSunburst();
  }, [d3Container, selection, data.length, items.length]);

  function selector() {
    return items
      .filter(d => d.active)
      .map(d => d.children)
      .reduce((acc, val) => acc.concat(val), [])
      .filter(d => cats.includes(d.dataCategory));
  }

  function getSettings(): VisualizationSettings {
    return {
      ...VisualizationSettingsDefaults,
      ...props.settings
    };
  }

  function getColor(d: d3.HierarchyRectangularNode<Map<string, SunBurstTree>>): string {
    let node = d;
    if (node.depth === 1) {
      if (node.data === undefined)
        return '#333';
      return groupColor((node.data.entries().next().value[1]));
    }
    else if (node.depth > 1) {
      while (node.depth > 2 && node.parent) node = node.parent;
      return catColor((node.data.entries().next().value[1]));
    }
    return '#e3e3e3';
  }

  function transformData(data: DataPoint[]): SunBurstTree {

    const tree = new SunBurstTree({ name: '', parent: '' });

    data.forEach(d => {

      let group = tree.get('' + GroupMapping[d.category]);
      if (group === undefined) {
        group = new SunBurstTree({ name: '' + GroupMapping[d.category], parent: '', depth: 1 });
        tree.set(GroupMapping[d.category], group);
      }

      let category = group.get(d.category);
      if (category === undefined) {
        category = new SunBurstTree({ name: '' + d.category, parent: '' + GroupMapping[d.category], depth: 2 });
        group.set(d.category, category);
      }

      let subcategory = category.get(d.subcategory);
      if (subcategory === undefined) {
        subcategory = new SunBurstTree({ name: d.subcategory, parent: '' + d.category, depth: 3 });
        category.set(d.subcategory, subcategory);
      }

      subcategory.size += 1;
      category.size += 0;
      group.size += 0;
      tree.size += 0;
    });

    return tree;
  }

  function drawSunburst() {

    if (d3Container.current) {

      const dataPoints = data.reduce(
        (acc: Array<DataPoint>, val: ProviderFile) => (val.data) ? acc.concat(val.data) : acc,
        Array<DataPoint>());

      const dataSelected = (selection) ? dataPoints.filter(d =>
        d.date.getTime() >= selection[0].getTime() &&
        d.date.getTime() <= selection[1].getTime()
      ) : dataPoints;

      // transform data for sunburst
      const sunburstData = transformData(dataSelected);

      // create hierarchy from sunburst data
      const tree = d3.hierarchy(sunburstData, d => {
        return Array.isArray(d) ? d[1] : null;
      })
        .sum(d => Array.isArray(d) ? d[1].size : 0)
        .sort((a, b) => (b.value || 0) - (a.value || 0));

      const sunburst = d3.partition<Map<string, SunBurstTree>>()
        .size([2 * Math.PI, tree.height + 1])(tree as any);

      sunburst.each((d: any) => {
        d.current = d;
      });

      // setup
      const labelText = (percentage: number) => `${d3.format('.1%')(percentage)}`;

      // arc path generator
      const arc = d3.arc<any>()
        .startAngle(d => d.x0)
        .endAngle(d => d.x1)
        .padAngle(d => Math.min((d.x1 - d.x0) / 2, settings.paddingAngle))
        .padRadius(innerRadius * 1.5)
        .innerRadius(d => d.y0 * innerRadius + settings.ringSpacing)
        .outerRadius(d => Math.max(d.y0 * innerRadius, d.y1 * innerRadius - 1));

      // select svg g element
      const node = d3.select(d3Container.current);

      // append sunburst paths
      const path = node.selectAll('path')
        .data(sunburst.descendants().slice(1), (d: any) => d.data[0])
        .join('path')
        .attr('fill', d => getColor(d))
        .style('opacity', d => (d.depth === 0) ? 0 : 1)
        .attr('d', (d: HierarchyRectangularNode<Map<string, SunBurstTree>>) => {
          return arc((d));
        });

      // setup onClick event
      const clicked = (e: Event, p: d3.HierarchyRectangularNode<Map<string, SunBurstTree>>) => {

        // if clicked segment is currently selected root, change to parent
        if ((p as any).selected)
          p = p.parent || sunburst;

        // TODO: this should not change the variable like this...
        // instead we should create a useState for the sunburst variable and change it there
        sunburst.each((d: any) => {
          d.selected = (d === p);
          d.target = {
            x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
            x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
            y0: Math.max(0, d.y0 - p.depth),
            y1: Math.max(0, d.y1 - p.depth)
          };
        });

        const t = node.transition().duration(750);

        path.transition(t as any)
          .tween('data', (d: any) => t => d.current = d3.interpolate(d.current, d.target)(t))
          .attrTween('d', (d: any) => () => arc(d.current) || '');
      };

      // make the segments clickable
      path.filter(d => d.children !== undefined && d.children.length > 0)
        .style('cursor', 'pointer')
        .on('click', clicked);


      // setup tooltip for segments
      const tooltip = d3.select('#tooltip')
        .style('visibility', 'hidden');

      const mouseover = function (e: Event, d: d3.HierarchyRectangularNode<Map<string, SunBurstTree>>) {
        tooltip
          .style('visibility', 'visible')
          .style('border-color', getColor(d));

        // highlight path of ancerstors
        const ancestors1 = d.ancestors().map((d) => d.data.entries().next().value[1]);
        path.filter(d => !ancestors1.includes(d.data.entries().next().value[1]) && d.depth !== 0)
          .style('opacity', settings.hoverOpacity);

        // create tooltip text
        const ancestors = d.ancestors().reverse().slice(1);

        tooltip
          .html(`
            <p>${ancestors.map((a: d3.HierarchyRectangularNode<Map<string, SunBurstTree>>, i: number) => `
                <span style="
                  display: inline-block; 
                  color: ${getColor(a)};
                  padding: 0 2px; 
                ">${a.data.entries().next().value[1]}</span>
              `).join(' &#8594; ')}
            </p>
            <p>${d3.format(',d')(d.value || 0)} data points ${(d.parent) ?
              `(${d3.format('.1%')((d.value || 0) / (d.parent.value || 1))} of
              ${(d.parent.data.entries().next().value[1]) ?
                d.parent.data.entries().next().value[1] : 'all data points'})` : ''}</p>
          `);

        d3.select('#centerlabel').style('visibility', 'visible');
      };

      const mousemove = (e: MouseEvent, d: d3.HierarchyRectangularNode<Map<string, SunBurstTree>>) => {

        //const tooltipRect = (tooltip.node() as HTMLDivElement).getBoundingClientRect();
        //const svgRect = d3Container.current.getBoundingClientRect();

        tooltip
          //.style("left", ((clientX - svgRect.x >= svgRect.width / 2) ? (clientX + 30) : (clientX - 30 - tooltipRect.width)) + "px")
          .style('left', (e.pageX + 30) + 'px')
          .style('top', e.pageY + 'px');

        d3.select('#centerlabel-percentage')
          .text(labelText((d.value || 0) / (sunburst.value || 1)));
      };


      const mouseleave = (e: Event, d: d3.HierarchyRectangularNode<Map<string, SunBurstTree>>) => {
        tooltip.style('visibility', 'hidden');

        //d3.select(this).style("stroke", "none");

        d3.select('#centerlabel').style('visibility', 'hidden');
        d3.select('#centerlabel-percentage')
          .text('');
        path.filter(d => d.depth !== 0).style('opacity', 1);
      };

      // add tooltip for all segments
      path
        .on('mouseover', mouseover)
        .on('mousemove', mousemove)
        .on('mouseleave', mouseleave);
    }
  }

  return (
    <div id="sunburst">

      <TimelineSelector
        settings={{
          width: props.settings.width,
          brushWidth: props.settings.width
        }}
        onSelected={(selection) => setSelection(selection)}
      />

      {(items.length > 0) ?
        <svg className="sunburst" width="100%" viewBox={`0 0 ${settings.width} ${settings.height - 100}`}>
          <g ref={d3Container} transform={`translate(${[settings.width / 2, settings.height / 2]})`} />

          <g
            id="centerlabel"
            transform={`translate(${[settings.width / 2, settings.height / 2]})`}
            style={{ visibility: 'hidden', mixBlendMode: 'difference' }}>
            <text
              id="centerlabel-percentage"
              dy={-0.15 * innerRadius}
              textAnchor="middle"
              fill="white"
              fontSize={innerRadius * 0.5}
              fontVariant="small-caps"
              fontWeight="bold"
              strokeWidth={1}
              stroke="black"
              strokeOpacity={0.3}
              style={{ cursor: 'default', pointerEvents: 'none' }}
            />
            <text
              dy={0.2 * innerRadius}
              textLength={innerRadius * 1.75}
              textAnchor="middle"
              fill="white"
              fontSize={innerRadius * 0.2}
              fontVariant="small-caps"
              fontWeight="bold"
              strokeWidth={0.5}
              stroke="black"
              strokeOpacity={0.3}
              style={{ cursor: 'default', pointerEvents: 'none' }}>
              of selected data
            </text>
          </g>
        </svg>
        :
        <h1 style={{ margin: '6em 0', textAlign: 'center' }}>No data found. Select your data export or use the demo dataset.</h1>
      }

      <div
        id="tooltip"
        ref={tooltipContainer}
        style={{
          position: 'absolute',
          opacity: settings.tooltipOpacity,
          color: 'white',
          padding: '0 8px',
          maxWidth: settings.tooltipMaxWidth,
          backgroundColor: 'rgba(48,48,48)',
          borderStyle: 'solid',
          borderWidth: '4px',
          borderRadius: '6px',
          boxShadow: '2px 0px white, 0px 2px white, -2px 0px white, 0px -2px white'
        }} />

    </div>
  );
};

export default SunBurst;