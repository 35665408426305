import { VisTypes} from 'constants/vis';
import { OptionLabelTypes } from 'store/language/types';

// State definition
export interface SelectionState {

  providerFilter: Filter,
  folderFilter: Filter,
  nodeFilter: Filter,

  currentVis: string,
  currentColor: string,
  currentColors: [string,string][],
  currentSelection: string | null,
  currentElement: string | null,
}

// Action types definition
export enum SelectionActionTypes {
  SELECT = '@@selection/SELECT',
  DESELECT = '@@selection/DESELECT',

  SET_ALL_FOLDERS = '@@selection/SET_ALL_FOLDERS',
  SET_FOLDERS = '@@selection/SET_FOLDERS',
}

export interface Filter {
  label: string,
  active: boolean,
  items: Item[]
}

interface BaseItem {
  kind: string,
  id: string,
  label: OptionLabelTypes,
  active: boolean,
  value: any,
  view?: VisTypes
}

interface ToggleItem extends BaseItem {
  kind: 'Toggle',
  value : boolean
}
export interface SelectorItem extends BaseItem {
  kind: 'Selector',
  value : string,
  options: string[],
  labels: OptionLabelTypes[]
}
interface RangeItem extends BaseItem {
  kind: 'Range',
  value: number,
  type: string,
  //start: number,
  //end: number
}
interface SliderItem extends BaseItem {
  kind: 'Slider',
  value: number,
  step: number,
  start: number,
  end: number
}
interface LassoItem extends BaseItem {
  kind: 'Lasso',
  value: boolean,
  amount: number,
  items: number[]
}
interface TextItem extends BaseItem {
  kind: 'Input',
  value: string,
}

export type Item = SelectorItem | RangeItem | LassoItem | ToggleItem | SliderItem | TextItem;