
/**
 * Adds the second to the first array
 * passed by reference
 * should be faster then concat or push
 * @param arr1 
 * @param arr2 
 */
export function mergeArrays(arr1: any[], arr2: any[]) {
  const arr1Length = arr1.length;
  const arr2Length = arr2.length;
  arr1.length = arr1Length + arr2Length;
  for (let i = 0; i < arr2.length; i++) {
    arr1[arr1Length + i] = arr2[i];
  }
}

/**
 * Adds "Chat with" at the beginning of the string
 * 
 * Example:
 * ["Max", "Sam", "Zoe"] => Chat with Max, Sam and Zoe
 * @param list list of strings
 * @returns beautiful string
 */
export function beautifyParticipants(list: string[]): string {
  return 'Chat with ' + beautifyArray(list);
}

/**
 * Creates a nicer sounding array
 * 
 * example:
 * ["Max", "Sam", "Zoe"] => Max, Sam and Zoe
 * @param list 
 * @returns 
 */
export function beautifyArray(list: string[]): string {
  if (list.length === 1)
    return list[0];
  const l = list.length - 1;
  return list.slice(0, l).join(', ') + ' and ' + list[l];
}


/**
 * capitalizes the first letter of the given string
 * @param str string to capitalize
 * @returns 
 */
export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

/**
 * gets whole objects as string
 * @param obj object with some keys or something
 * @returns a string that looks a bit nice
 */
export function getObjectAsString(obj: { [key: string]: any }): string {
  return Object.entries(obj)
    .filter(d => d[1] !== null && d[1] !== '')
    .map(d => `${d[0]}: ${getPrimitiveAsString(d[1])}`)
    .join('\n');
}

/**
 * transforms a priminitive to a string
 * @param d anything really
 * @returns returns it in string format
 */
export function getPrimitiveAsString(d: any): string {
  if (typeof d === 'string')
    return d;
  else if (typeof d === 'number')
    return d + '';
  else if (typeof d === 'boolean')
    return (d ? 'True' : 'False');
  else if (d instanceof Date)
    return d.toLocaleString();
  else if (Array.isArray(d)) {
    return `${d.length} items.\n${d.map(v => `${getPrimitiveAsString(v)}\n`).join('\n')}`;
  } else if (typeof d === 'object') {
    // intent the inner objects
    return `\n${getObjectAsString(d).split('\n').map(d => `\t${d}`).join('\n')}`;
  }
  return '';
}

/**
 * Removes duplicate elements from given array.
 * 
 * May cause problems if values match when converted to different types, such as '1' == 1.
 * @param a Arbitrary array
 * @returns Array with only unique elements
 */
export function removeDuplicates<T>(a: Array<T>): Array<T> {
  return a.reduce<{arr: Array<T>, seen: {[k: string]: number}}>((res, el) => !res.seen.hasOwnProperty(String(el)) ? {arr: [...res.arr, el], seen: {...res.seen, [String(el)]: 1}} : res, {arr:[], seen:{}}).arr;
}