import { ProviderFile, ProviderDefinition } from 'providers/types';

// Action types definition
export enum ProviderActionTypes {
  ADD_DATA= '@@provider/ADD_DATA',
  REMOVE_DATA = '@@provider/REMOVE_DATA',
  SWITCH_ITEM = '@@provider/SWITCH_ITEM',
  SET_PRIVACY_SENSITIVITY = '@@provider/SET_PRIVACY_SENSITIVITY',
}

// State definition
export interface ProviderState {
  readonly providers: ProviderDefinition[];
  readonly items: ProviderDatum[];
  readonly counter: number;
}

export interface ProviderDatum {
  id: number,
  filename: string,
  providerId: string,
  active: boolean,
  children: ProviderFile[]
}