import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import { ApplicationState } from 'store/index';
import DetailItems from './DetailItems';
import SearchField from './SearchField';
import { DataPoint, ProviderFile } from 'providers/types';
import useLanguageHook from 'containers/App/useLanguageHook';

interface ComponentProps {
  providerFiles: ProviderFile[],
  height: number,
  private: boolean
}

interface DataPoint2 extends DataPoint {
  itemId: number,
  fileId: number,
  index: number
}

const DetailView: FC<ComponentProps> = (props) => {
  // Senstivity
  const categoryFilter = useSelector((state: ApplicationState) => state.categories.catColors);
  const searchString = useSelector((state: ApplicationState) => state.options.searchString);
  // Rating Stuff
  const amount = getLength();
  const rated = getFilteredItems();
  const sum = rated.reduce((a, b) => (a || 0) + (b.sensitivity || 0), 0) as number;
  const rating = Math.floor(sum / rated.length) || 0;
  // Pagiantion
  const [currentPage, setCurrentPage] = useState(1);
  const [direction, setDirection] = useState(false);
  const itemsPerPage = 20;
  const maxPage = getMaxPageCount();
  // Styling
  const maxHeight = props.height - 30;
  const { t } = useLanguageHook();


  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Box textAlign="center">
          <Typography variant="h4" sx={{ m: 1 }}>
            {t.Vis.TheList}
          </Typography>
          <Typography color="textSecondary">
            {/*Some description of this page goes here.*/}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} spacing={2} container direction="row" justifyContent="center" alignItems="center">
        <Grid item>
          <SearchField />
        </Grid>
        <Grid item>
          {renderPagination()}
        </Grid>
        <Grid item>
          {/*renderPrivacyInfo()*/}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <List component="nav" sx={{
          position: 'relative',
          overflow: 'auto',
          maxHeight: maxHeight,
          paddingTop: '25px'
        }}>
          {getData().map((d: DataPoint2, i) =>
            <DetailItems
              key={d.date + '-' + i}
              data={d}
              private={props.private}
              index={d.index}
              itemId={d.itemId}
              fileId={d.fileId}
            />
          )}
          {amount === 0 &&
            <Grid container justifyContent="center">
              <Grid item>
                <Typography>
                  {t.DataPage.NoDataFound}
                </Typography>
              </Grid>
            </Grid>
          }
        </List>
      </Grid>
    </Grid>
  );


  function renderPrivacyInfo() {
    const percentage = amount === 0 ? 0 : Math.floor(rated.length / amount * 100 * 100) / 100;
    return (
      <Paper sx={{
        minWidth: 220
      }}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Typography>
              {t.Vis.Sensitivity}:
            </Typography>
            <Paper sx={{
              textAlign: 'center',
              width: '65px',
              float: 'left',
              marginRight: '5px',
              marginLeft: '5px',
              background: (props) => 'linear-gradient(90deg, rgb(79, 157, 223) ' +
                (-rating * 100 + 0) + '%, rgb(244, 188, 33) ' +
                (-rating * 100 + 5000) + '%, rgb(176, 6, 7) ' +
                (-rating * 100 + 10000) + '%)'
            }}>
              <Typography fontStyle={'h2'}>{rating}</Typography>
            </Paper>
            <Typography>
              {t.Vis.Rated.replace('%num%', percentage + '%')}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  function renderPagination() {
    return (
      <Paper >
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Button onClick={prev} disabled={currentPage === 1}>
              {t.Vis.PreviousPage}
            </Button>
          </Grid>
          <Grid item>
            <Typography sx={{ m: 1 }}>
              {t.Vis.Page} {currentPage} / {maxPage}
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={next} disabled={currentPage === maxPage}>
              {t.Vis.NextPage}
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Button onClick={() => setDirection(!direction)}>
              {direction ? t.Vis.AscendingOrder : t.Vis.DescendingOrder}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  function getCategoryFilter() {
    return categoryFilter
      .filter(d => d.value)
      .map(d => d.id);
  }

  function searchFilter(d: DataPoint): boolean {
    const search = searchString.toLowerCase();
    if (search === '')
      return true;

    return d.text.toLowerCase().includes(search) ||
      d.category.toLowerCase().includes(search) ||
      d.subcategory.toLowerCase().includes(search);
  }

  function getLength() {
    return props.providerFiles
      .reduce((acc: number, val) => acc + val.data.length, 0);
  }

  function getFilteredItems() {
    return props.providerFiles
      .reduce((acc: DataPoint[], val) => acc.concat(val.data.filter(d => d.sensitivity !== undefined)), []);
  }

  function getMaxPageCount() {
    const cats = getCategoryFilter();
    const fuck = props.providerFiles
      .reduce((acc: number, val) => acc +
        val.data.filter(d => cats.includes(d.category) && searchFilter(d)).length, 0);
    return Math.ceil(fuck / itemsPerPage);
  }

  function getData() {
    const start = (currentPage - 1) * itemsPerPage;
    const cats = getCategoryFilter();

    const data = props.providerFiles
      .filter(d => cats.includes(d.dataCategory))
      .reduce((acc: DataPoint2[], val) => acc.concat(val.data
        .map((d, i) => { return { ...d, index: i, fileId: val.id, itemId: val.itemId } as DataPoint2; })
        .filter(d => searchFilter(d))
      ), [])
      .sort((a: DataPoint2, b: DataPoint2) => a.date.getTime() - b.date.getTime());

    if (direction)
      return data.reverse().slice(start, start + itemsPerPage);
    else
      return data.slice(start, start + itemsPerPage);
  }

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }
};

export default DetailView;