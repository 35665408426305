import React, { FC } from 'react';

import { Box, useTheme } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import { LanguageTypes } from 'store/language/types';

// Component-specific props.
interface ComponentProps {
  list: string[],
  title: string,
  language: LanguageTypes
}

const HelperPaper: FC<ComponentProps> = (props) => {
  const theme = useTheme();

  return (
    <List>
      {props.list.map((d, i) => renderListItem(d, i + 1))}
    </List>
  );

  function renderListItem(d: string, i: number) {
    let text = <>{d}</>;
    if (d.startsWith('http')) {
      if (props.language === 'de') {
        text = <>Besuche <Link sx={{ color: theme.palette.secondary.light }} color="secondary" target="_blank" rel="noopener noreferrer" href={d}>
          diese Website
        </Link> um deine Persönlichen Daten herunterzuladen.
        </>
        ;
      } else {
        text = <>Visit <Link sx={{ color: theme.palette.secondary.light }} color="secondary" target="_blank" rel="noopener noreferrer" href={d}>
          this webpage
        </Link > to download your personal data
        </>
        ;
      }
    }
    return (
      <ListItem key={i} sx={{ pb: 0.25, pt: 0.25 }}>
        <ListItemIcon sx={{ minWidth: '14px', fontWeight: 900, p: 1 }}>
          {i}
        </ListItemIcon>
        <ListItemText primary={
          <Typography variant="h6" sx={{}}>
            {text}
          </Typography>
        } />
      </ListItem>
    );
  }

};

export default HelperPaper;
