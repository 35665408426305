import React, { FC } from 'react';

import {
  Typography,
  Link
} from '@mui/material';
import { styled } from '@mui/material/styles';


const Root = styled('div')(() => ({
  maxWidth: '1280px',
  margin: 'auto',
  padding: '3em',
  textAlign: 'left'
}));

const ImprintPage: FC = () => {
  return (
    <Root>
      <Typography variant="h4" align="center">Imprint</Typography>

      <hr />

      <Typography variant="h5">Fraunhofer Institute for Computer Graphics Research IGD</Typography>
      <Typography>
        <em>
          <strong>Fraunhofer IGD</strong><br />
          Fraunhoferstraße 5<br />
          64289 Darmstadt<br />
          Phone: +49 6151 155-0<br />
          Fax: +49 6151 155-199<br />
          <Link underline="always" href="https://www.igd.fraunhofer.de">www.igd.fraunhofer.de</Link>
        </em>
      </Typography>

      <p />
      <Typography>
        The institute is a constituent entity of the Fraunhofer-Gesellschaft, and as such has no separate legal status.
      </Typography>
      <p />

      <Typography>
        <em>
          <strong>Fraunhofer-Gesellschaft zur Förderung der angewandten Forschung e.V.</strong><br />
          Hansastraße 27 c<br />
          80686 München<br />
          Phone: +49 89 1205-0<br />
          Fax: +49 89 1205-7531<br />
          <Link underline="always" href="https://www.fraunhofer.de">www.fraunhofer.de</Link>
          <br />
        </em>
      </Typography>

      <p />
      <Typography>
        <strong>VAT Identification Number</strong> in accordance with §27 a VAT Tax Act: DE 129515865
      </Typography>
      <p />

      <Typography>
        <em>
          <strong>Court of jurisdiction</strong><br />
          Amtsgericht München (district court)<br />
          Registered nonprofit association<br />
          Registration no. VR 4461<br />
        </em>
      </Typography>

      <p />

      <Typography variant="h5">Executive Board</Typography>
      <Typography>
        Prof. Dr.-Ing. Reimund Neugebauer, President, Corporate Policy and Research Management<br />
        Prof. Dr. Georg Rosenfeld, Technology Marketing and Business Models<br />
        Prof. Dr. Alexander Kurz, Human Resources, Legal Affairs and IP Management<br />
        Prof. (Univ. Stellenbosch) Dr. Alfred Gossner, Finance, Controlling<br />
        (incl. Business Administration, Purchasing and Real Estate) and Information Systems
      </Typography>

      <p />

      <Typography variant="h5">Usage Rights</Typography>
      <Typography>
        Copyright © by Fraunhofer-Gesellschaft<br />
        All rights reserved.<br />
        All copyright for this Web site are owned in full by the Fraunhofer-Gesellschaft.
      </Typography>

      <p />
      <Typography>
        Permission is granted to download or print material published on this site for personal use only. Its use for any other purpose, and in particular its commercial use or distribution, are strictly forbidden in the absence of prior written approval. Please address your requests for approval to:
      </Typography>
      <p />

      <Typography>
        <em>
          <strong>Fraunhofer IGD</strong><br />
          Fraunhoferstraße 5<br />
          64289 Darmstadt<br />
          <Link underline="always" href="https://www.igd.fraunhofer.de">www.igd.fraunhofer.de</Link>
        </em>
      </Typography>

      <p />
      <Typography>
        Notwithstanding this requirement, material may be downloaded or printed for use in connection with press reports on the activities of the Fraunhofer-Gesellschaft and its constituent institutes, on condition that the following terms are complied with:
      </Typography>
      <p />

      <Typography variant="h5">Disclaimer</Typography>
      <Typography>
        We cannot assume any liability for the content of external pages. Solely the operators of those linked pages are responsible for their content.
      </Typography>

      <p />
      <Typography>
        We make every reasonable effort to ensure that the content of this Web site is kept up to date, and that it is accurate and complete. Nevertheless, the possibility of errors cannot be entirely ruled out. We do not give any warranty in respect of the timeliness, accuracy or completeness of material published on this Web site, and disclaim all liability for (material or non-material) loss or damage incurred by third parties arising from the use of content obtained from the Web site.
      </Typography>
      <p />

      <p />
      <Typography>
        Registered trademarks and proprietary names, and copyrighted text and images, are not generally indicated as such on our Web pages. But the absence of such indications in no way implies that these names, images or text belong to the public domain in the context of trademark or copyright law.
      </Typography>
      <p />

    </Root>
  );
};

export default ImprintPage;