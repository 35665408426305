import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ApplicationState } from 'store/index';
import translations from 'constants/translations.json';


function useTranslations() {
  const lang = useSelector((state: ApplicationState) => state.language.language);
  const supportedLangs = useSelector((state: ApplicationState) => state.language.supportedLangs);
  const t = useMemo(() => translations[lang], [lang]);

  return {
    t,
    lang,
    supportedLangs
  };
}

export default useTranslations;
