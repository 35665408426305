import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Papa from 'papaparse';

import { Paper, Grid, Button, Typography, Box, FormControlLabel, Checkbox, FormControl, FormLabel, FormGroup, FormHelperText, Stack, Radio, TextField } from '@mui/material';

import { ApplicationState } from 'store/index';
import { Category, DataPoint, ProviderFile } from 'providers/types';
import { ProviderDatum } from '@store/providers/types';
import useTranslations from 'containers/App/useLanguageHook';
import { getObjectAsString } from '@utils/array';
import { catColor } from 'constants/color';
import BarChart from 'containers/Visuals/BarChart';

enum Format {
  JSON = 'JSON',
  CSV = 'CSV',
  PDF = 'PDF',
  Plain = 'Plain Text',
  HTML = 'HTML',
}

const ExportView: FC = () => {
  const { t } = useTranslations();
  // File selection
  const providerItems = useSelector((state: ApplicationState) => state.provider.items);
  const [active, setActive] = useState<boolean[]>(providerItems.map(d => false));
  // Format selection
  const avilableFormats = [Format.JSON, Format.CSV, Format.PDF, Format.Plain, Format.HTML];
  const [format, setFormat] = useState(Format.JSON);
  const statistics = () => getStatistics();
  const [stats, setStats] = useState(statistics);


  return (
    <Grid container justifyContent='center' spacing={2} sx={{ maxWidth: 1280 }}>
      <Grid item xs={12}>
        <Typography variant="h3">
          Export Your Data to other services
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex' }}>
          <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
            <Typography>Select which files you want to export.</Typography>
            <FormGroup>
              {providerItems.map((d, i) =>
                <FormControlLabel
                  key={d.id}
                  control={
                    <Checkbox checked={active[i]} onChange={() => handleActiveClick(i)} name={d.filename} />
                  }
                  label={
                    <Box>
                      <Typography>{d.filename} ({d.providerId})</Typography>
                    </Box>
                  }
                />
              )}
            </FormGroup>
            <FormHelperText>You can only select one.</FormHelperText>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex' }}>
          <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
            <Typography>Choose a format.</Typography>
            <FormGroup>
              {avilableFormats.map(d =>
                <FormControlLabel
                  key={d}
                  control={
                    <Radio checked={format === d} onChange={() => setFormat(d)} name={d} />
                  }
                  label={
                    <Box>
                      <Typography>{d}</Typography>
                    </Box>
                  }
                />
              )}
            </FormGroup>
            <FormHelperText>Usually JSON works best.</FormHelperText>
          </FormControl>
        </Box>
      </Grid>
      {/*
      <Chip key={d.id} label={d.filename} deleteIcon={<Done />}
              onClick={() => setActive(d.id)}
              color={(active !== undefined && active === d.id) ? "primary" : "default"}
            />
            
      <Grid item xs={12} container direction="row" justifyContent="center" alignItems="flex-start">
        {providerItems.map(renderFiles)}
      </Grid>
      */}
      <Grid item>
        <Stack sx={{ m: 2 }} spacing={2} direction='column'>
          <Button color="primary" disabled={active === undefined} variant="contained" onClick={() => download()}>
            Download
          </Button>
        </Stack>
      </Grid>
      <Grid item>
        <TextField
          id="filled-multiline-flexible"
          label="Multiline"
          multiline
          maxRows={10}
          sx={{ whiteSpace: 'pre', minWidth: 600 }}
          value={getStatistics()}
          variant="filled"
        />
        <BarChart size={{ height: 200, width: 400 }} data={getBarChartData()} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          You can export your data to use it in other services, or for yourself.
        </Typography>
      </Grid>
    </Grid>
  );

  function handleActiveClick(i: number) {
    setActive(active.map((d, j) => j === i ? !d : d));
  }

  function download() {
    const item = providerItems.find((d, i) => active[i]);
    if (item) {
      switch (format) {
        case Format.JSON:
          downloadFile(toJsonBlob(item), item.filename, '_export.json');
          break;
        case Format.CSV:
          downloadFile(toPapaCsv(getDataPoints(item)), item.filename, '_export.csv');
          break;
        case Format.PDF:
          downloadFile(toJsonBlob(providerItems), providerItems[0].filename, '_export.pdf');
          break;
        case Format.Plain:
          downloadFile(toTextBlob(item), item.filename, '_export.txt');
          break;
        case Format.HTML:
          downloadFile(toJsonBlob(providerItems), providerItems[0].filename, '_export.html');
          break;
      }
    }
  }

  function renderExportJson() {
    return (
      <Paper variant="outlined">
        <Stack sx={{ m: 2 }} spacing={2} direction='column'>
          <Typography>
            Export your all TransparenycVis Data
          </Typography>
          <Button color="primary" variant="contained"
            onClick={(e) => downloadFile(toJsonBlob(providerItems), providerItems[0].filename, '.json')}>
            Export all Files as JSON
          </Button>
          <Button color="primary" variant="contained"
            onClick={(e) => downloadFile(toJsonBlob(getAllDataPoints()), providerItems[0].filename, '.json')}>
            Export Datapoints as Json
          </Button>
          <Button color="primary" variant="contained"
            onClick={(e) => downloadFile(toPapaCsv(getAllDataPoints()), providerItems[0].filename, '.csv')}>
            Export Datapoints as Papaparse CSV
          </Button>
          <Button color="primary" variant="contained"
            onClick={(e) => downloadFile(toCustomCsv(getAllDataPoints()), providerItems[0].filename, '.csv')}>
            Export Datapoints as simple CSV
          </Button>
        </Stack>
      </Paper>
    );
  }

  function renderExportAnonymized() {
    return (
      <Paper variant="outlined">
        <Typography>
          Export your Data as Anonymized
        </Typography>
        <Button color="primary" variant="outlined"
          onClick={(e) => downloadFile(new Blob([]), 'test', 'json')}>
          Export Anonym
        </Button>
      </Paper>
    );
  }

  function getDataPoints(item: ProviderDatum) {
    return item.children
      .reduce((acc: DataPoint[], val) => acc.concat(val.data), []);
  }

  function getAllDataPoints() {
    return providerItems
      .reduce((acc: ProviderFile[], val) => val.active ? acc.concat(val.children) : acc, [])
      .reduce((acc: DataPoint[], val) => acc.concat(val.data), []);
  }

  function anonymizeSubcategory(name: string) {
    // TODO: anything else to anonymize?
    if (name.startsWith('F Chat') ||
      name.startsWith('I Messages') ||
      name.startsWith('I Nachrichten'))
      return 'Chat';
    else
      return name.substring(2);
  }

  function getStatistics() {
    const timeFrame = 1000 * 60 * 60 * 24;
    const amountOfFrames = 365;
    const currentTime = new Date().getTime();
    const today = currentTime - (currentTime % timeFrame);

    const res: { [key: string]: [number, number][] } = {};
    // use subcategories
    getAllDataPoints().forEach(item => {
      const subcategory = anonymizeSubcategory(item.subcategory);
      if (res[subcategory] === undefined) {
        res[subcategory] = Array.from({ length: amountOfFrames },
          (_, index: number) => {
            return [today - timeFrame * index, 0];
          }
        );
      }
      res[subcategory].forEach(weekInItem => {
        if (item.date.getTime() >= weekInItem[0] &&
          item.date.getTime() <= weekInItem[0] + timeFrame) {
          weekInItem[1] += 1;
        }
      });
    });
    console.log((res));
    return toStatisticsCsv(res);
  }

  function toStatisticsCsv(data: { [key: string]: any }) {
    const amountOfFrames = 365;
    const headers: string[] = ['category', 'subcategory', ...Array.from({ length: amountOfFrames }, (_, i) => `week${i}`)];
    const keys: string[] = Object.keys(data);
    let csv = headers.map(d => d).join(',') + '\n';
    for (const line of keys) {
      csv += line + ',' + data[line].map(([date, amount]: [number, number]) => amount).join(',') + '\n';
    }
    return csv;
  }

  function getBarChartData() {
    const result = Object.values(Category).map(category => {

      const items = providerItems.reduce(
        (acc, val) => acc + val.children.reduce(
          (acc1, val1) => acc1 + (val1.dataCategory === category ? val1.data.length : 0), 0), 0);


      return {
        key: category,
        color: catColor(category),
        value: items
      };
    });
    return result;
  }

  function toPapaCsv(data: any[]) {
    return toCSVBlob(Papa.unparse(data));
  }

  function toCustomCsv(data: any[]) {
    const keys: string[] = data[0] && Object.keys(data[0]) || [];
    let csv = keys.map(d => d).join(',') + '\n';
    for (const line of data) {
      csv += keys.map(key => (line[key] + '').replace(/\n/g, ' ').replace(/,/g, '.')).join(',') + '\n';
    }
    return toCSVBlob(csv);
  }

  function toCSVBlob(csv: any): Blob {
    // While BOM is needed for Excel, other programs struggle with it
    //const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
    return new Blob([csv], { type: 'text/csv' });
  }

  function toJsonBlob(data: any) {
    const output = JSON.stringify(data, null, 4);
    return new Blob([output], { type: 'text/json' });
  }

  function toTextBlob(data: any) {
    const output = getObjectAsString(data);
    return new Blob([output], { type: 'text/plain' });
  }

  function downloadFile(data: Blob, filename: string, ext: string) {
    const fileDownloadUrl = URL.createObjectURL(data);
    const node = document.createElement('a');
    node.download = filename + ext;
    node.href = fileDownloadUrl;

    node.dispatchEvent(new MouseEvent('click'));
    setTimeout(() => {
      URL.revokeObjectURL(fileDownloadUrl);
    });
  }

  function renderImages(d: ProviderDatum) {
    //"data:image/png;base64,"
    return (
      <Grid item key={d.id}>
        {
          d.children.filter(d => d.fileType === 'Picture').map(d =>
            <div key={d.id}>
              {d.fileName}
              {d.binaryData && <img src={d.binaryData} />}
            </div>
          )
        }
      </Grid>
    );
  }
};

export default ExportView;
