import translations from 'constants/translations.json';

export type CategoryKeyTypes = keyof typeof translations.en.Categories;
type FileCategoryKeyTypes = keyof typeof translations.en.FileCategories;

// State definition
export interface CategoriesState {
  //activeState: ColorCategories,
  catColors: ColorToggleItem[],
  subColors: ColorToggleItem[],
  locColors: ColorToggleItem[],
  provColors: ColorToggleItem[],
  fileColors: ColorToggleItem[],
}

// Action types definition
export enum CategoriesActionTypes {
  TOGGLE_ITEM = '@@categories/TOGGLE_ITEM',
  UPDATE_COLOR = '@@categories/UPDATE_COLOR',
  CREATE_SUBCATS = '@@categories/CREATE_SUBCATS',
  SELECT_ALL = '@@categories/SELECT_ALL',
  DESELECT_ALL = '@@categories/DESELECT_ALL',
}

export interface ColorToggleItem {
  id: string,
  key: CategoryKeyTypes | FileCategoryKeyTypes,
  label: string,
  value: boolean,
  color: string,
  //apply: (d : DataPoint) => boolean
}

export enum ColorCategories {
  categories,
  subcategories,
  locations,
  providers,
  files,
}