import { action } from 'typesafe-actions';
import { CategoriesActionTypes, ColorCategories } from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '..';
import { interpolateSinebow, lch } from 'd3';

export function selectItem(filter: ColorCategories, id: string): any {
  return (dispatch: Dispatch) => {
    dispatch(action(CategoriesActionTypes.TOGGLE_ITEM, { filter: filter, id: id }));
  };
}
export function deselectAllItems(filter: ColorCategories): any {
  return (dispatch: Dispatch) => {
    dispatch(action(CategoriesActionTypes.DESELECT_ALL, { filter: filter }));
  };
}
export function selectAllItems(filter: ColorCategories): any {
  return (dispatch: Dispatch) => {
    dispatch(action(CategoriesActionTypes.SELECT_ALL, { filter: filter }));
  };
}

export function updateColors(filter: ColorCategories, array: [string, string][]): any {
  return (dispatch: Dispatch) => {
    // maybe update based on current selected color scheme?
    // only the timeline has different color schemes, so
    dispatch(action(CategoriesActionTypes.UPDATE_COLOR, { filter, array }));
  };
}

// TODO: add support for multiple colormaps
export function updateLocations(array: [string, string][]): any {
  return (dispatch: Dispatch) => {
    dispatch(action(CategoriesActionTypes.UPDATE_COLOR,
      { filter: ColorCategories.locations, array }));
  };
}

export function updateSubcategories(): any {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    const data = getState().provider.items;
    const a = data.map(d => d.children.map(c => c.data.map(p => p.subcategory)).flat()).flat();
    const list = getSubcatList(a);
    const sorted = list.sort((a, b) => b[1] - a[1]);
    const len = sorted.length;
    const res = sorted.map((d, i) => [d[0], getColor(i, len)]);
    console.log(res);
    dispatch(action(CategoriesActionTypes.CREATE_SUBCATS, { list: res }));
  };
}

function getColor(i: number, len: number): string {
  const t = i / len;
  const { l, c, h } = lch(interpolateSinebow(i % 2 === 0 ? t + 0.5 : 1 - t));
  return lch(85, 70, h).formatHex();
  //return rgb(interpolateSinebow(44 / (i ) + (i%2))).formatHex();
}

function getSubcatList(data: string[]): [string, number][] {
  const categories = {} as { [key: string]: number };
  for (const subcategory of data) {
    categories[subcategory] = (categories[subcategory] || 0) + 1;
  }
  console.log(categories);

  return Object.entries(categories);
}

/*
export function updateSubcategories(array: [string, string][]): any {
  return (dispatch: Dispatch) => {
    dispatch(action(CategoriesActionTypes.UPDATE_COLOR,
      { filter: ColorCategories.subcategories, array }));
  }
}
*/