import { Category } from 'providers/types';

export enum Group {
  Input = 'User Inputs & Uploads',
  Account = 'Account & Devices',
  Behaviour = 'Online User Behaviour'
}

export const GroupColor = {
  [Group.Input]: '#BDB8AD',
  [Group.Account]: '#EBE7E0',
  [Group.Behaviour]: '#C6D4E1'
};

export function groupColor(s: Group | string) {
  if (GroupColor[s as Group])
    return GroupColor[s as Group];
  return '#e3e3e3';
}

export const GroupMapping = {
  [Category.Account]: Group.Account,
  [Category.Activity]: Group.Behaviour,
  [Category.Contacts]: Group.Behaviour,
  [Category.Stories]: Group.Behaviour,
  //[Category.Location]: Group.Account,
  // [Category.Media]: Group.Input,
  [Category.Messages]: Group.Input,
  //[Category.Other]: Group.Behaviour,
  [Category.Likes]: Group.Behaviour,
  [Category.PostsAndComments]: Group.Input,
  [Category.Security]: Group.Account,
};
