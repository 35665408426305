import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Checkbox from '@mui/material/Checkbox';
import Delete from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { ApplicationState } from 'store/index';
import { ProviderDatum } from 'store/providers/types';
import { ProviderDefinition } from 'providers/types';
import { switchActive, removeData } from 'store/providers/actions';
import useTranslations from 'containers/App/useLanguageHook';

// Component-specific props.
interface ComponentProps {
  item: ProviderDefinition,
  files: ProviderDatum[],
  onClick: () => void,
  selected: boolean
}
interface StyleProps {
  colors: string,
  selected: boolean
}

const ProviderSelectItem: FC<ComponentProps> = (props) => {
  const theme = useTheme();
  const colors = getGradient();
  const language = useSelector((state: ApplicationState) => state.language.language);
  const providerItems = useSelector((state: ApplicationState) => state.provider.items);
  const { t } = useTranslations();

  return (
    <Box>
      <Box sx={{ m: 1 }}>
        <ButtonBase focusRipple onClick={() => props.onClick()}>
          <Paper variant="outlined" sx={{
            margin: '0em',
            position: 'relative',
            maxWidth: 300,
            minWidth: 300,
            textAlign: 'center',
            padding: '.5em',
            border: props.selected ? '1px solid ' + theme.palette.secondary.main : undefined,
            '&:hover': {
              border: '1px solid ' + theme.palette.primary.main,
              backgroundColor: theme.palette.primary.dark
            }
          }}>
            <Box sx={{
              paddingTop: '8px',
              textAnchor: 'middle',
              fontSize: '0.8em',
              color: theme.palette.secondary.light,
            }}>{t.DataPage.ShowMeFirst}</Box>
            <Box sx={{
              fontWeight: 700,
              fontSize: 30,
              textAnchor: 'middle',
              padding: '2rem',
              paddingTop: '1rem',
              pointerEvents: 'none',
            }}>{props.item.name}</Box>
          </Paper>
        </ButtonBase>
      </Box>
      {providerItems.length > 0 &&
        <Paper variant="outlined" sx={{
          margin: '1em',
          marginLeft: '2.5em',
          marginTop: '-10px',
          position: 'relative',
          maxWidth: 250,
          minWidth: 250,
          textAlign: 'center',
          padding: '.5em'
        }}>
          <Typography variant="subtitle2">{t.DataPage.DataExports}:</Typography>
          {providerItems.map(d => <ProviderSelectItemFiles key={d.id} item={d} />)}
        </Paper>
      }
    </Box>
  );

  function getGradient() {
    const colors = props.item.colors;
    let color: string;
    if (props.files.length === 0) {
      return theme.palette.text.primary;
    }
    if (colors.length === 1)
      color = colors[0];
    else
      color = 'linear-gradient(90deg, ' + colors.map((d, i) => d + ' ' + (i * 100 / (colors.length - 1) + '%')).join(', ') + ')';
    return color;
  }
};

interface ItemComponentProps {
  item: ProviderDatum,
}

function shortFileName(name: string) {
  return (name.length > 35 ? name.substr(0, 30) + '...' + name.substr(-5) : name);
}

const ProviderSelectItemFiles: FC<ItemComponentProps> = (props) => {
  const dispatch = useDispatch();

  return <Box display="flex" key={props.item.id} sx={{ p: 1 }}>
    <Tooltip title={(!props.item.active ? 'Select this data export' : 'Deselect this data export')}>
      <Checkbox
        checked={props.item.active}
        onChange={(e) => dispatch(switchActive(props.item.id))}
      />
    </Tooltip>
    <Tooltip title={props.item.filename}>
      <Typography sx={{ selfAlign: 'center', p: 1 }}>{shortFileName(props.item.filename)}</Typography>
    </Tooltip>
    <Tooltip title={'Remove this data export'}>
      <Button color="error" variant="outlined" onClick={(e) => dispatch(removeData(props.item.id))}>
        <Delete />
      </Button>
    </Tooltip>
  </Box>;
};

export { ProviderSelectItem, ProviderSelectItemFiles };
