import { VisTypes } from 'constants/vis';

// State definition
export interface trackingState {
  timeline: Record<InteractionTypes, number>,
  treemap: Record<InteractionTypes, number>,
  visited: VisTypes[]
}

// Action types definition
export enum TrackingActionTypes {
  SET_TRACKING = '@@tracking/SET_TRACKING',
  TRACK_VISITED = '@@tracking/TRACK_VISITED'
}

// Additional definitions used in action objects
export type InteractionTypes = 'hover' | 'viewsec' | 'checkbox' | 'filter';