import React, { FC } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useTheme } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";

import { ApplicationState } from "store/index";
import { ColorToggleItem, ColorCategories } from "store/categories/types";
import { selectItem } from "store/categories/actions";
import { VisTypes } from "constants/vis";
import Node from "./Node";
import useTranslations from "containers/App/useLanguageHook";
import { FileCategory } from "constants/filenames";
import { Category } from "providers/types";
import { track } from "@store/tracking/actions";

const CategoryList: FC = () => {
  const currentVis = useSelector(
    (state: ApplicationState) => state.options.currentVis
  );
  const categoryColors = useSelector(
    (state: ApplicationState) => state.categories.catColors
  );
  const fileColors = useSelector(
    (state: ApplicationState) => state.categories.fileColors
  );
  const dispatch = useDispatch();
  const { t } = useTranslations();
  const theme = useTheme();

  return (
    <List dense component="div" disablePadding>
      {/*
      <Button onClick={() => dispatch(updateSubcategories())}>Update {subs ? "+" : "-"}</Button>
      */}
      {getActiveCategory().map(renderColorItem)}
    </List>
  );

  function getActiveCategory() {
    if (currentVis === VisTypes.Files) return fileColors;
    //if (currentVis === VisTypes.Timeline && subs)
    //return subColors;
    return categoryColors;
  }

  function refactorMePls() {
    // TODO: this does not seem to belong here, the categories state should know
    // which is the active color scheme, not some component
    // but its much easier for now...
    if (currentVis === VisTypes.Files) return ColorCategories.files;
    //if (currentVis === VisTypes.Timeline && subs)
    //return ColorCategories.subcategories;
    return ColorCategories.categories;
  }

  function getLabel(item: ColorToggleItem): string {
    if (currentVis === VisTypes.Files)
      return t.FileCategories[item.key as FileCategory];
    if (currentVis === VisTypes.Timeline)
      return t.Categories[item.key as Category];
    return t.Categories[item.key as Category];
  }

  function renderColorItem(item: ColorToggleItem) {
    //let amount = getAmount(item.label);
    return (
      <Tooltip
        key={item.id}
        placement="right"
        title={"Click to " + (item.value ? "hide" : "show") + " this category"}
      >
        <ListItem
          button
          onClick={() => {
            dispatch(track("filter", 1));
            dispatch(selectItem(refactorMePls(), item.id));
          }}
        >
          <ListItemAvatar>
            <Node color={item.color} active={item.value} size={20} margin={0} />
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{
              style: {
                color: item.value
                  ? theme.palette.text.primary
                  : theme.palette.text.disabled,
              },
            }}
            //primary={item.label}
            primary={getLabel(item)} // + " (" + amount + ")"}
          />
        </ListItem>
      </Tooltip>
    );
  }
};

export default CategoryList;
