import { action } from 'typesafe-actions';
import { ProviderActionTypes } from './types';
import { ProviderFile } from 'providers/types';
import { Dispatch } from 'redux';
import { clearLastFilename } from '@store/files/actions';
//import { updateSubcategories } from '@store/categories/actions';

export function addNewData(filename: string, providerId: string, data: ProviderFile[]): any {
  return (dispatch: Dispatch) => {
    //dispatch(updateSubcategories());
    dispatch(action(ProviderActionTypes.ADD_DATA, {
      filename: filename,
      providerId: providerId,
      data: data
    }));
  };
}
export function removeData(itemId: number): any {
  return (dispatch: Dispatch) => {
    dispatch(action(ProviderActionTypes.REMOVE_DATA, { itemId: itemId }));
    dispatch(clearLastFilename());
  };
}
export function switchActive(itemId: number): any {
  return (dispatch: Dispatch) => {
    dispatch(action(ProviderActionTypes.SWITCH_ITEM, { itemId: itemId }));
  };
}
export function setSensitivity(itemId: number, fileId: number, index: number, value: number): any {
  return (dispatch: Dispatch) => {
    dispatch(action(ProviderActionTypes.SET_PRIVACY_SENSITIVITY, { itemId, fileId, index, value }));
  };
}