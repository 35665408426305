import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  keyframes,
  Paper,
  styled,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { ProviderDatum } from "store/providers/types";
import { ProviderDefinition } from "providers/types";
import { switchVis } from "store/options/actions";
import { VisTypes } from "constants/vis";
import Dropzone from "containers/Components/Dropzone";
import HelperPaper from "containers/Components/HelperPaper";
import useLanguageHook from "containers/App/useLanguageHook";
import { ApplicationState } from "@store/index";
import { ProviderSelectItemFiles } from "./ProviderSelectItem";

// Component-specific props.
interface ComponentProps {
  item: ProviderDefinition;
  files: ProviderDatum[];
  onClose: () => void;
}

const ring = keyframes`
  from {
    width: 50%;
    height: 100%;
    opacity: 0;
  }
  50% {
    width: 80%;
    height: 110%;
    opacity: 0.2;
  }
  to {
    width: 260%;
    height: 260%;
    opacity: 0;
  }
`;

const ProviderItemExpanded: FC<ComponentProps> = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { lang, t } = useLanguageHook();
  const navigate = useNavigate();
  const providerItems = useSelector(
    (state: ApplicationState) => state.provider.items
  );
  const dataIsLoaded = providerItems.length > 0;

  // URL query support

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  return (
    <Box
      sx={{
        margin: "auto",
        width: "100%",
        maxWidth: 1280,
        padding: theme.spacing(2, 4, 3),
      }}
    >
      <Grid container>
        <Grid justifyContent="space-between" container>
          <Grid item>
            <h2>{t.DataPage.First}</h2>
          </Grid>
        </Grid>
        <Grid justifyContent="space-between" container>
          <Grid item xs={12} md={5}>
            <HelperPaper
              list={props.item.strings[lang].help}
              language={lang}
              title={props.item.strings[lang].name}
            />
          </Grid>
          <Grid item xs={12} md={7} container spacing={1}>
            <Grid item xs={12}>
              <Dropzone />
            </Grid>
            <Grid item container justifyContent="center">
              <Grid item>
                {providerItems.length > 0 && (
                  <Paper
                    variant="outlined"
                    sx={{
                      margin: "2em",
                      //maxWidth: 250,
                      //minWidth: 250,
                      textAlign: "center",
                      padding: "1em",
                    }}
                  >
                    <Typography variant="subtitle2">
                      {t.DataPage.DataExports}:
                    </Typography>
                    {providerItems.map((d) => (
                      <ProviderSelectItemFiles key={d.id} item={d} />
                    ))}
                  </Paper>
                )}
              </Grid>
            </Grid>
            <Grid item container justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!dataIsLoaded}
                  sx={{
                    "&::before": !dataIsLoaded
                      ? {}
                      : {
                          content: '""',
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          zIndex: 0,
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          animation: `${ring} 1500ms linear 0ms infinite`,
                          borderRadius: "100%",
                          border: "6px solid " + theme.palette.primary.light,
                        },
                  }}
                  onClick={() => {
                    navigate(
                      "/visualization/?view=" +
                        VisTypes.Files +
                        "&tic=" +
                        query.get("tic")
                    );
                    dispatch(switchVis(VisTypes.Files));
                  }}
                >
                  {t.DataPage.ExploreActionButton}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProviderItemExpanded;
