import Button, { ButtonProps } from '@mui/material/Button';
import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

/**
 * Workaround solution to link buttons, source: https://github.com/mui-org/material-ui/issues/7186
 */
const LinkButton : FC<ButtonProps & LinkProps> = (props : any) => {
  return (
    <Button component={Link} {...props} />
  );
};

export default LinkButton;