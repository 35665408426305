import { VisTypes } from 'constants/vis';
import { Reducer, AnyAction } from 'redux';
import { TrackingActionTypes, InteractionTypes, trackingState } from './types';

// Initial state
const initialState: trackingState = {
  timeline: {
    'checkbox': 0,
    'filter': 0,
    'hover': 0,
    'viewsec': 0
  },
  treemap: {
    'checkbox': 0,
    'filter': 0,
    'hover': 0,
    'viewsec': 0
  },
  visited: [VisTypes.Finalization]
};


// The reducer function
const reducer: Reducer<trackingState> = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case TrackingActionTypes.SET_TRACKING: {
      const { type, id, value } = action.payload as { type: VisTypes, id: InteractionTypes, value: number };
      if (type === VisTypes.Timeline)
        return Object.assign({}, state, { timeline: { ...state.timeline, [id]: state.timeline[id] + value } });
      if (type === VisTypes.Files)
        return Object.assign({}, state, { treemap: { ...state.treemap, [id]: state.treemap[id] + value } });
      console.warn('Tracking but not in Timeline or Treemap', type, id, value);
      return state;
    }
    case TrackingActionTypes.TRACK_VISITED: {
      const { type } = action.payload as { type: VisTypes };
      const stuff = state.visited.includes(type) ? state.visited : [...state.visited, type];
      return Object.assign({}, state, { visited: stuff });
    }
    default: {
      return state;
    }
  }
};

export { reducer as trackingReducer };