import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import * as d3 from "d3";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { COLORS, SELECT_COLOR, catColor } from "constants/color";
import { DataPoint, Category } from "providers/types";
import useLanguageHook from "containers/App/useLanguageHook";
import Tooltip from "containers/Components/Tooltip";

export interface TimelineSettings {
  height: number;
  nodecolor:
    | string
    | "solid"
    | "location"
    | "category"
    | "subcategory"
    | "provider";
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
  paddingTop: number;
  precision: number;
  private: boolean;
  timePadding: number;
  tooltipWidth: number;
  mouseSelectRadius: number;
  scaleCircleSize: boolean;
  width: number;
  subcolormap: { [key: string]: string };
}

const defaultSettings: TimelineSettings = {
  width: 1300,
  height: 800,

  paddingRight: 100,
  paddingTop: 50,
  paddingLeft: 50,
  paddingBottom: 50,
  timePadding: 0.01,
  tooltipWidth: 400,
  mouseSelectRadius: 15,
  scaleCircleSize: true,

  nodecolor: "solid", // | 'location' | 'category'| 'subcategory'
  precision: 6,
  private: false,
  subcolormap: {}, // TODO
};

const redLineDate = new Date(2023, 0, 25);

// Component-specific props.
interface ComponentProps {
  data: DataPoint[];
  settings: Partial<TimelineSettings>;
}

/**
 * A plot using Cartesian coordinates to display two time-related variables.
 * While time is one dimensional, the repetitive cycles are considered and
 * split into two dimensions. The x-axis is showing years and months over a
 * longer time-period, while the y-axis is showing a single day over a 24
 * hours period. This provides a familiar way to relate to the visualization.
 * A grid allows for better orientation and comparability. Each circle in
 * the visualization represents a data point which is colored according to
 * its category. To reduce overplotting, only a border of the circle is drawn.
 *
 *
 * @param props see TimelineSettings
 * @returns React.FC rendering
 */
const Timeline: FC<ComponentProps> = (props: ComponentProps) => {
  const colorCanvasRef = useRef<HTMLCanvasElement>(null);
  const interactCanvasRef = useRef<HTMLCanvasElement>(null);
  const zoomRef = useRef<HTMLDivElement>(null);
  const xaxisRef = useRef<SVGGElement>(null);
  const yaxisRef = useRef<SVGGElement>(null);
  const gridsRef = useRef<SVGGElement>(null);
  const theme = useTheme();
  const { t } = useLanguageHook();

  const settings: TimelineSettings = Object.assign(
    {},
    defaultSettings,
    props.settings
  );
  const canvasWidth = Math.floor(settings.width - settings.paddingRight);
  const canvasHeight = Math.floor(settings.height - settings.paddingTop);

  const data: DataPoint[] = props.data;

  const categories = React.useRef<{ [label: string]: string }>({});
  const [size, setSize] = useState(1 << 14);
  const [counter, setCounter] = useState(0);
  const animReq = React.useRef<number | undefined>();
  const findReq = React.useRef<number | undefined>();

  const [zoom, setZoom] = useState({
    k: 1,
    x: 0,
    y: 0,
  });

  const [tooltip, setTooltip] = useState({
    visible: false,
    category: "",
    subcat: "",
    date: "",
    text: "",
    uri: "",
    isFixed: false,
    id: -1,
  });
  const [tooltipPos, setTooltipPos] = useState({
    x: 0,
    y: 0,
  });

  //const d3ScaleX = useRef<d3.ScaleTime<number, number, never> | null>(null);
  //const d3ScaleY = useRef<d3.ScaleTime<number, number, never> | null>(null);
  const { xScale, yScale, xAxis, yAxis } = useMemo(
    () => getScales(),
    [
      props.data.length,
      zoom.k,
      zoom.x,
      zoom.y,
      settings.timePadding,
      canvasHeight,
      canvasWidth,
    ]
  );

  const nodeSizeScale: d3.ScaleLinear<number, number, never> = useMemo(
    () => getNodeSize(),
    [data]
  );

  // This useEffect block is run only once after the component mounts.
  useEffect(() => {
    // Attach zoom listener
    if (interactCanvasRef.current) {
      const { zoom } = getZoom();
      d3.select(interactCanvasRef.current).call(zoom);
    }
  }, []);

  // This useEffect is run every time the data or component sizes change.
  useEffect(() => {
    renderXAxis(xaxisRef.current);
    renderYAxis(yaxisRef.current);
    renderGrids(gridsRef.current);
    renderChart();

    return () => {
      if (animReq.current) window.cancelAnimationFrame(animReq.current);
    };
  }, [
    props.data.length,
    zoom.k,
    zoom.x,
    zoom.y,
    settings.nodecolor,
    settings.timePadding,
    settings.precision,
    settings.subcolormap,
    settings.scaleCircleSize,
    canvasHeight,
    canvasWidth,
  ]);

  // -----------------------------------------------------------------------------------------------------
  // Functions
  // -----------------------------------------------------------------------------------------------------

  function getZoom() {
    const beginning = data.length > 0 ? data[0].date.getTime() : 0;
    const ending = data.length > 0 ? data[data.length - 1].date.getTime() : 0;
    const day = 24 * 60 * 60 * 1000;

    const zoom = d3
      .zoom<HTMLCanvasElement, unknown>()
      .scaleExtent([1, Math.max(100, (ending - beginning) / day)])
      .extent([
        [0, 0],
        [canvasWidth, canvasHeight],
      ])
      .translateExtent([
        [0, -Infinity],
        [canvasWidth, Infinity],
      ])
      .on("zoom", ({ transform }: { transform: d3.ZoomTransform }) => {
        setZoom({
          k: transform.k,
          x: transform.x,
          y: transform.y,
        });
      });

    return { zoom };
  }

  function initalTimeframe() {
    const timeStart = data.length > 0 ? data[0].date.getTime() : 0;
    const timeEnd = data.length > 0 ? data[data.length - 1].date.getTime() : 0;
    const timePadding = (timeEnd - timeStart) * settings.timePadding;
    return { timeStart, timeEnd, timePadding };
  }

  function getScales() {
    const { timeStart, timeEnd, timePadding } = initalTimeframe();
    const day = 24 * 60 * 60 * 1000;
    const month = day * 31;

    const xScale = d3.zoomIdentity
      .translate(zoom.x, zoom.y)
      .scale(zoom.k)
      .rescaleX(
        d3
          .scaleTime()
          .domain([
            new Date(timeStart - timePadding),
            new Date(timeEnd + timePadding),
          ])
          .range([0, canvasWidth])
      );

    const yScale = d3
      .scaleTime()
      .domain([new Date(0), new Date(0 + day)])
      .range([0, canvasHeight - 12]);

    const yAxis = d3
      .axisLeft<Date>(yScale)
      //.tickFormat(d3.timeFormat("%H:%M"))
      .tickFormat((_, i) => `${(i + "").padStart(2, "0")}:00`)
      .tickArguments([d3.timeHour.every(1)])
      .tickSize(24);

    const xAxis = d3.axisTop(xScale);
    xAxis.tickSize(12);

    const diff = xScale.domain()[1].getTime() - xScale.domain()[0].getTime();
    xAxis.tickArguments(diff > month ? [] : [d3.timeDay.every(1)]);

    return {
      xScale,
      yScale,
      xAxis,
      yAxis,
    };
  }

  function dateToXScale(d: Date) {
    return xScale(
      dateToLocalTime(d) - (dateToLocalTime(d) % 86400000) + 43200000
    );
  }

  function dateToYScale(d: Date) {
    return yScale(dateToLocalTime(d) % 86400000);
  }

  function dateToLocalTime(d: Date): number {
    return d.getTime() - d.getTimezoneOffset() * 60000;
  }

  function getNodeSize() {
    const maxTextSize = d3.max(data, (d) => d.text.length);
    //const maxTextSize = Math.max(...data.map((d) => d.text.length));
    const textScale = d3
      .scaleLinear()
      .domain([0, maxTextSize || 15])
      .range([2.5, 15]);
    return textScale;
  }

  function getColor() {
    switch (settings.nodecolor) {
      case "location":
        return (d: DataPoint) => getPositionColor(d.text);
      case "category":
        return (d: DataPoint) => catColor(d.category as Category);
      case "subcategory":
        return (d: DataPoint) => settings.subcolormap[d.subcategory];
      case "provider":
        return (d: DataPoint) =>
          getProviderColorFromSubcategory(d.subcategory.charAt(0));
      case "solid":
      default:
        return (d: DataPoint) => "#5FAF77";
    }
  }

  function getProviderColorFromSubcategory(str: string) {
    // TODO: refactor this to some better place
    const plattforms = {
      F: "#4267B2",
      T: "#1DA1F2",
      G: "#0F9D58",
      I: "#E1306C",
      L: "#0A66C2",
      N: "#E50914",
    };

    if (str === "F") return "#4267B2";
    if (str === "T") return "#1DA1F2";
    if (str === "G") return "#0F9D58";
    if (str === "I") return "#E1306C";
    if (str === "L") return "#0A66C2";
    if (str === "N") return "#E50914";
    return "#000";
  }

  // TODO: refactor this
  function getPositionColor(name: string): string {
    if (!name) return "grey";

    // parses "(498275804, 85011349)" to "49,8" based on precision
    const c = name.indexOf(",");
    const p = name.indexOf(")");
    const o = name.indexOf("(");
    if (o !== 0 || c === -1 || p === -1) return "grey";
    const precision = settings.precision || 7;
    const lat = name.substring(1, c - 1 - precision); // -90 to 90
    const long = name.substring(c + 2, p - c - 2 - precision); //-180 to 180
    const id = lat + "," + long;

    return getCategoryColor(id);
  }

  function getCategoryColor(name: string): string {
    if (!categories.current[name]) {
      //let color = d3.lab(70, -100 + 200 * Math.random(), -100 +200*Math.random());
      setCounter((counter || 0) + 1);
      //categories[name] = d3.interpolateTurbo((counter%10)/10);
      //let color = "hsl(" + Math.random() * 360 + ",66%,66%)";
      //categories[name] = color;
      categories.current[name] = COLORS[counter % COLORS.length];
    }
    return categories.current[name];
  }

  function getTransition(): d3.Transition<any, unknown, null, undefined> {
    return d3.transition().duration(150).ease(d3.easeLinear);
  }

  // -----------------------------------------------------------------------------------------------------
  // Render
  // -----------------------------------------------------------------------------------------------------

  function renderXAxis(node: SVGGElement | null) {
    const dataJoin: d3.Selection<any, any, any, any> = d3
      .select(node)
      .selectAll(".xAxis")
      .data([{ xAxis }]);

    const dataEnter = dataJoin
      .enter()
      .append("g")
      .attr("class", "xAxis")
      .attr("stroke-width", "0px")
      .attr("width", canvasWidth)
      .attr("height", 50);

    dataEnter.merge(dataJoin).transition(getTransition()).call(xAxis);

    dataEnter
      .merge(dataJoin)
      .selectAll("text")
      .attr("font-size", "2em")
      .attr("transform", "translate(0,10)")
      .on("click", (event) => {
        console.log("TODO: add onlick zoom");
        console.log(event);
      });

    dataJoin.exit().remove();
  }

  function renderYAxis(node: SVGGElement | null) {
    const dataJoin: d3.Selection<any, any, any, any> = d3
      .select(node)
      .selectAll(".yAxis")
      .data([{ yAxis }]);

    const dataEnter = dataJoin
      .enter()
      .append("g")
      .attr("class", "yAxis")
      .attr("stroke-width", "0px")
      .attr("height", canvasHeight)
      .attr("width", settings.paddingLeft);

    dataEnter
      .merge(dataJoin)
      .attr("transform", "translate(" + [5, 0] + ")")
      .transition(getTransition())
      .call(yAxis);

    dataEnter
      .merge(dataJoin)
      .selectAll("text")
      .attr("font-size", "1.5em")
      .attr("transform", "translate(10,0)");

    dataJoin.exit().remove();
  }

  function renderGrids(node: SVGGElement | null) {
    const dataJoin = d3
      .select(node)
      .selectAll<SVGGElement, d3.Axis<Date | d3.NumberValue>>(".xGrid")
      .data([{ xAxis }]);

    const dataEnter = dataJoin
      .enter()
      .append("g")
      .attr("class", "xGrid")
      .attr("stroke-width", "1px")
      .attr("color", "#2a2a2a");

    dataEnter
      .merge(dataJoin)
      .attr("transform", "translate(5,5)")
      .transition(getTransition())
      .call(xAxis.tickSize(-canvasHeight).tickFormat("" as any));

    dataJoin.exit().remove();

    const dataJoin2: d3.Selection<any, any, any, any> = d3
      .select(node)
      .selectAll(".yGrid")
      .data([{ yAxis }]);

    const dataEnter2 = dataJoin2
      .enter()
      .append("g")
      .attr("class", "yGrid")
      .attr("stroke-width", "1px")
      .attr("color", "#2a2a2a");

    dataEnter2
      .merge(dataJoin2)
      .attr("transform", "translate(5,5)")
      .transition(getTransition())
      .call(
        yAxis
          .ticks(8)
          .tickSize(-canvasWidth)
          .tickFormat("" as any)
      );

    dataJoin2.exit().remove();
  }

  function renderChart() {
    if (colorCanvasRef.current) {
      const ctx = colorCanvasRef.current.getContext("2d");
      if (ctx) {
        if (animReq.current) {
          // clear current animation
          window.cancelAnimationFrame(animReq.current);
        }
        animReq.current = window.requestAnimationFrame(() =>
          chunkNextData(ctx, 0, 0)
        );
      }
    }
  }

  function chunkNextData(
    context: CanvasRenderingContext2D,
    progress: number,
    progressEnd: number
  ) {
    if (progress === 0) {
      // emtpy drawing area
      context.clearRect(0, 0, settings.width, settings.height);

      // figure out how many items to draw
      progressEnd = 0;
      const start = xScale.domain()[0];
      const end = xScale.domain()[1];
      let progressSet = false;
      for (let i = 0; i < data.length; ++i) {
        if (!progressSet && data[i].date >= start) {
          progress = i;
          progressSet = true;
        }
        if (data[i].date >= end) {
          progressEnd = i;
          break;
        }
      }
      if (progressEnd === 0) {
        progressEnd = data.length;
      }
    }
    //const startTime = performance.now();
    drawCircles(context, progress, Math.min(data.length - 1, progress + size));
    //const duration = performance.now() - startTime;

    //if (duration > 50) {
    //setSize(Math.max(size - (1 << 12), 1 << 12));
    //console.log("[Timeline] Performance duration took: ", Math.floor(duration), " ms with", size);
    //}

    if (progress + size < progressEnd) {
      animReq.current = window.requestAnimationFrame(() =>
        chunkNextData(context, progress + size, progressEnd)
      );
    } else {
      animReq.current = undefined;
    }
  }

  function drawCircles(
    context: CanvasRenderingContext2D,
    start: number,
    end: number
  ) {
    context.save();
    context.translate(0, 0);
    context.lineWidth = 1;
    const tau = 2 * Math.PI;
    const color = getColor();
    //context.globalAlpha = 1;

    /*
    // faster drawing, but doesnt work for all use cases yet
    Object.entries(Category).forEach(([name, category]) => {
      context.beginPath();
      let arr = data
        .slice(start, end)
        .filter(d => d.category === category);

      if(arr.length > 0){
        context.strokeStyle = color(arr[0]);
        context.fillStyle = color(arr[0]) + "11";
      }

      for(let item of arr){
        context.arc(
          dateToXScale(item.date),
          dateToYScale(item.date),
          size, 0, tau);
      }
      context.fill();
      context.stroke();
    });
    */
    for (let i = start; i < end; i++) {
      context.beginPath();
      context.strokeStyle = color(data[i]);
      context.fillStyle = color(data[i]) + "11";
      context.arc(
        dateToXScale(data[i].date),
        dateToYScale(data[i].date),
        settings.scaleCircleSize ? nodeSizeScale(data[i].text.length) : 4,
        0,
        tau
      );
      context.fill();
      context.stroke();
    }
    context.restore();
  }

  function setPositionTooltip(i: number) {
    if (i > data.length) {
      i = i - 1;
    } else if (i < 0) {
      i = i + 1;
    }
    const ix = dateToXScale(data[i].date);
    const iy = dateToYScale(data[i].date);
    updateTooltip(ix, iy, data[i], i);
  }

  function updateTooltip(
    left: number,
    right: number,
    res: DataPoint,
    position: number
  ): void {
    setTooltip({
      category: res.category,
      visible: true,
      subcat: res.subcategory.substr(2),
      date: res.date.toLocaleString(),
      text: res.text,
      uri: res.uri ? res.uri : "",
      isFixed: tooltip.isFixed,
      id: position,
    });
    setTooltipPos({
      x: left,
      y: right,
    });
  }

  function mouseClickListener() {
    setTooltip({
      ...tooltip,
      isFixed: !tooltip.isFixed,
    });
  }

  function mouseMoveListener(
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>
  ) {
    const [mouseX, mouseY] = d3.pointer(event);
    if (tooltip.isFixed) return;
    if (findReq.current) {
      // clear current animation
      window.cancelAnimationFrame(findReq.current);
    }
    findReq.current = window.requestAnimationFrame(() => {
      const [res, pos] = findNearestPoint(
        mouseX,
        mouseY,
        settings.mouseSelectRadius
      );
      if (res !== undefined) {
        // Show tooltip
        document.body.style.cursor = "pointer";
        updateTooltip(event.pageX, event.pageY, res, pos);

        const tmp = res;
        window.requestAnimationFrame(() =>
          drawMouse(
            dateToXScale(tmp.date),
            dateToYScale(tmp.date),
            settings.scaleCircleSize ? nodeSizeScale(tmp.text.length) : 4,
            SELECT_COLOR.primary,
            getColor()(tmp)
          )
        );
      } else {
        if (tooltip.visible) {
          // Hide tooltip
          document.body.style.cursor = "auto";
          setTooltip({
            ...tooltip,
            visible: false,
          });
        }
        window.requestAnimationFrame(() =>
          drawMouse(
            mouseX,
            mouseY,
            settings.mouseSelectRadius,
            SELECT_COLOR.secondary,
            ""
          )
        );
      }
    });
  }

  function findNearestPoint(
    x: number,
    y: number,
    radius: number
  ): [DataPoint | undefined, number] {
    let r = radius * radius;
    let i,
      n,
      closest = undefined,
      dx,
      dy,
      d2,
      ix,
      iy,
      pos = -1;

    // we get the approximate x position because the data is spread over the x axis
    // then we pad the data left and right
    const leftday = xScale.invert(x - r);
    const rightday = xScale.invert(x + r);
    i = d3
      .bisector((a: DataPoint, b: Date) => a.date.getTime() - b.getTime())
      .left(data, leftday);
    n = d3
      .bisector((a: DataPoint, b: Date) => a.date.getTime() - b.getTime())
      .right(data, rightday);
    //let padding = data.length * 0.1;
    //i = Math.max(Math.floor(index-padding), 0);
    //n = Math.min(Math.floor(index+padding), data.length);

    // iterate over nearby data to find perfect match
    for (; i < n; ++i) {
      ix = dateToXScale(data[i].date);
      iy = dateToYScale(data[i].date);
      // bounding box cutting
      if (ix - r >= x || ix + r <= x || iy - r >= y || iy + r <= y) continue;
      dx = x - ix;
      dy = y - iy;
      d2 = dx * dx + dy * dy;
      if (d2 < r) {
        closest = data[i];
        pos = i;
        r = d2;
      }
    }
    return [closest, pos];
  }

  function drawMouse(
    x: number,
    y: number,
    radius: number,
    color: string,
    color2: string
  ) {
    const context = interactCanvasRef?.current?.getContext("2d");
    if (context) {
      context.clearRect(0, 0, canvasWidth, canvasHeight);
      context.save();
      context.translate(0, 0);

      context.globalAlpha = 1;

      // Nodes
      context.beginPath();
      if (color2 !== "") {
        context.fillStyle = "#ffffffaa";
        context.arc(x, y, settings.mouseSelectRadius, 0, 2 * Math.PI);
        context.fill();
        context.beginPath();
        context.fillStyle = color2;
        context.arc(x, y, radius, 0, 2 * Math.PI);
        context.fill();
      } else {
        context.fillStyle = color;
        context.arc(x, y, radius, 0, 2 * Math.PI);
        context.fill();
      }
      context.restore();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // Return
  // -----------------------------------------------------------------------------------------------------

  return (
    <Box sx={{}}>
      <div
        ref={zoomRef}
        style={{
          position: "relative",
          transform: `translate(${settings.paddingTop}px,${settings.paddingLeft}px)`,
        }}
      >
        <canvas
          ref={colorCanvasRef}
          height={canvasHeight}
          width={canvasWidth}
          style={{
            pointerEvents: "none",
            position: "absolute",
          }}
        />
        <canvas
          ref={interactCanvasRef}
          height={canvasHeight}
          width={canvasWidth}
          style={{
            position: "absolute",
          }}
          onClick={mouseClickListener}
          onMouseMove={mouseMoveListener}
          onMouseLeave={() => (document.body.style.cursor = "auto")}
        />
        {data.length === 0 && (
          <Box
            sx={{
              position: "absolute",
              transform: `translate(0px,${Math.max(
                0,
                canvasHeight / 2 - settings.paddingBottom
              )}px)`,
            }}
          >
            <Typography variant="h3" align="center">
              {t.DataPage.NoDataFound}
            </Typography>
          </Box>
        )}
      </div>
      <svg
        style={{
          height: settings.height,
          width: settings.width,
        }}
      >
        <g
          className="last-evaluation-line"
          transform={`translate(${xScale(redLineDate)}, 0)`}
        >
          <text
            className="evaltext"
            x={4}
            y={16}
            fill="#fff"
            fontFamily='"Fira Sans Condensed"'
          >
            {t.SurveyFinal.lastEval}
          </text>
          <rect
            className="redline"
            fill="#f00"
            opacity={0.8}
            shapeRendering="crisp-edges"
            pointerEvents={"none"}
            height={canvasHeight + 50}
            width={3}
          ></rect>
        </g>
        <g
          transform={`translate(${settings.paddingTop - 1},${
            settings.paddingLeft - 1
          })`}
        >
          <rect
            color="#111"
            width={canvasWidth}
            height={canvasHeight}
            fill="transparent"
            stroke="#111"
            strokeWidth="2px"
          />
        </g>
        <g
          transform={`translate(${settings.paddingTop},${settings.paddingLeft})`}
          ref={xaxisRef}
        ></g>
        <g
          transform={`translate(${settings.paddingTop},${settings.paddingLeft})`}
          ref={yaxisRef}
        ></g>
        <g
          transform={`translate(${settings.paddingTop},${settings.paddingLeft})`}
          ref={gridsRef}
        ></g>
      </svg>

      <Tooltip
        position={tooltip.visible ? tooltipPos : undefined}
        settings={{ tooltipWidth: settings.tooltipWidth, margin: 0 }}
        texts={[
          tooltip.category,
          tooltip.subcat + "\n" + tooltip.date,
          tooltip.text,
          "",
        ]}
        uri={tooltip.uri === "" ? undefined : tooltip.uri}
        category={tooltip.category}
        isFixed={tooltip.isFixed}
        /*
      // TODO: Maybe for another release
      clickToNext={() => {
        setPositionTooltip(tooltip.id + 1);
      }}
      clickToPrev={() => {
        setPositionTooltip(tooltip.id - 1);
    }}
    */
      />
    </Box>
  );
};

export default Timeline;
