import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  ButtonGroup,
  Button
} from '@mui/material';

import { ApplicationState } from 'store/index';
import { setLanguage } from 'store/language/actions';
import { LanguageTypes } from '@store/language/types';
import useLanguageHook from './useLanguageHook';

const LanguageSwitch: FC = () => {
  const language = useSelector((state: ApplicationState) => state.language.language);
  const supportedLangs = useSelector((state: ApplicationState) => state.language.supportedLangs);
  const dispatch = useDispatch();
  const { t } = useLanguageHook();

  return (
    <ButtonGroup sx={{margin: 1}}>
      {
        supportedLangs.map(d =>
          <Button key={d.name}
            color="info"
            variant={language === d.name ? 'contained' : undefined}
            onClick={() => dispatch(setLanguage(d.name as LanguageTypes))}>
            {d.text}
          </Button>
        )
      }
    </ButtonGroup>
  );
};

export default LanguageSwitch;
