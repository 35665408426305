export default {
  about: '/about',
  imprint: '/imprint',
  dsgvo: '/dsgvo',
  debug: '/debug',
  data: '/data',
  vis: '/visualization',
  landing: '/landing',
  changelog: '/changelog',
  root: '/',
};
