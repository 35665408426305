import { Reducer, AnyAction } from 'redux';
import { LanguageState, LanguageActionTypes, LanguageTypes } from './types';
import translations from 'constants/translations.json';

// Initial state
const initialState: LanguageState = {
  language: navigator.language.startsWith('de') ? 'de' : 'en',
  supportedLangs: getSupportedLanguages()
};

function getSupportedLanguages() {
  return Object.keys(translations)
    .map((d: string) => {
      return {
        'name': d as LanguageTypes,
        'text': translations[d as LanguageTypes].name
      };
    });
}

// The reducer function
const reducer: Reducer<LanguageState> = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case LanguageActionTypes.SET_LANGUAGE: {
      return Object.assign({}, state, { language: action.payload });
    }
    default: {
      return state;
    }
  }
};

export { reducer as languageReducer };