import { PropaneSharp } from '@mui/icons-material';
import { catColor } from 'constants/color';
import { FileCategory } from 'constants/filenames';
import Tooltip from 'containers/Components/Tooltip';
import { HierarchyRectangularNode } from 'd3';
import { getProvider } from 'providers';
import React, { FC, ReactNode, useMemo } from 'react';
import { FilePiece, getFileSize } from '.';

interface ToolTipSettings {
  tooltipWidth: number;
  margin: number;
  showImages: boolean;
}

interface Props {
  settings: ToolTipSettings;
  position?: { x: number; y: number };
  files?: HierarchyRectangularNode<FilePiece>;
  isFixed?: boolean;
}

const TooltipWrapper: FC<Props> = ({ settings, position, files, isFixed }) => {
  const getPath = (f: HierarchyRectangularNode<FilePiece>) => {
    const id = f.data?.path ?? '/';
    return id.replace(
      new RegExp(
        `treemap(\\/${getProvider(f.data.providerId).name}(\\/[^\\/]*))*\\/*`
      ),
      ''
    );
  };

  const category = useMemo(
    () =>
      (files?.data.data.length === 0
        ? files.data.fileType + ' File'
        : files?.data.data[0].category) ?? FileCategory.Other,
    [files]
  );

  const texts = useMemo<
    [ReactNode, ReactNode, ReactNode, ReactNode]
  >(() => {
    try {
      if (files) {
        const provider = getProvider(files.data.providerId);

        if (files.data.files.length > 1) {
          // Group of files
          return [
            `${files.data.files.length} Files`,
            provider.name,
            `Various files in folder:  ${files.data.groupBy === 'folder' ? files.data.folder : '/'
            }`,
            `File Path: /${getPath(files)}\nFile Size: ${getFileSize(files.value ?? 0)}`,
          ];
        } else if (files.data.datalength > 1) {
          // File with data elements
          const subc =
            files.data.datacategories[0][0] +
            (files.data.datacategories.length > 1
              ? ` and ${files.data.datacategories.length - 1} others.`
              : '');
          const subcats = subc.startsWith(files.data.providerId)
            ? subc.substring(files.data.providerId.length)
            : subc;

          return [
            files.data.fileName,
            provider.name,
            <>
              Contains {files.data.datalength}{' '}
              <span style={{ color: catColor(category) }}>
                {files.data.dataCategory}
              </span>{' '}
              elements of type {subcats}
            </>,
            `File Path: /${getPath(files)}\nFile Size: ${getFileSize(files.data.uncompressedSize)}`,
          ];
        } else if (files.data.fileType == FileCategory.Picture) {
          // File without data elements
          return [
            files.data.fileName,
            provider.name,
            <>
              {files.data.fileType} from {provider.name}
              {!settings.showImages && files.data.binaryData ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <a href={files.data.binaryData} target="_blank" rel="noopener noreferrer">
                    <img
                      src={files.data.binaryData}
                      style={{ maxWidth: '100%', maxHeight: '20vh' }}
                    />
                  </a>
                </div>
              ) : null}
            </>,
            `File Path: /${getPath(files)}\nFile Size: ${getFileSize(files.value ?? 0)}`,
          ];
        } else {
          // File without data elements
          return [
            files.data.fileName,
            provider.name,
            `${files.data.fileType} from ${provider.name}. It contains some data we cannot display.`,
            `File Path: /${getPath(files)}\nFile Size: ${getFileSize(files.value ?? 0)}`,
          ];
        }
      }
    } catch (e) { }

    return [null, null, null, null];
  }, [files]);

  return (
    <Tooltip
      position={position}
      settings={settings}
      texts={texts}
      category={category}
      showFileIcon={true}
      isFixed={isFixed}
    />
  );
};

export default TooltipWrapper;
