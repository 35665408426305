import { ApplicationState } from "store/index";
import { Box, Button, useTheme } from "@mui/material";
import { ColorCategories } from "store/categories/types";
import { deselectAllItems, selectAllItems } from "@store/categories/actions";
import { Item } from "store/selection/types";
import { sidebarWidth } from "constants/vis";
import { Stack } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import { VisTypes } from "constants/vis";
import CategoryList from "./CategoryList";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import FilesList from "./FilesList";
import React, { FC } from "react";
import Typography from "@mui/material/Typography";
import useTranslations from "containers/App/useLanguageHook";

// Component-specific props.
interface ComponentProps {
  options: Item[];
  callback: (id: string, value: any) => void;
}

const SidebarLeft: FC<ComponentProps> = () => {
  const currentVis = useSelector(
    (state: ApplicationState) => state.options.currentVis
  );
  const active = useSelector(
    (state: ApplicationState) => state.options.sidebarOpen
  );
  const { t } = useTranslations();
  const theme = useTheme();
  const dispatch = useDispatch();

  function refactorMePls() {
    // TODO: this does not seem to belong here, the categories state should know
    // which is the active color scheme, not some component
    // but its much easier for now...
    if (currentVis === VisTypes.Files) return ColorCategories.files;
    //if (currentVis === VisTypes.Timeline && subs)
    //return ColorCategories.subcategories;
    return ColorCategories.categories;
  }

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: sidebarWidth,
        },
      }}
      sx={{
        width: sidebarWidth,
        flexGrow: 1,
      }}
      variant="persistent"
      open={active}
      anchor="left"
    >
      <Box sx={{ toolbar: theme.mixins.toolbar, height: "60px" }} />
      <Typography
        variant="h5"
        sx={{
          alignSelf: "center",
          fontWeight: 500,
          padding: 1,
        }}
      >
        {t.Vis.DataExportsTitle}
      </Typography>
      <FilesList />
      <Divider />
      <Typography
        variant="h5"
        sx={{
          alignSelf: "center",
          fontWeight: 500,
          padding: 1,
        }}
      >
        {t.Vis.CategoriesTitle}
      </Typography>
      <CategoryList />
      {/*Alle Abwählen Alle Auswählen */}
      <Stack sx={{ pt: 4 }} direction="row" spacing={1} justifyContent="center">
        <Button onClick={() => dispatch(deselectAllItems(refactorMePls()))}>
          {t.Vis.DeselectAll}
        </Button>
        <Button onClick={() => dispatch(selectAllItems(refactorMePls()))}>
          {t.Vis.SelectAll}
        </Button>
      </Stack>
    </Drawer>
  );
};

export default SidebarLeft;
