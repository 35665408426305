import { action } from 'typesafe-actions';

import { InteractionTypes, TrackingActionTypes } from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '..';
import { VisTypes } from 'constants/vis';

export function track(id: InteractionTypes, value: number): any {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    const state = getState();

    dispatch(action(TrackingActionTypes.SET_TRACKING, { type: state.options.currentVis, id: id, value: value }));
  };
}

export function trackVisited(id: VisTypes): any {
  return (dispatch: Dispatch) => {
    dispatch(action(TrackingActionTypes.TRACK_VISITED, { type: id }));
  };
}