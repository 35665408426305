import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Theme, Paper, Grid, Button, Typography } from '@mui/material';
import { ApplicationState } from 'store/index';
import { loadSampleDataset } from '@store/files/actions';
import { ProviderDatum } from '@store/providers/types';
import { ProviderDefinition } from 'providers/types';
import { ProviderSelectItem, ProviderSelectItemFiles } from './ProviderSelectItem';
import ProviderItemExpanded from './ProviderItemExpanded';
import useTranslations from 'containers/App/useLanguageHook';
import { getProviders } from 'providers';


const ProviderView: FC = () => {
  const providerItems = useSelector((state: ApplicationState) => state.provider.items);
  const providers = useSelector((state: ApplicationState) => state.provider.providers);
  const dispatch = useDispatch();
  const [item, setItem] = useState<ProviderDefinition | undefined>(undefined);
  const { t } = useTranslations();
  const isDevelopmentEnvironment = process.env.NODE_ENV === 'development';

  return (
    <Grid
      container
      justifyContent="center"
      spacing={2}
      sx={{
        maxWidth: 1500,
        margin: 'auto',
      }}
    >
      <Grid item xs={12}>
        <ProviderItemExpanded
          item={getProviders()[0][0]}
          files={providerItems}
          onClose={() => null}
        />
        {
          isDevelopmentEnvironment &&
          renderDemoDataset()
        }
      </Grid>
    </Grid>
  );


  function renderDemoDataset() {
    const files = providerItems.filter(d => d.providerId === 'sample');

    return <>
      <Paper variant="outlined" sx={{
        margin: '1em',
        position: 'relative',
        maxWidth: 300,
        minWidth: 300,
        minHeight: 100,
        textAlign: 'center',
        padding: '1.5em',
      }}>
        <Typography variant="subtitle2">
          {t.DataPage.DemoDatasetPre}
        </Typography>
        <Button variant="contained" onClick={(e) => dispatch(loadSampleDataset())}>
          {t.DataPage.DemoDataset}
        </Button>
      </Paper>
      {files.length > 0 &&
        <Paper variant="outlined" sx={{
          margin: '1em',
          marginLeft: '2.5em',
          marginTop: '-10px',
          position: 'relative',
          maxWidth: 250,
          minWidth: 250,
          textAlign: 'center',
          padding: '.5em'
        }}>
          <Typography variant="subtitle2">
            {t.DataPage.DataExports}:
          </Typography>
          {files.map(d =>
            <ProviderSelectItemFiles key={d.id} item={d} />
          )}
        </Paper>
      }
    </>;
  }

};

export default ProviderView;
