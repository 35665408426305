import { action } from 'typesafe-actions';
import { OptionsActionTypes } from './types';

import { VisTypes } from 'constants/vis';
import { Dispatch } from 'redux';

export function selectItem(id: string, value: any): any {
  return (dispatch: Dispatch) => {
    //const { providerFilter, nodeFilter, folderFilter } = getState().Options;
    dispatch(action(OptionsActionTypes.SELECT, { id: id, value: value }));
  };
}
export function toggleItem(label: string): any {
  return (dispatch: Dispatch) => {
    dispatch(action(OptionsActionTypes.TOGGLE, label));
  };
}

export function switchVis(label: VisTypes): any {
  return (dispatch: Dispatch) => {
    dispatch(action(OptionsActionTypes.SWITCH_VIS, { label: label }));
  };
}
export function search(label: string): any {
  return (dispatch: Dispatch) => {
    dispatch(action(OptionsActionTypes.SEARCH, { label: label }));
  };
}
export function sidebar(label?: boolean): any {
  return (dispatch: Dispatch) => {
    dispatch(action(OptionsActionTypes.SIDEBAR, { label: label }));
  };
}
export function enableExports(label: boolean): any {
  return (dispatch: Dispatch) => {
    dispatch(action(OptionsActionTypes.EXPORTS, { label: label }));
  };
}
export function enableMentalModels(label: boolean): any {
  return (dispatch: Dispatch) => {
    dispatch(action(OptionsActionTypes.MENTALMODELS, { label: label }));
  };
}
export function enableFixedDataset(label: boolean): any {
  return (dispatch: Dispatch) => {
    dispatch(action(OptionsActionTypes.FIXEDDATASET, { label: label }));
  };
}