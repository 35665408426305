import React, { FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinkButton from 'containers/App/LinkButton';
import routeConfig from '@config/routes';
import useLanguageHook from 'containers/App/useLanguageHook';
import { useLocation } from 'react-router-dom';
import logo from 'resources/ColorOrange.svg';
import { borderRadius } from '@mui/system';

//import BmbfSVG from 'resources/bmbf.svg';
//import HmwkSVG from 'resources/hmwk.svg';

const LandingPage: FC = () => {
  const { t } = useLanguageHook();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  return (
    <main>
      {/* Hero unit */}
      <Box sx={{ position: 'relative', zIndex: 3, pt: 8, pb: 8 }}>
        <Container maxWidth="lg">
          {/* Title */}
          <Typography
            variant="h2"
            align="center"
            sx={{
              transform: 'translateX(-100px)',
            }}
            color="text.primary"
            fontFamily="Alex Brush, Helvetica, Arial, sans-serif"
            gutterBottom
          >
            {t.LandingPage.Welcome}
          </Typography>
          <Typography
            variant="h1"
            align="center"
            color="text.primary"
            gutterBottom
          >
            {t.LandingPage.Header}
            <img
              src={logo}
              style={{
                height: '1em',
                margin: '0 20px',
                borderRadius: 10,
                transform: 'translateY(.15em)',
              }}
            />
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            {t.LandingPage.Subtitle}
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
            dangerouslySetInnerHTML={{ __html: t.LandingPage.InfoDataProcessing }}
          >
          </Typography>

          {/* Action */}
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={1}
            justifyContent="center"
          >
            <LinkButton
              size="large"
              variant="contained"
              color="primary"
              to={{
                pathname: routeConfig.vis,
                search: '?tic=' + query.get('tic'),
              }}
            >
              {t.LandingPage.ButtonGo} <ChevronRight />
            </LinkButton>
          </Stack>
        </Container>
      </Box>
    </main>
  );
};

export default LandingPage;
