import { Category, DataPoint, toDataPoint, ProviderFile, FileCategory } from 'providers/types';
import { LanguageTypes } from 'store/language/types';

const firstNames = ['Alexander', 'Andrea', 'Andreas', 'Angelika', 'Anja', 'Anna', 'Barbara', 'Ben', 'Bernd', 'Berta', 'Bertha', 'Birgit', 'Brigitte', 'Carl', 'Christa', 'Christian', 'Christina', 'Claudia', 'Curt', 'Daniel', 'Dennis', 'Dieter', 'Edith', 'Elfriede', 'Elias', 'Elisabeth', 'Elke', 'Elsa', 'Emilia', 'Emily', 'Emma', 'Erika', 'Erna', 'Ernst', 'Felix', 'Finn', 'Frank', 'Frieda', 'Friedrich', 'Gabriele', 'Gerda', 'Gerhard', 'Gertrud', 'Gisela', 'Gustav', 'Günter', 'Hannah', 'Hans', 'Heike', 'Heinrich', 'Heinz', 'Helga', 'Helmut', 'Herbert', 'Hermann', 'Hertha', 'Hildegard', 'Horst', 'Ilse', 'Inge', 'Ingeborg', 'Ingrid', 'Irmgard', 'Jan', 'Jannik', 'Jennifer', 'Jessika', 'Jonas', 'Julia', 'Jörg', 'Jürgen', 'Karin', 'Karl', 'Katharina', 'Katrin', 'Klaus', 'Käthe', 'Lara', 'Laura', 'Lea', 'Lena', 'Leon', 'Leonie', 'Lieselotte', 'Lilli', 'Lina', 'Lisa', 'Luca', 'Luis', 'Lukas', 'Manfred', 'Marcel', 'Margarete', 'Maria', 'Marie', 'Marion', 'Markus', 'Marta', 'Martin', 'Martina', 'Matthias', 'Max', 'Maximilian', 'Melanie', 'Mia', 'Michael', 'Michelle', 'Monika', 'Nadine', 'Nicole', 'Niklas', 'Noah', 'Oliver', 'Otto', 'Patrick', 'Paul', 'Peter', 'Petra', 'Philipp', 'Renate', 'Rolf', 'Sabine', 'Sabrina', 'Sandra', 'Sarah', 'Sebastian', 'Sofia', 'Stefan', 'Stefanie', 'Susanne', 'Tanja', 'Thomas', 'Tim', 'Tobias', 'Tom', 'Torsten', 'Ursula', 'Ute', 'Uwe', 'Walter', 'Werner', 'Wilhelm', 'Wolfgang'];
const lastNames = ['Albrecht', 'Arnold', 'Bachmann', 'Barth', 'Bauer', 'Baumann', 'Beck', 'Becker', 'Berg', 'Berger', 'Bergmann', 'Beyer', 'Brandt', 'Braun', 'Brunner', 'Busch', 'Böhm', 'Demir', 'Dietrich', 'Engel', 'Ernst', 'Fischer', 'Frank', 'Franke', 'Franz', 'Frey', 'Friedrich', 'Fritz', 'Fuchs', 'Graf', 'Gruber', 'Günther', 'Haas', 'Hahn', 'Hansen', 'Hartmann', 'Heinrich', 'Hermann', 'Herrmann', 'Herzog', 'Hofer', 'Hoffmann', 'Hofmann', 'Horn', 'Huber', 'Jahn', 'Jansen', 'Jung', 'Jäger', 'Kaiser', 'Kaufmann', 'Kaya', 'Keller', 'Kern', 'Klein', 'Koch', 'Kramer', 'Kraus', 'Krause', 'Krämer', 'Krüger', 'Kuhn', 'Kunz', 'Köhler', 'König', 'Kühn', 'Lang', 'Lange', 'Langer', 'Lehmann', 'Lenz', 'Lindner', 'Lorenz', 'Ludwig', 'Maier', 'Martin', 'Maurer', 'Maus', 'Mayer', 'Meier', 'Meyer', 'Michel', 'Miller', 'Moser', 'Mueller', 'Möller', 'Müller', 'Neumann', 'Nowak', 'Otto', 'Paul', 'Peter', 'Peters', 'Petersen', 'Pfeiffer', 'Pohl', 'Reinhardt', 'Richter', 'Riedel', 'Ritter', 'Rose', 'Roth', 'Sauer', 'Schmid', 'Schmidt', 'Schmitt', 'Schmitz', 'Schneider', 'Scholz', 'Schreiber', 'Schröder', 'Schubert', 'Schulte', 'Schulz', 'Schulze', 'Schumacher', 'Schuster', 'Schwarz', 'Schäfer', 'Seidel', 'Seifert', 'Simon', 'Sommer', 'Stein', 'Steiner', 'Thomas', 'Vogel', 'Vogt', 'Voigt', 'Wagner', 'Walter', 'Weber', 'Weiss', 'Wenzel', 'Werner', 'Wilhelm', 'Winkler', 'Winter', 'Wolf', 'Yildirim', 'Yildiz', 'Yilmaz', 'Ziegler', 'Zimmermann'];
const loremIpsum = ':) :( :D 😀 😊 \n \n lol accusamus accusantium adipisci alias aliquam aliquid amet animi aperiam architecto asperiores aspernatur assumenda at atque aut autem beatae blanditiis commodi consectetur consequatur consequuntur corporis corrupti culpa cumque cupiditate debitis delectus deleniti deserunt dicta dignissimos distinctio dolor dolore dolorem doloremque dolores doloribus dolorum ducimus dunt ea eaque earum eius eligendi enim eos error esse est et eum eveniet ex excepturi exercitationem expedita explicabo facere facilis fuga fugiat fugit harum hic id illo illum impedit in inci inea inventore ipsa ipsam ipsum iste iure iusto labore laborum laudantium libero magnam magni maiores maxime minima minus modi molestiae molestias mollitia nam natus necessitatibus nemo neque nesciunt nihil nisi nobis non nostrum nulla numquam obcaecati odio odit officia officiis omnis optio pariatur perferendis perspiciatis placeat porro possimus praesentium provident quae quaerat quam quasi qui quia quibusdam quidem quis quis quisquam quo quod quos ratione recusandae reiciendis rem repellendus rerum saepe sapiente sed sed sequi similique sint sit soluta sunt suscipitlaboriosam tempora temporibus tenetur totam ullam unde ut ut vel velit veniam veritatis vero vitae voluptate'.split(' ');
const cities = ['Berlin', 'Hamburg', 'Munich', 'Cologne', 'Frankfurt am Main', 'Düsseldorf', 'Leipzig', 'Dortmund', 'Essen', 'Bremen', 'Dresden', 'Hanover', 'Duisburg', 'Bochum', 'Bielefeld', 'Bonn', 'Augsburg', 'Brunswick', 'Aachen', 'Chemnitz', 'Darmstadt'];

// Filename, FileType, Folder
const facebookBlueprint = [
  ['your_address_books.json', 'Text', 'about_you'],
  //["no-data.txt", "Text", "ads"],
  //["no-data.txt", "Text", "apps_and_websites"],
  //["no-data.txt", "Text", "comments"],
  //["no-data.txt", "Text", "events"],
  //["no-data.txt", "Text", "following_and_followers"],
  ['friends.json', 'Text', 'friends'],
  //["received_friend_requests.json", "Text", "friends"],
  ['sent_friend_requests.json', 'Text', 'friends'],
  //["your_group_membership_activity.json", "Text", "groups"],
  ['pages.json', 'Text', 'likes_and_reactions'],
  ['posts_and_comments.json', 'Text', 'likes_and_reactions'],
  //["no-data.txt", "Text", "location"],
  //["no-data.txt", "Text", "marketplace"],
  //["no-data.txt", "Text", "other_activity"],
  //["no-data.txt", "Text", "pages"],
  ['0.json', 'Text', 'photos_and_videos'],
  ['your_posts_1.json', 'Text', 'posts'],
  ['profile_information.json', 'Text', 'profile_information'],
  ['profile_update_history.json', 'Text', 'profile_information'],
  //["no-data.txt", "Text", "saved_items_and_collections"],
  ['your_search_history.json', 'Text', 'search_history'],
  ['account_activity.json', 'Text', 'security_and_login_information'],
  ['administrative_records.json', 'Text', 'security_and_login_information'],
  ['logins_and_logouts.json', 'Text', 'security_and_login_information'],
  ['login_protection_data.json', 'Text', 'security_and_login_information'],
  ['where_you\'re_logged_in.json', 'Text', 'security_and_login_information'],
  //["no-data.txt", "Text", "stories"],
  //["no-data.txt", "Text", "your_places"]
];

// TODO: move to translations json
const translation = {
  'en': {
    'ChatWith': 'F Chat with ',
    'name': 'Your full name is ',
    'first': '\nYour first name is ',
    'middle': '\nYour middle name is ',
    'last': '\nYour last name is ',
    'current': '\n\nYour current email is ',
    'previousemail': '\nYour previous email is ',
    'pending': '\nYour pending email is: none',
    'ad': '\nYour ad account email is: none ',
    'city': '\nYour city is ',
    'birthday': '\n\nYour birthday is on ',
    'gender': '\n\nYour gender is ',
    'pronoun': '\nYour pronoun is ',
    'previousnames': '\n\nYour previous names are ',
    'other': '\nYour other names are ',
    'family': '\nYour family members are ',
    'and': ' and ',
    'male': 'Male',
    'female': 'Female',
    'non': 'Gender Non-confirming',
    'liked': 'You liked the page of ',
    'AddressBook': 'F Address Book',
    'Friend': 'F Friend',
    'PageLikes': 'F Page Likes',
    'post': 'You liked a post of ',
    'Postings': 'F Postings',
    'YourPosts': 'F Your Posts',
    'posted': 'You posted:\n',
    'Profile': 'F Profile Information',
    'placeChange': ' changed their place of residence to ',
    'ProfileUpdate': 'F Profile Updates',
    'searched': 'You searched for',
    'signin': 'You signed in to www.facebook.com',
    'signout': 'You signed out off www.facebook.com',
    'Security': 'F Security: Login and Logout',
    'Login': 'F Security: Login Protection'
  },
  'de': {
    'ChatWith': 'F Chat mit ',
    'name': 'Dein ganzer Name ist ',
    'first': '\nDein Vorname ist ',
    'middle': '\nDein mittlerer Name ist ',
    'last': '\nDein Nachname ist ',
    'current': '\n\nDeine aktuelle Email ist ',
    'previousemail': '\nDeine Vorherige Email ist ',
    'pending': '\nDeine ausstehende E-Mail lautet: keine',
    'ad': '\nDie E-Mail-Adresse deines Werbekontos lautet: keine',
    'city': '\nDeine Stadt lautet ',
    'birthday': '\n\nDein Geburtsdatum ist am ',
    'gender': '\n\nDein Geschlecht ist ',
    'pronoun': '\nDeine Pronomen sind ',
    'previousnames': '\n\nDeine vorherigen Namen sind: ',
    'other': '\nDeine anderen Namen sind: ',
    'family': '\nDeine Familien Mitglider sind ',
    'and': ' und ',
    'male': 'Männlich',
    'female': 'Weiblich',
    'non': 'Divers',
    'liked': 'Du hast die Seite gelikt von ',
    'AddressBook': 'F Adressbuch',
    'Friend': 'F Freunde',
    'PageLikes': 'F Seiten Likes',
    'post': 'Du hast einen Beitrag gelikt von ',
    'Postings': 'F Beiträge',
    'YourPosts': 'F Deine Beiträge',
    'posted': 'Du hast veröffentlicht.:\n',
    'Profile': 'F Profil Informationen',
    'placeChange': ' hat ihren Wohnsitz verlegt nach ',
    'ProfileUpdate': 'F Profil Änderungen',
    'searched': 'Du suchstest nach',
    'signin': 'Sie haben sich bei www.facebook.com angemeldet.',
    'signout': 'Sie haben sich bei www.facebook.com abgemeldet.',
    'Security': 'F Sicherheit: Anmeldung und Abmeldung',
    'Login': 'F Sicherheit: Login-Schutz'
  }
};

type Person = {
  name: string,
  age: number,
  gender: string,
  email: string,
  birthday: Date,
  city: string
}

export default class dataGenerator {
  static generate(seed = 0, lang: LanguageTypes = 'en'): ProviderFile[] {
    if (seed === 0) {
      seed = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    } else {
      seed = Math.floor(seed);
    }
    let s = seed + 1;
    const t = translation[lang];

    // uniform distribution between 0 and 1
    function random(): number {
      s = Math.sin(s) * 10000;
      return s - Math.floor(s);
    }

    function random_array<T>(array: T[]): T {
      return array[Math.floor(random() * (array.length - 1))];
    }

    // Standard Normal variate using Box-Muller transform.
    function randn_bm(min: number, max: number, skew: number) {
      if (min === max)
        return min;
      let u = 0, v = 0;
      while (u === 0) u = random(); //Converting [0,1) to (0,1)
      while (v === 0) v = random();
      let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);

      num = num / 10.0 + 0.5; // Translate to 0 -> 1
      if (num > 1 || num < 0) num = randn_bm(min, max, skew); // resample between 0 and 1 if out of range
      num = Math.pow(num, skew); // Skew
      num *= max - min; // Stretch to fill range
      num += min; // offset to min
      return num;
    }

    function getName(): string {
      return random_array(firstNames) + ' ' + random_array(lastNames);
    }


    // "Your full name is Max Munstermann
    // Your first name is Max
    // Your last name is Munstermann
    // Your current email is max.mustermann@igd.fraunhofer.de
    // Your birthday is on Freitag, 29. Juli 1994
    // Your gender is MALE
    // Your pronoun is MALE
    // Your education experiences are Universität zu Köln (Graduated, school type: Hochschule) and Seoul National University (Graduated, school type: Schule)
    // Your pages are Other (Köln)
    // Your groups are Köln Kleinanzeigen (30. Juli 2019) and Beste Jobs in Köln und Umgebung (29. Juli 2019)
    // Your registration timestamp is 29. Juli 2019
    // Your profile uri is https://www.facebook.com/profile.php?id=100040153803145",

    function generatePerson(): Person {
      const name = getName();
      const birthday = randomDate(new Date(new Date().getFullYear() - 18 - Math.floor(random() * 70), 0, 1), new Date());

      return {
        name: name,
        age: new Date().getFullYear() - birthday.getFullYear(),
        gender: getPronoun(name),
        email: getEmail(name),
        birthday: birthday,
        city: random_array(cities)
      };
    }
    function getProfileInformation(me: Person, peopleStack: Person[]): string {
      let result = '';
      result += t.name + me.name;
      result += t.first + me.name.substr(0, me.name.indexOf(' '));
      result += t.middle;
      result += t.last + me.name.substr(me.name.indexOf(' ') + 1);
      result += t.current + me.email;
      result += t.previousemail + me.name.replace(' ', '_') + '@facebook.com';
      result += t.pending;
      result += t.ad;
      result += t.pronoun + me.city;
      result += t.birthday + me.birthday.toLocaleDateString();
      result += t.gender + me.gender;
      result += t.pronoun + me.gender;
      result += t.previousnames + me.name + '1';
      result += t.other + '';
      result += t.family +
        peopleStack[Math.floor(peopleStack.length * random())].name + ', ' +
        peopleStack[Math.floor(peopleStack.length * random())].name + t.and +
        peopleStack[Math.floor(peopleStack.length * random())].name;
      return result;
    }

    function randomDate(start: Date, end: Date) {
      return new Date(start.getTime() + random() * (end.getTime() - start.getTime()));
    }

    function getImageName() {
      const a = Math.floor((random() * 999999999999999));
      const b = Math.floor((random() * 999999999999999));
      const c = Math.floor((random() * 999999999999999));
      return a + '_' + b + '_o_' + c + '.jpg';
    }

    function generateImageFile() {
      // 10kb to 1mb
      return getFileObject(getImageName(), 'photos_and_videos', FileCategory.Picture, randn_bm(1024 * 10, 1024 * 1024 * 1, 5), []);
    }
    function generateVideoFile() {
      // 10kb to 1mb
      return getFileObject(getImageName(), 'photos_and_videos', FileCategory.Video, randn_bm(1024 * 100, 1024 * 1024 * 15, 5), []);
    }

    function generateMessageFile(index: number, startDate: number, diffTime: number, me: string, person: Person) {
      const times = randn_bm(1, index * index * 0.9 + 1, 2.5)*10;
      const messagesStack = [];
      const name = person.name;
      for (let i = 0; i < times; ++i) {
        const date = startDate + getDate(diffTime);
        const text = (random() < 0.5 ? name : me) + ': ' + getString(randn_bm(1, 200, 7));
        const sub = t.ChatWith + name + t.and + me;
        const message = toDataPoint(new Date(date), text, Category.Messages, sub);
        messagesStack.push(message);
      }
      messagesStack.sort((a, b) => a.date.getTime() - b.date.getTime());
      return getFileObject('message_1.json', 'messages/' + getFolderName(name), FileCategory.Text, 128 * times * 2, messagesStack);
    }

    function getString(len: number) {
      const res = [];
      for (let i = 0; i < len; ++i) {
        res.push(loremIpsum[Math.floor(random() * (loremIpsum.length - 1))]);
      }
      return res.join(' ');
    }

    function getEmail(name: string) {
      return name.replace(' ', '@') + '.com';
    }

    function getPronoun(name: string) {
      return random() > 0.1 ? (random() > 0.5 ? t.male : t.female) : t.non;
    }

    function getFolderName(name: string) {
      return name.replace(' ', '');
    }

    function getDate(diffTime: number) {
      const day = 1000 * 60 * 60 * 24;
      let result = random() > 0.2 ?
        randn_bm(0, diffTime, 2 + Math.sin(random())) :
        randn_bm(diffTime, 0, 2 + Math.cos(random()));
      if (random() < 0.2)
        return (result - (result % day)) + randn_bm(0, day, 1);
      if (random() < 0.2)
        return (result - (result % day)) + randn_bm(0, day, 1);
      // person should be sleeping, meaning less activity between 0 and 8 so we skip them
      const hours = toHours(toLocalTime(new Date(result)));
      if (hours < 7 && random() > 0.08)
        result += 1000 * 60 * 60 * (6 + 2 * random());

      return result;
    }

    function toHours(d: number) {
      return Math.floor((d / (1000 * 60 * 60)) % 24);
    }

    function toLocalTime(d: Date) {
      return d.getTime() - d.getTimezoneOffset() * 60000;
    }

    function getFileObject(fileName: string, folder: string, fileType: FileCategory, fileSize: number, data: DataPoint[]): ProviderFile {
      return {
        compressedSize: Math.floor(fileSize + 1),
        fileName: fileName,
        dataCategory: data.length === 0 ? 'Files' : data[0].category,
        fileType: fileType,
        folder: folder,
        hash: '',
        date: new Date(),
        isDir: false,
        path: folder + '/' + fileName,
        data: data.filter(d => new Date(d.date).getFullYear() > 1970 && new Date(d.date).getFullYear() <= new Date().getFullYear() + 1),
        uncompressedSize: Math.floor(1 + fileSize * Math.max(1, 0.9 + random() / 4)),
        groupBy: 'folder',
        providerId: 'sample',
        id: fileCounter++,
        binaryData: null,
        itemId: -1,
        mimeType: 'unknown/unknown'
      };
    }

    let fileCounter = 0;

    const objectStack: ProviderFile[] = [];
    const startDate = new Date('2000').getTime() +
      random() * (new Date('2010').getTime() - new Date('2000').getTime());
    let endDate = startDate + random() * (new Date('2017').getTime() - startDate);

    // half the time its today
    if (random() > 0.5) {
      endDate = new Date().getTime();
    } else {
      endDate = new Date().getTime();
    }
    const diffTime = endDate - startDate;
    const me = generatePerson();
    const peopleStack = [];
    const peopleCount = randn_bm(10, 100, 1);
    for (let j = 0; j < peopleCount; ++j) {
      peopleStack.push(generatePerson());
    }

    for (const file in facebookBlueprint) {
      switch (facebookBlueprint[file][0]) {
        case 'your_address_books.json': {
          //  {"date": Date, "text": "Schwester", "category": "Contacts", "subcategory": "F Address Book"},
          const messagesStack = [];
          let size = 0;
          for (let i = 0; i < peopleCount; i = i + 2) {
            const str = peopleStack[i].name;
            const message = toDataPoint(new Date(startDate + getDate(diffTime / 100)), str, Category.Contacts, t.AddressBook);
            size += (19 + 8 + 8 + str.length) * 2;
            messagesStack.push(message);
          }
          messagesStack.sort((a, b) => a.date.getTime() - b.date.getTime());
          objectStack.push(getFileObject('your_address_books.json', 'friends/your_address_books.json', FileCategory.Text, size, messagesStack));
          break;
        }
        case 'friends.json': {
          // {"date": Date, "text": "M *****", "category": "Contacts", "subcategory": "F Friend"}
          const messagesStack = [];
          let size = 0;
          for (let i = 0; i < peopleCount; ++i) {
            const str = peopleStack[i].name;
            const message = toDataPoint(new Date(startDate + getDate(diffTime)), str, Category.Contacts, t.Friend);
            size += (19 + 8 + 8 + str.length) * 2;
            messagesStack.push(message);
          }
          messagesStack.sort((a, b) => a.date.getTime() - b.date.getTime());
          objectStack.push(getFileObject('friends.json', 'friends/friends.json', FileCategory.Text, size, messagesStack));
          break;
        }
        //case 'received_friend_requests.json':
        case 'sent_friend_requests.json':
          // {"date": Date,"text": "Name","category": "Contacts","subcategory": "F Sent Friend Requests"}
          break;
        case 'pages.json': {
          // {"date": Date, "text": "Köln", "category": "Likes", "subcategory": "F Page Likes"}
          const times = randn_bm(33, 333, 3);
          const messagesStack = [];
          let size = 0;
          for (let i = 0; i < times; ++i) {
            const str = random() > 0.5 ? getString(1) : getName();
            const message = toDataPoint(new Date(startDate + getDate(diffTime)), t.liked + str, Category.Likes, t.PageLikes);
            size += (19 + 8 + 8 + str.length) * 2;
            messagesStack.push(message);
          }
          messagesStack.sort((a, b) => a.date.getTime() - b.date.getTime());
          objectStack.push(getFileObject('pages.json', 'likes_and_reactions/pages.json', FileCategory.Text, size, messagesStack));
          break;
        }
        case 'posts_and_comments.json': {
          // {"date": Date,"text": "Max Munstermann gefällt Patrick ******* Beitrag in Köln Kleinanzeigen.","category": "Likes","subcategory": "F Post and Comment Likes"}
          const times = randn_bm(333, 3333, 3);
          const messagesStack = [];
          let size = 0;
          for (let i = 0; i < times; ++i) {
            const str = peopleStack[Math.floor(peopleStack.length * random())].name;
            const message = toDataPoint(new Date(startDate + getDate(diffTime)), t.post + str, Category.Likes, t.Postings);
            size += (19 + 8 + 8 + str.length) * 2;
            messagesStack.push(message);
          }
          messagesStack.sort((a, b) => a.date.getTime() - b.date.getTime());
          objectStack.push(getFileObject('posts_and_comments.json', 'likes_and_reactions/posts_and_comments.json', FileCategory.Text, size, messagesStack));
          break;
        }
        case 'your_posts_1.json': {
          // {"date": Date, "text": "Max Mustermann hat eine Erinnerung geteilt.", "category": "Posts", "subcategory": "F Your Posts"}
          const times = randn_bm(33, 333, 3);
          const messagesStack = [];
          let size = 0;
          for (let i = 0; i < times; ++i) {
            const str = t.posted + getString(randn_bm(20, 200, 1));
            const message = toDataPoint(new Date(startDate + getDate(diffTime)), str, Category.PostsAndComments, t.YourPosts);
            size += (19 + 8 + 8 + str.length) * 2;
            messagesStack.push(message);
          }
          messagesStack.sort((a, b) => a.date.getTime() - b.date.getTime());
          objectStack.push(getFileObject('your_posts_1.json', 'posts/your_posts_1.json', FileCategory.Text, size, messagesStack));
          break;
        }
        case 'profile_information.json': {
          const messagesStack = [];
          let size = 0;
          const str = getProfileInformation(me, peopleStack);
          const message = toDataPoint(new Date(startDate), str, Category.Account, t.Profile);
          size += (19 + 8 + 8 + str.length) * 2;
          messagesStack.push(message);
          messagesStack.sort((a, b) => a.date.getTime() - b.date.getTime());
          objectStack.push(getFileObject('profile_information.json', 'profile_information/profile_information.json', FileCategory.Text, size, messagesStack));
          break;
        }
        case 'profile_update_history.json': {
          // {"date": Date,"text": "Max Munstermann hat „Aktueller Wohnort“ zu Köln, Deutschland geändert.","category": "Account","subcategory": "F Profile Updates"}
          const times = Math.floor(random() * 10 + 3);
          const messagesStack = [];
          let size = 0;
          for (let i = 0; i < times; ++i) {
            const str = me.name + t.placeChange + random_array(cities);
            const message = toDataPoint(new Date(startDate + getDate(diffTime)), str, Category.Account, t.ProfileUpdate);
            size += (19 + 8 + 8 + str.length) * 2;
            messagesStack.push(message);
          }
          messagesStack.sort((a, b) => a.date.getTime() - b.date.getTime());
          objectStack.push(getFileObject('profile_update_history.json', 'search_history/profile_update_history.json', FileCategory.Text, size, messagesStack));
          break;
        }
        case 'your_search_history.json': {
          // {"date": Date,"text": "Du hast nach „köln“ gesucht","category": "Activity","subcategory": "F köln"}
          const times = randn_bm(33, 444, 3);
          const messagesStack = [];
          let size = 0;
          for (let i = 0; i < times; ++i) {
            const str = random() > 0.5 ? getString(random() > 0.5 ? 1 : 2) : getName();
            const message = toDataPoint(new Date(startDate + getDate(diffTime)), t.searched + ' „' + str + '“', Category.Activity, 'F Search');
            size += (19 + 8 + 8 + str.length) * 2;
            messagesStack.push(message);
          }
          messagesStack.sort((a, b) => a.date.getTime() - b.date.getTime());
          objectStack.push(getFileObject('your_search_history.json', 'search_history/your_search_history.json', FileCategory.Text, size, messagesStack));
          break;
        }
        case 'account_activity.json':
          // {"date": Date,"text": "1.2.3.4","category": "Security","subcategory": "F Security: Email Added"},
          //let types = ["Session updated", "Login", "Email added", "Checkpoint"];
          break;
        case 'administrative_records.json':
          // {"date": Date, "text": "Email change", "category": "Security", "subcategory": "F Security: Admin"}
          break;
        case 'logins_and_logouts.json': {
          // {"date": Date, "text": "Anmelden www.facebook.com", "category": "Security", "subcategory": "F Security: Login and Logout"}
          const times = randn_bm(50, 1000, 3);
          const messagesStack = [];
          let size = 0;
          for (let i = 0; i < times; ++i) {
            const str = random() > 0.5 ? t.signin : t.signout;
            const message = toDataPoint(new Date(startDate + getDate(diffTime)), str, Category.Security, t.Security);
            size += (19 + 8 + 8 + str.length) * 2;
            messagesStack.push(message);
          }
          messagesStack.sort((a, b) => a.date.getTime() - b.date.getTime());
          objectStack.push(getFileObject('logins_and_logouts.json', 'security_and_login_information/logins_and_logouts.json', FileCategory.Text, size, messagesStack));
          break;
        }
        case 'login_protection_data.json': {
          // {"date": Date, "text": "Cookie: ************************", "category": "Security", "subcategory": "F Security: Login Protection"}
          const times = randn_bm(20, 500, 2.5);
          const messagesStack = [];
          let size = 0;
          for (let i = 0; i < times; ++i) {
            const str = random() > 0.5 ? 'Cookie: 31**********************' : 'Cookie: 89**********************';
            const message = toDataPoint(new Date(startDate + diffTime / 4 * 3 + (random() * diffTime / 4)), str, Category.Security, t.Login);
            size += (19 + 8 + 8 + str.length) * 2;
            messagesStack.push(message);
          }
          messagesStack.sort((a, b) => a.date.getTime() - b.date.getTime());
          objectStack.push(getFileObject('login_protection_data.json', 'security_and_login_information/login_protection_data.json', FileCategory.Text, size, messagesStack));
          break;
        }
          break;
        case 'where_you\'re_logged_in':
          // {"date": Date, "text": "Chrome on Windows PC in Frankfurt, Germany", "category": "Security", "subcategory": "F Security: Active Logins"}
          break;
        default:
          break;
      }
    }
    // messages
    // {"date": Date,"text": "Max Munstermann: Sehr kreativer Name","category": "Messages","subcategory": "F Chat with Max Munstermann and Max Munstermann"}
    for (let j = 0; j < peopleStack.length; ++j) {
      objectStack.push(generateMessageFile(j, startDate, diffTime, me.name, peopleStack[j]));
    }
    const images = randn_bm(20, 200, 1);
    for (let j = 0; j < images; ++j) {
      objectStack.push(generateImageFile());
    }
    const videos = randn_bm(3, 20, 1);
    for (let j = 0; j < videos; ++j) {
      objectStack.push(generateVideoFile());
    }
    return objectStack;
  }
}