import { catColor } from 'constants/color';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { ApplicationState } from '@store/index';
import { Button, getTableFooterUtilityClass, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { track } from '@store/tracking/actions';

interface ToolTipSettings {
  tooltipWidth: number;
  margin: number;
}

interface Props {
  settings: ToolTipSettings;
  position?: { x: number; y: number };
  texts: [ReactNode, ReactNode, ReactNode, ReactNode];
  category: string;
  showFileIcon?: boolean
  uri?: string
  isFixed?: boolean
  clickToNext?: () => void,
  clickToPrev?: () => void,
}

const Tooltip: FC<Props> = ({ settings, position, texts, category, uri, showFileIcon, isFixed, clickToNext, clickToPrev }) => {
  const [TitleText, SubtitleText, BodyText, Body2Text] = texts;
  const [height, setHeight] = useState(0);
  const [isFixated, setIsFixated] = useState({
    x: position?.x,
    y: position?.y
  });
  const posX = (isFixed ? isFixated.x : position?.x) ?? 0;
  const posY = (isFixed ? isFixated.y : position?.y) ?? 0;
  const elementRef = useRef<HTMLElement>();
  const dispatch = useDispatch();

  const providerItems = useSelector((state: ApplicationState) => state.provider.items);

  useEffect(() => {
    dispatch(track('hover', 1));
  }, [texts[1], texts[2]]);

  useEffect(() => {
    // When fixed changes to positive, save the current position
    console.log('Updating is pinned position to ', isFixed, posX, posY);
    if (position && isFixed === true)
      setIsFixated(position);
  }, [isFixed]);

  useEffect(() => {
    // When element renders, update the height dynamicly to adapt to content
    if (elementRef.current)
      setHeight(elementRef.current.clientHeight);
  }, [elementRef, texts, settings]);


  function findDataUri(uri: string) {
    // search somewhere in "files.data.binaryData"
    let binaryData: string | undefined;
    for (const providerDatums of providerItems) {
      for (const providerFile of providerDatums.children) {
        if (providerFile.path === uri && providerFile.binaryData) {
          binaryData = providerFile.binaryData;
          break;
        }
      }
    }

    if (binaryData === undefined) {
      return null;
    }

    return <a href={binaryData} target="_blank" rel="noopener noreferrer">
      <img
        src={binaryData}
        style={{ maxWidth: '100%', maxHeight: '20vh', margin: 'auto' }}
      />
    </a>;
  }

  function getLeft() {
    return document.documentElement.clientWidth -
      (settings.tooltipWidth + settings.margin) < posX ?
      posX - settings.tooltipWidth - settings.margin :
      posX;
  }

  function getTop() {
    return Math.min(document.documentElement.clientHeight - height - settings.margin - 30, posY);
  }

  return (
    <Box
      ref={elementRef}
      className="tooltip"
      sx={{
        background: '#1F1B1B',
        borderRadius: '15px 15px 5px 5px',
        overflow: 'hidden',
        boxShadow: '0 0 3px rgba(0,0,0,0.1)',
        color: '#e6e6e6', //"rgb(175, 180, 185)",
        border: isFixed ? 'solid 1px #fff' : '',
        fontSize: '1em',
        overflowWrap: 'break-word',
        whiteSpace: 'break-spaces',
        position: 'fixed',
        left: getLeft(),
        top: getTop(),
        maxWidth: `${settings.tooltipWidth}px`,
        minWidth: `${settings.tooltipWidth}px`,
        padding: 0,
        pointerEvents: isFixed ? 'auto' : 'none',
        zIndex: 1300,
        visibility: isFixed || position ? 'visible' : 'hidden',
      }}
    >

      <div
        style={{
          background: '#49454F',
          margin: 0,
          padding: '7px 5px',
          display: 'flex',
          alignItems: 'center',
        }}
      >

        {
          showFileIcon ?
            <div
              style={{
                height: '2em',
                margin: '0 7px',
              }}>
              <svg width="30" height="40" viewBox="0 0 40 50" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 5C0 2.23858 2.23858 0 5 0H26L40 18V45C40 47.7614 37.7614 50 35 50H5C2.23858 50 0 47.7614 0 45V5Z" fill={catColor(category)} />
                <path d="M26 0L40 18H26V0Z" fill="#00000044" />
              </svg>
            </div>
            :
            <div
              style={{
                height: '2em',
                aspectRatio: '1',
                background: catColor(category),
                borderRadius: '50%',
                margin: '0 7px',
              }}
            ></div>
        }

        <div>
          <Typography sx={{ fontSize: '1.2rem', fontWeight: 600 }} >
            {TitleText}
          </Typography>
          <Typography>
            {SubtitleText}
          </Typography>
        </div>
      </div>
      <Box sx={{ p: 1 }}>
        <Box sx={{ mb: 1.5 }} >
          {BodyText}
        </Box>
        {uri && findDataUri(uri)}
        <Typography variant="body2" color="text.secondary">
          {Body2Text}
        </Typography>
      </Box>
      <Stack justifyContent="flex-end" direction="row" spacing={1}>
        {
          clickToPrev &&
          <Button onClick={clickToPrev}>
            View Next Item
          </Button>
        }
        {
          clickToPrev &&
          <Button onClick={clickToNext}>
            View Next Item
          </Button>
        }
      </Stack>
    </Box>
  );
};

export default Tooltip;
