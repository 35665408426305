import { Store, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';

// Import the state interface and combined reducers/sagas.
import { ApplicationState, rootReducer } from './store';
import { OptionsState, isOptionsState } from './store/options/types';
import { initialState } from './store/options/reducer';

const loadState = () => {
  return undefined;
  /*
  // Load the saved state from localStorage
  try {
    const serializedState = localStorage.getItem('options');
    if (serializedState === null) {
      return undefined;
    } else {
      let parsed = JSON.parse(serializedState) as OptionsState;
      if (isOptionsState(parsed) && parsed.version === initialState.version) {
        return { options: parsed };
      } else {
        return undefined;
      }
    }
  } catch (e) {
    return undefined;
  }
  */
};

const saveState = (state: ApplicationState) => {
  try {
    const serializedState = JSON.stringify(state.options);
    localStorage.setItem('options', serializedState);
  } catch (e) {
    console.log('Error: could not save state to localStorage', e);
  }
};

export default function configureStore(): Store<ApplicationState> {
  const preloadedState: any = loadState();
  const store = createStore(
    rootReducer(),
    preloadedState,
    composeWithDevTools(
      applyMiddleware(thunk)
    )
  );

  /*
  // state persistance
  store.subscribe(() => {
    saveState(store.getState())
  });
  */

  /*
  // Hot reloading
  if ((module as any).hot) {
    // Enable Webpack hot module replacement for reducers
    (module as any).hot.accept('./store', () => {
      store.replaceReducer(rootReducer(history));
    });
  }
  */

  return store;
}
