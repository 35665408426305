import { Category, FileCategory } from 'providers/types';
import { FileCategories } from './filenames';

export const COLOURS = {
  [Category.Account]: '#5dacd9', // 0 => 93   172   217  = Account
  [Category.Activity]: '#6ed355', // 1 => 110   211   85  = Activity
  [Category.Contacts]: '#9e8ada', // 2 => 158   138   218 = Contacts
  //  [Category.Location]: "#ce9549", // 3 => 206   149   73  = Location
  //   [Category.Media]: "#e55f45", // 4 => 229   95   69   = Mediax
  [Category.Messages]: '#d88299', // 5 => 216   130   153 = Messages
  // [Category.Other]: "#aab67a", // 6 => 170   182   122 = Other 
  [Category.Likes]: '#67cea7', // 9 => 103   206   167 = Likes
  [Category.PostsAndComments]: '#d758d4', // 7 => 215   88   212  = Posts
  [Category.Security]: '#d1d648', // 8 => 209   214   72  = Security
  [Category.Stories]: '#e55f45', // 8 => 209   214   72  = Security
};

export const COLORS = [
  '#5dacd9',
  '#6ed355',
  '#9e8ada',
  //"#ce9549",
  // "#e55f45",
  '#d88299',
  '#aab67a',
  '#d758d4',
  '#d1d648',
  '#67cea7',
];

export const SELECT_COLOR = {
  primary: '#f5f5f5',
  secondary: '#656565',
};
export function catColor(s: Category | string) {
  if (COLOURS[s as Category])
    return COLOURS[s as Category];
  return '#e3e3e3';
}
export function fileColor(s: FileCategory | string) {
  for (const cat of FileCategories)
    if (cat.type === s)
      return cat.color;
  return '#e3e3e3';
}

export const COLORS_AND_CAT = [
  ['Account', COLORS[0]],
  ['Activity', COLORS[1]],
  ['Contacts', COLORS[2]],
  ['Location', COLORS[3]],
  ['Media', COLORS[4]],
  ['Messages', COLORS[5]],
  ['Other', COLORS[6]],
  ['Likes', COLORS[9]],
  ['Posts', COLORS[7]],
  ['Security', COLORS[8]]
];

export const FILE_COLOR = ['Files', '#f0f0ff'];

export const COLOR_AND_FILES = [
  ['Video', '#e6f5c9'],
  ['Picture', '#fdcdac'],
];

export const FILECOLORS = {
  'Text': COLORS[0],
  'Media': COLORS[4],
  'Other': COLORS[6],
};

export const FraunhoferGreen = '#518861';