import { Reducer, AnyAction } from 'redux';
import { ProviderState, ProviderActionTypes, ProviderDatum } from './types';
import { getProviders } from 'providers/index';
import { ProviderFile } from 'providers/types';


// Initial state
const initialState: ProviderState = {
  providers: getProviders().map(([def, _]) => def),
  items: [],
  counter: 0,
};

function updateObjectInArray(array: ProviderDatum[], id: number) {
  return array.map((item) => {
    if (item.id !== id) {
      return item;
    }

    return {
      ...item,
      ...{ active: !item.active }
    };
  });
}

// TODO: does not work for 2 data exports from the same service
// the files should have their own index number to filter them
function setSensitivityinElements(array: ProviderDatum[], itemId: number, fileId: number, index: number, value: number) {
  return array.map((item) => {
    // find ProviderDatum
    if (item.id !== itemId) {
      return item;
    }
    return {
      ...item,
      ...{
        children: item.children.map((e) => {
          // Find File in ProviderDatum
          if (e.id !== fileId)
            return e;
          return {
            ...e, ...{
              data: e.data.map((d, i2) => {
                // Find DataPoint in File in ProviderDatum
                if (i2 !== index)
                  return d;
                return { ...d, ...{ sensitivity: value } };
              })
            }
          };
        })
      }
    };
  });
}

function freeObjectURL(obj: ProviderDatum | undefined) {
  // release all existing object URL which were created in the worker
  //the memory is not necessarily freed immediately
  obj?.children.forEach(d => d.binaryData && URL.revokeObjectURL(d.binaryData));
}

// The reducer function
const reducer: Reducer<ProviderState> = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ProviderActionTypes.ADD_DATA: {
      const { filename, providerId, data } = action.payload;

      const newData: ProviderDatum = {
        id: state.counter,
        filename: filename,
        providerId: providerId as string,
        active: true,
        children: data.map((d: ProviderFile) => { return { ...d, ...{ itemId: state.counter } }; }) as ProviderFile[]
      };

      return Object.assign({}, state, {
        items: state.items.concat(newData),
        counter: state.counter + 1
      });
    }
    case ProviderActionTypes.REMOVE_DATA: {
      const { itemId } = action.payload;
      const removed = state.items.find(d => d.id === itemId);
      freeObjectURL(removed);
      return Object.assign({}, state, {
        items: state.items.filter(d => d.id != itemId)
      });
    }
    case ProviderActionTypes.SWITCH_ITEM: {
      const { itemId } = action.payload;
      return Object.assign({}, state, {
        items: updateObjectInArray(state.items, itemId)
      });
    }
    case ProviderActionTypes.SET_PRIVACY_SENSITIVITY: {
      const { itemId, fileId, index, value } = action.payload;
      return Object.assign({}, state, {
        items: setSensitivityinElements(state.items, itemId, fileId, index, value)
      });
    }
    default: {
      return state;
    }
  }
};

export { reducer as providerReducer };