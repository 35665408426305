import { FileCategory } from 'constants/filenames';
export { FileCategory };

// Provider attributes definition
export type ProviderDefinition = {
  name: string,
  id: string,
  url: string,
  desc: string,
  colors: string[],
  groupFilesBy: string,
  strings: {
    en: {
      name: string,
      help: string[]
    },
    de: {
      name: string,
      help: string[]
    }
  },
  hidden?: boolean
}

export type ProviderParser = {
  fileStructure: string[],
  optionalFileStructure: string[];
  processFile: ProcessFile
}

export type ProcessFile = (
  path: string,
  data: Uint8Array,
  callback: (str: string) => void,
  getFile: (path: string) => undefined | UnparsedFile
) => (DataPoint[]);

export type ProviderFile = {
  id: number; // only unique among this data export
  itemId: number; // file item id
  providerId: string;
  compressedSize: number;
  fileName: string;
  fileType: FileCategory;
  dataCategory: string;
  folder: string;
  hash: string;
  isDir: boolean;
  path: string;
  data: DataPoint[];
  uncompressedSize: number;
  groupBy: string;
  date: Date;
  mimeType: string;
  binaryData: null | string;
}

export type UnparsedFile = {
  compressedSize: number;
  fileName: string;
  folder: string;
  hash: string;
  isDir: boolean;
  path: string;
  data: Uint8Array | null;
  uncompressedSize: number;
  date: Date;
  mimeType: string;
  binaryData: null | string;
}

export function toDataPoint(date: Date, text: string, category: Category, subcategory: string, uri?: string) {
  if (uri !== undefined) {
    return {
      date: date,
      text: text,
      category: category,
      subcategory: subcategory,
      uri: uri
    };
  }
  return {
    date: date,
    text: text,
    category: category,
    subcategory: subcategory
  };
}

// TODO: to decrease memory consumtion this should be an array with 4 elements
// also it should be inside one big array in the store instead of within each file
// also subcategory might be just strings? with an dynamic increasing index pointing to strings for each category?
// because strings are actually quite big memory-wise
// and we have A LOT of data points
// unsure if Date is better then number but guess thats finde for now
export interface DataPoint {
  readonly date: Date;
  readonly text: string;
  readonly subcategory: string;
  readonly category: Category;
  readonly uri?: string,
  sensitivity?: number,
  // TODO: should have some kind of
  // readonly active: boolean;
  // readonly public: boolean;
}

export enum WorkerStatus {
  Start = 'Start',
  Failure = 'Failure',
  Log = 'Log',
  Progress = 'Progress',
  Success = 'Success',
  Warn = 'Warn',
  Provider = 'Provider'
}

export enum Category {
  Account = 'Account',
  Activity = 'Activity',
  Contacts = 'Contacts',
  //  Location = "Location",
  //  Media = "Media",
  Messages = 'Messages',
  Likes = 'Likes',
  //Other = "Other",
  PostsAndComments = 'Posts',
  Stories = 'Stories',
  Security = 'Security'
}


export interface DataProviderOptions {
  readonly files: string[];
  readonly folders: string[];
}
