import React, { FC, useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, keyframes, Theme, useTheme } from '@mui/material';
import { dropFile } from '@store/files/actions';
import useLanguageHook from 'containers/App/useLanguageHook';

const pulsating = keyframes`
  0% {
    opacity: 0.9,
  }
  50% {
    opacity: 1,
  }
  100% {
    opacity: 0.9,
  }
`;

const DragAndDrop: FC<{ children: React.ReactNode }> = (props) => {
  const [drag, setDrag] = useState(false);
  const [_counter, setCounter] = useState(0);
  const dropRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useLanguageHook();
  const kinds = ['application/zip', 'application/octet-stream', 'application/x-zip-compressed'];

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer &&
      e.dataTransfer.items &&
      e.dataTransfer.items.length > 0 &&
      kinds.includes(e.dataTransfer.items[0].type)) {
      setDrag(true);
      // Fix: cant access React State scope inside a eventListener,
      // but calling setState with a func lets us access the scope.
      setCounter(x => x + 1);
    }
  };

  const handleDragOut = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setCounter(x => {
      if (x - 1 === 0) {
        setDrag(false);
      }
      return x - 1;
    });
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    setCounter(0);
    if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      dispatch(dropFile(Array.from(e.dataTransfer.files)));
      //e.dataTransfer.clearData();
    }
  };

  useEffect(() => {
    // Initial attachment of listeners
    if (dropRef.current) {
      dropRef.current.addEventListener('dragenter', handleDragIn);
      dropRef.current.addEventListener('dragleave', handleDragOut);
      dropRef.current.addEventListener('dragover', handleDrag);
      dropRef.current.addEventListener('drop', handleDrop);
    }

    // Upon unmount remove listeners
    return () => {
      if (dropRef.current) {
        dropRef.current.removeEventListener('dragenter', handleDragIn);
        dropRef.current.removeEventListener('dragleave', handleDragOut);
        dropRef.current.removeEventListener('dragover', handleDrag);
        dropRef.current.removeEventListener('drop', handleDrop);
      }
    };
  }, []);

  return (
    <Box sx={{
      display: 'inline-block',
      position: 'relative',
      width: '100%',
      height: '100vh'
    }} ref={dropRef}>
      {drag &&
        <Box>
          <Box sx={{
            backgroundColor: theme.palette.primary.main,
            opacity: 0,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9998,
            animation: `${pulsating} 2500ms linear 0ms infinite`
          }} />
          <Box sx={{
            position: 'fixed',
            top: 80,
            bottom: 80,
            left: 80,
            right: 80,
            zIndex: 9999,
            borderColor: theme.palette.primary.contrastText,
            borderStyle: 'dashed',
            borderWidth: '3px',
            borderRadius: '2px'
          }} />
          <Box sx={{
            position: 'fixed',
            top: '50%',
            right: 0,
            left: 0,
            textAlign: 'center',
            zIndex: 9999,
            textShadow: '1px 1px 2px rgba(0, 0, 0, 1)',
            color: theme.palette.primary.contrastText,
            fontSize: 40
          }}>
            {t.Dropzone.Drop}
          </Box>
        </Box>
      }
      {props.children}
    </Box>
  );
};

export default DragAndDrop;