import React, { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { Folder as FolderIcon } from '@mui/icons-material';
import * as d3 from 'd3';
import { HierarchyNode, HierarchyRectangularNode } from 'd3';
import { COLORS, catColor, fileColor } from 'constants/color';
import { FilePiece } from '.';
import TooltipWrapper from './tooltipWrapper';

export interface FileSettings {
  width: number;
  height: number;
  margin: number;

  size: string;
  color: string;
  filesize: boolean;
  leftSidebarOpen: boolean;

  groupingSize: boolean;
  private: boolean;
  tooltipWidth: number;
  showImages: boolean;
  showFolderHierarchy: boolean;
}

interface ComponentProps {
  hierarchyData: FilePiece[];
  settings: FileSettings;
  fileList: FilePiece[];
}

export const getFileSize = (num: number): string => {
  if (num < 1024) return Math.floor(num) + ' Bytes';
  num = num / 1024;
  if (num < 1024) return Math.floor(num) + ' KB';
  num = num / 1024;
  if (num < 1024) return Math.floor(num) + ' MB';
  num = num / 1024;
  return Math.floor(num) + ' GB';
};

const Treemap: FC<ComponentProps> = (props: ComponentProps) => {
  const mag = {
    numberOfChildren: 500,
    pixelAreaSize: 12,
  };

  const svgRef = useCallback((node: SVGSVGElement) => {
    setSvg(d3.select(node));
  }, []);
  const nodesGroupRef = useCallback((node: SVGGElement) => {
    setNodesGroup(d3.select(node));
  }, []);

  const [svg, setSvg] =
    useState<d3.Selection<SVGSVGElement, unknown, null, undefined>>();
  const [nodesGroup, setNodesGroup] =
    useState<d3.Selection<SVGGElement, unknown, null, undefined>>();
  let counter = 0;
  const categories: { [label: string]: string } = {};

  const [tooltip, setTooltip] = useState<{
    position?: { x: number; y: number };
    files?: HierarchyRectangularNode<FilePiece>;
    isFixed?: boolean;
  }>({ isFixed: false });

  const showSize = (d: HierarchyNode<FilePiece>) => {
    const trav = (e: HierarchyNode<FilePiece>): number =>
      (e.children ?? []).reduce(
        (sum, f) => (f.data.isDir ? sum + trav(f) : sum + 1),
        0
      );

    if (d.data.isDir) return (d.children?.length ?? 0) + ' Elements';
    if (!props.settings.filesize) return null;
    if (props.settings.size === 'length')
      return d.data.datalength + ' Data Points';
    if (props.settings.size === 'images') {
      return getFileSize(d.data.uncompressedSize);
    }
    return getFileSize(d.value ?? d.data.val);
  };

  const renderTitle = (
    item: FilePiece,
    size: number,
    yOffset: number,
    height: number
  ): string => {
    if (!item) return '';
    if (item.sum && item.files.length > 1)
      return renderText(item.files.length + ' Files', size, yOffset, height);
    return renderText(item.fileName, size, yOffset, height);
  };

  /**
   * Returns given string when the parent nodes height is sufficient
   * to print the text.
   * @param str Text to print
   * @param size Supposed font size
   * @param yOffset Y-Offset above the text
   * @param height Height of the parents node
   * @returns Text if the nodes height is sufficient, else empty
   */
  const renderText = (
    str: string,
    size: number,
    yOffset: number,
    height: number
    // Text y specifies the position of the baseline, thus a 6th is sufficient
  ): string => (yOffset + axis.y(size) / 6 > height ? '' : str);

  const getColor = (d: FilePiece) => {
    switch (props.settings.color) {
      case 'category':
        return catColor(d.datalength > 0 ? d.data[0].category : '');
      case 'provider':
        return getCategoryColor(d.providerId);
      case 'fileType':
        return d.datalength > 0 ? catColor(d.data[0].category) : fileColor(d.fileType);
      case 'ext':
        return getCategoryColor(d.category);
      case 'nodes':
        return getCategoryColor(d.folder);
      default:
        return 'hsl(' + Math.random() * 360 + ',20%,50%)';
    }
  };

  const getCategoryColor = (d: string) => {
    if (!categories[d]) {
      categories[d] = COLORS[counter++ % COLORS.length];
    }
    return categories[d];
  };

  /**
   * Returns nodes supposed brightness for hierarchical treemap.
   * Used in order to visualize hierarchical differences of elements with
   * the same color in the treemap.
   * @param d Node the return the brightness for
   * @returns CSS brightness value based on element's height
   */
  const applyBrightness = (d: HierarchyRectangularNode<FilePiece>) => {
    let base = 0;
    const isHovered = tooltip.files && tooltip.files.data.path.includes(d.data.path);
    if (isHovered) {
      base = .2;
    }
    const val = base + 1.1 + 0.8 / (d.depth + d.height + 2) - 1 / (d.height + 2);
    return val;
  };

  /**
   * Predict if children of elements have to be rendered.
   * @param e Element
   * @returns boolean
   */
  const checkIfVisible = (e: HierarchyRectangularNode<FilePiece>) =>
    // if there is a parent
    !e.parent ||
    //if x and y size is above pixelAreaSize
    (calcHypotenuse(e.parent) > mag.pixelAreaSize &&
      // if the number of children is below numberOfChildren
      (e.parent?.children?.length ?? 0) <= mag.numberOfChildren) ||
    // or if every child is bigger then pixelAreaSize
    e.parent?.children?.every(
      (d) => calcHypotenuse(d) > mag.pixelAreaSize
    );

  function calcHypotenuse(d: HierarchyRectangularNode<FilePiece>) {
    return Math.sqrt(
      Math.pow(axis.x(d.x1) - axis.x(d.x0), 2) *
      Math.pow(axis.y(d.y1) - axis.y(d.y0), 2)
    );
  }

  /**
   * Calculate path of parent element.
   * @param p Path of element to get the parent from
   * @returns Path of parent element. May be false if p is root.
   */
  const getParentString = (p: string) =>
    p.indexOf('/') > -1 && p.substring(0, p.lastIndexOf('/'));

  /**
   * Zoom into element in hierarchical treemap.
   * @param d  New root node
   */
  const zoomIn = (d: HierarchyRectangularNode<FilePiece>) => {
    setRootPath(d.data.path);
  };

  /**
   * Zoom out of current root element in hierarchical treemap.
   * @param d Current root node
   */
  const zoomOut = (d: HierarchyRectangularNode<FilePiece>) => {
    const newRoot = getParentString(d.data.path);

    if (newRoot) {
      setRootPath(newRoot);
    }
  };

  const createFlatTree = (d: FilePiece[]) => {
    const data = d3.group(d, (d) => d.folder);
    const data2 = Array.from(data, ([name, children]) => ({ name, children }));
    const h = d3
      .hierarchy({
        name: 'treemap',
        val: 0,
        data: [],
        children: data2,
      })
      .sum((d) => d.val);
    d3
      .treemap()
      .size([props.settings.width, props.settings.height])
      .tile(d3.treemapSquarify)(h as any);
    return h.leaves();
  };

  const createTree = (arr: FilePiece[]) => {
    const s = d3
      .stratify<FilePiece>()
      .id((d) => d.relPath)
      .parentId((d) => d.relPath.substring(0, d.relPath.lastIndexOf('/')));

    const data = s(arr);

    return data
      .sum((d) => d.val)
      .sort((a, b) => b.height - a.height || (b.value ?? 0) - (a.value ?? 0));
  };

  const zoomed = (transform: d3.ZoomTransform) => {
    // based on the current zoom we switch between transforming the
    // whole SVG or transforming every element (and hiding those not in view)
    // this makes the zoomed out and zoomed in more efficient and fluid (hopefully)
    if (transform.k > 2) {
      const x = d3
        .scaleLinear()
        .domain([0, props.settings.width])
        .range([
          props.settings.margin,
          props.settings.width - props.settings.margin,
        ]);
      const y = d3
        .scaleLinear()
        .domain([0, props.settings.height])
        .range([
          props.settings.margin,
          props.settings.height - props.settings.margin,
        ]);
      setAxis({ x: transform.rescaleX(x), y: transform.rescaleY(y) });
    } else {
      if (nodesGroup?.node.length !== props.fileList.length) {
        // rerender all nodes if zooming out
        const x = d3
          .scaleLinear()
          .domain([0, props.settings.width])
          .range([
            props.settings.margin,
            props.settings.width - props.settings.margin,
          ]);
        const y = d3
          .scaleLinear()
          .domain([0, props.settings.height])
          .range([
            props.settings.margin,
            props.settings.height - props.settings.margin,
          ]);
        setAxis({ x, y });
      }
      nodesGroup?.attr('transform', transform + '');
    }
  };

  const getFolderColor = (f: HierarchyRectangularNode<FilePiece>) => {
    const traverseTree = (f: HierarchyRectangularNode<FilePiece>): string | undefined => {
      return f.children?.reduce((found, c) => {
        let val;
        if (c.data.isDir) {
          val = traverseTree(c);
        } else {
          val = getColor(c.data);
        }

        if (found === undefined || found == val)
          return val;
        else
          return undefined;
      }, undefined as string | undefined);
    };

    return (traverseTree(f) ??
      d3.scaleSequential([0, f.height + f.depth],
        d3.interpolate('#fffca6', '#75a5ff'))(f.height));
  };

  const [axis, setAxis] = useState<{
    x: d3.ScaleLinear<number, number>;
    y: d3.ScaleLinear<number, number>;
  }>({
    x: d3
      .scaleLinear()
      .domain([0, props.settings.width])
      .range([
        props.settings.margin,
        props.settings.width - props.settings.margin,
      ]),
    y: d3
      .scaleLinear()
      .domain([0, props.settings.height])
      .range([
        props.settings.margin,
        props.settings.height - props.settings.margin,
      ]),
  });

  const [rootPath, setRootPath] = useState<string>('');

  const createTreemap = useCallback(
    (data: d3.HierarchyNode<FilePiece>) => {
      return (
        d3
          .treemap<FilePiece>()
          .size([props.settings.width, props.settings.height])
          //.round(true)
          .padding(3)
          .paddingTop((d) =>
            d.data.path != 'treemap' && axis.y(d.y1) - axis.y(d.y0) > 30
              ? 30
              : 0
          )
          .tile(d3.treemapResquarify)(Object.assign(data))
      );
    },
    [props.settings.width, props.settings.height, props.settings.margin]
  );

  useEffect(() => {
    setAxis({
      x: d3
        .scaleLinear()
        .domain([0, props.settings.width])
        .range([
          props.settings.margin,
          props.settings.width - props.settings.margin,
        ]),
      y: d3
        .scaleLinear()
        .domain([0, props.settings.height])
        .range([
          props.settings.margin,
          props.settings.height - props.settings.margin,
        ]),
    });
  }, [props.settings.margin, props.settings.width, props.settings.height]);

  useEffect(() => {
    const zoom = props.settings.showFolderHierarchy
      ? d3.zoom<SVGSVGElement, unknown>().scaleExtent([1, 1])
      : d3
        .zoom<SVGSVGElement, unknown>()
        .scaleExtent([1, 64]) // min max allowed scale factors
        //.extent([[0, 0], [this.settings.width - 50, this.settings.height - 60]])
        .extent([
          [0, 0],
          [props.settings.width, props.settings.height],
        ])
        .on('zoom', ({ transform }) => zoomed(transform));

    svg?.call(zoom, d3.zoomIdentity);
  }, [
    svg,
    props.settings.showFolderHierarchy,
    props.settings.width,
    props.settings.height,
  ]);

  useEffect(() => {
    zoomed(d3.zoomIdentity);
  }, [props.settings.showFolderHierarchy]);

  /* Render Treemap */
  /**
   * Render a single node in a treemap.
   * @param f Node to render
   * @param i React key value for the block
   * @returns JSX of a single svg block of a treemap
   */
  const renderBlock = (f: HierarchyRectangularNode<FilePiece>, i: number) => {
    const offset = 0;

    const width = Math.max(axis.x(f.x1) - axis.x(f.x0), 0),
      height = Math.max(axis.y(f.y1) - axis.y(f.y0), 0);
    const clip = {
      clipPath: `path('M 0 ${height - 6} H ${width - 6} V 0 H 0 z')`,
    };
    const clipIcon = {
      clipPath: `path('M 0 ${height - 3} H ${width - 6} V 0 H 0 z')`,
    };
    const clipTitle = {
      clipPath: `path('M 0 1000 H ${width - (6 + (f.data.isDir && getParentString(f.data.relPath) ? 24 : 0))
        } V 0 H 0 z')`,
    };

    return (
      <g
        key={i}
        style={clip}
        transform={`translate(${axis.x(f.x0)}, ${axis.y(f.y0 + offset)})`}
        onClick={() => {
          if (f.data.isDir) {
            f.depth == 0
              ? getParentString(f.data.path) && zoomOut(f)
              : f.children && zoomIn(f);
          } else {
            setTooltip({
              files: tooltip.files,
              position: tooltip.position,
              isFixed: !tooltip.isFixed
            });
          }
        }}
        onMouseMove={({ clientX, clientY }) => {
          !f.data.isDir && !tooltip.isFixed &&
            setTooltip({
              files: f,
              position: { x: clientX, y: clientY },
              isFixed: tooltip.isFixed
            });
        }
        }
        onMouseLeave={() => !tooltip.isFixed && setTooltip({ isFixed: tooltip.isFixed })}
      >
        {props.settings.showImages && f.data.binaryData ? (
          <image
            href={f.data.binaryData}
            transform={`translate(0, ${f.parent ? 0 : axis.y(20)})`}
            preserveAspectRatio={f.parent ? 'xMidYMid slice' : 'xMidYMid meet'}
            onClick={() => zoomIn(f)}
            width={width}
            height={height}
            cursor="pointer"
            style={{
              stroke: !props.settings.showFolderHierarchy ? 'black' : undefined,
            }}
          ></image>
        ) : (
          <rect
            width={width}
            height={height}
            cursor={f.children && getParentString(f.data.path) ? 'pointer' : 'default'}
            style={{
              fill: f.data.isDir ? getFolderColor(f) : getColor(f.data),
              stroke: !props.settings.showFolderHierarchy ? 'black' : undefined,
              filter: `brightness(${applyBrightness(f)})`,
              WebkitTransition: '-webkit-filter 400ms linear'
            }}
          ></rect>
        )}

        {f.data.isDir && (axis.y(f.y1) - axis.y(f.y0)) > 24 && (axis.y(f.x1) - axis.y(f.x0)) > 24 && getParentString(f.data.relPath) && (
          <path
            transform={'translate(3,0)'}
            fill="#282323"
            d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z"
          ></path>
        )}
        <text
          x={6 + (f.data.isDir && getParentString(f.data.relPath) ? 24 : 0)}
          y={20}
          cursor={f.depth == 0 && getParentString(f.data.path) ? 'pointer' : 'none'}
          style={clipTitle}
          fontSize={20}
          pointerEvents="none"
          opacity={0.95}
          fill={f.data.binaryData && !f.parent ? '#fff' : '#111'}
        >
          {f.depth == 0 && f.children && getParentString(f.data.path) ? '\u2039 ' : ''}
          {getParentString(f.data.path) ? renderTitle(f.data, 20, 20, height) : 'Files'}
        </text>
        <text
          x={6}
          y={40}
          style={clip}
          fontSize={16}
          fontWeight={100}
          pointerEvents="none"
          fillOpacity={0.8}
          fill="#222"
        >
          {f.data.binaryData != undefined ||
            renderText(
              f.data.isDir
                ? !f.children?.some((d) => checkIfVisible(d))
                  ? showSize(f) ?? ''
                  : ''
                : f.data.category,
              16,
              40,
              height
            )}
        </text>
        {f.data.data.length > 0 && (axis.y(f.y1) - axis.y(f.y0)) > 100 && (axis.y(f.x1) - axis.y(f.x0)) > 100 &&
          f.data.data.slice(0, 100).map((d, i) =>
            <text
              overflow={'scroll'}
              x={6}
              y={56 + 32 + i * 14}
              style={clip}
              fontSize={12}
              fontWeight={100}
              pointerEvents="none"
              fill="#333">{d.text}</text>
          )
        }
        {f.data.isDir || f.data.binaryData || (
          <text
            x={6}
            y={56}
            style={clip}
            fontSize={16}
            fontWeight={100}
            pointerEvents="none"
            fillOpacity={0.8}
            fill="#222"
          >
            {renderText(showSize(f) ?? '', 16, 56, height)}
          </text>
        )}
      </g>
    );
  };

  /**
   * Render a given block of a hierarchical treemap and it's child
   * elements.
   * @param f Root node
   * @param i React key value of the root node
   * @returns JSX of a treemap block and it's children
   */
  const applyBlockRecursive = (f: HierarchyRectangularNode<FilePiece>, i: number): React.ReactNode =>
    [renderBlock(f, i), f.children?.filter(checkIfVisible).map(applyBlockRecursive)];

  /**
   * Render hierarchical treemap. Skip "treemap" root node.
   * @param n Treemap Root Node
   * @returns JSX of hierarchical treemap
   */
  const renderHierarchical = (n: HierarchyRectangularNode<FilePiece>) => [
    n.data.path !== 'treemap' && renderBlock(n, 0),
    n.children
      ?.map((f: any) => f as HierarchyRectangularNode<FilePiece>)
      .map((f: HierarchyRectangularNode<FilePiece>, i: number) => {
        return applyBlockRecursive(f, i);
      }),
  ];

  const data = useMemo(() => {
    try {
      return createTree(
        props.hierarchyData
          .filter((v) => v.path.startsWith(rootPath))
          .map((v) => ({
            ...v,
            relPath: v.path.substring(
              rootPath.lastIndexOf('/') + 1
            ),
          }))
      );
    } catch (e) {
      console.error('TREEMAP', e);
      return createTree(
        props.hierarchyData.map((v) => ({
          ...v,
          relPath: v.path,
        }))
      );
    }
  }, [props.hierarchyData, rootPath]);

  return (
    <>
      <TooltipWrapper
        settings={props.settings}
        position={tooltip?.position}
        files={tooltip?.files}
        isFixed={tooltip?.isFixed}
      />
      <svg
        ref={svgRef}
        width={props.settings.width}
        height={props.settings.height}
      >
        <g transform={'translate(10, 10)'}>
          <g ref={nodesGroupRef}>
            {props.settings.showFolderHierarchy
              ? (props.hierarchyData && renderHierarchical(createTreemap(data)))
              : createFlatTree(props.fileList).map((d, i) => renderBlock(d as any as HierarchyRectangularNode<FilePiece>, i))}
          </g>
        </g>
      </svg>
    </>
  );
};

export default Treemap;
