import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Theme, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';

import Node from './SidebarComponents/Node';

import { DataPoint } from 'providers/types';
import { catColor } from 'constants/color';
import { setSensitivity } from '@store/providers/actions';

// Component-specific props.
interface ComponentProps {
  data: DataPoint,
  private: boolean,
  itemId: number,
  fileId: number,
  index: number,
}

const PrettySlider = styled(Slider)(() => ({
  '& .MuiSlider-track': {
    background: 'linear-gradient(90deg, rgb(79, 157, 223) 0px, rgb(244, 188, 33) 150px, rgb(176, 6, 7) 300px)',
    height: 8,
    borderRadius: 4,
    border: '0px solid currentColor',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    background: 'grey',
    //border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#333',
    height: 12,
    width: 1,
    marginTop: -3,
  },
  '& .MuiSlider-valueLabel': {
    left: 'calc(-50% + 4px)',
  },
  '& .MuiSlider-rail': {
    background: 'grey',
    height: 8,
    opacity: 0.8,
    borderRadius: 4,
  },
  marginBottom: '14px'
}));

const marks = [
  {
    value: 0,
    label: 'Not Very Sensitive',
  },
  {
    value: 100,
    label: 'Sensitive',
  },
];

const DetailItems: FC<ComponentProps> = (props) => {
  const dispatch = useDispatch();

  const color = catColor(props.data.category);
  const item = props.data;
  //const [value, setValue] = React.useState(item.sensitivity ? item.sensitivity : 0);

  return (
    <ListItem sx={{
      border: '1px #5d5d5d solid',
      maxWidth: 1200,
      margin: '0 auto',
      overflowWrap: 'break-word'
    }}>
      <Node color={color} size={20} margin={20} />
      <Box sx={{
        width: 'calc(100%)'
      }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <span style={{ color: color }}>
              {item.category}
            </span>
            <span>
              {' - ' + item.subcategory.substring(1)}
            </span>
            <div>
              {item.date.toLocaleString()}
            </div>
          </Grid>
          {/*
          <Grid item sx={{
            width: "300px",
            marginLeft: "20px",
            marginRight: "20px"
          }}>
            <PrettySlider
              onChange={(_, value) => {
                dispatch(setSensitivity(props.itemId, props.fileId, props.index, value as number));
                //props.callback();
                //item.sensitivity = value as number;
              }}
              marks={marks}
              aria-labelledby="continuous-slider"
              valueLabelDisplay="off"
              value={item.sensitivity !== undefined ? item.sensitivity : 0}
              min={0}
              max={100}
            />
          </Grid>*/}
        </Grid>
        <Typography sx={{
          color: props.private ? 'transparent' : '',
          textShadow: props.private ? '0 0 5px rgb(255,255,255,0.5)' : '',
          whiteSpace: 'pre-wrap'
        }}>
          {item.text}
        </Typography>
      </Box>
    </ListItem>
  );
};

export default DetailItems;