import { Reducer, AnyAction } from 'redux';
import { FileState, FileDropActionTypes } from './types';

// Initial state
const initialState: FileState = {
  file: [],
  loading: false,
  lastFilename: '',
  error: null,
  progress: 0,
  worker: undefined,
  sampleDatasetId: 0
};

// The reducer function
const reducer: Reducer<FileState> = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case FileDropActionTypes.INIT_WORKER: {
      return Object.assign({}, state, { worker: action.payload });
    }
    case FileDropActionTypes.FILE_ADD: {
      return Object.assign({}, state, { file: [...state.file, ...action.payload.file] });
    }
    case FileDropActionTypes.FILE_POP: {
      return Object.assign({}, state, { file: state.file.slice(1), lastFilename: state.file[0].name });
    }
    case FileDropActionTypes.ITER_START: {
      return Object.assign({}, state, { error: null, loading: true, progress: 0 });
    }
    case FileDropActionTypes.ITER_PROVIDER: {
      return Object.assign({}, state, { provider: action.payload });
    }
    case FileDropActionTypes.ITER_PROGRESS: {
      return Object.assign({}, state, { progress: action.payload });
    }
    case FileDropActionTypes.ITER_SUCCESS: {
      return Object.assign({}, state, { lastFilename: action.payload, loading: false, progress: 100 });
    }
    case FileDropActionTypes.ITER_FAILURE: {
      return Object.assign({}, state, { error: action.payload, loading: false, progress: 100 });
    }
    case FileDropActionTypes.RESET_STATE: {
      return Object.assign({}, state, initialState);
    }
    case FileDropActionTypes.CLEAR_ERROR: {
      return Object.assign({}, state, { error: null });
    }
    case FileDropActionTypes.CLEAR_FILENAME: {
      return Object.assign({}, state, { lastFilename: '' });
    }
    default: {
      return state;
    }
  }
};

export { reducer as fileReducer };
