import { Reducer, AnyAction } from 'redux';
import { OptionsState, OptionsActionTypes } from './types';
import { Item } from 'store/selection/types';
import { VisTypes } from 'constants/vis';

const baseOptions: Item[] = [
  /*{
    id: 'double',
    active: false,
    view: VisTypes.Provider,
    kind: 'Toggle',
    value : false,
    label: "Double Provider"
  },*/
  {
    id: 'mental',
    active: false,
    view: VisTypes.All,
    kind: 'Toggle',
    value: false,
    label: 'ShowMentalModels'
  },
  {
    id: 'private',
    active: true,
    view: VisTypes.All,
    kind: 'Toggle',
    value: false,
    label: 'HideSensitiveData'
  },
  {
    id: 'showFolderHierarchy',
    active: true,
    view: VisTypes.Files,
    kind: 'Toggle',
    value: true,
    label: 'ShowFolderHierarchy'
  },
  {
    id: 'size',
    active: true,
    kind: 'Selector',
    value: 'uncompressed',
    label: 'ElementSize',
    view: VisTypes.Files,
    options: ['uncompressed', 'length', 'images', 'evenly'], //"min", "log", "compressed",
    labels: ['FileSize', 'DataPoints', 'OnlyImages', 'EqualSize'] //'min', "log", "compressed", 
  },
  {
    id: 'groupingSize',
    active: true,
    kind: 'Toggle',
    value: false,
    label: 'Grouping',
    view: VisTypes.Files
  },
  {
    id: 'search',
    active: false,
    kind: 'Input',
    view: VisTypes.Timeline,
    value: '',
    label: 'Search',
  },
  {
    id: 'multi',
    active: true,
    kind: 'Toggle',
    view: VisTypes.Timeline,
    value: false,
    label: 'MultipleTimelines',
  },
  {
    id: 'files',
    active: true,
    kind: 'Toggle',
    view: VisTypes.Timeline,
    value: false,
    label: 'ShowFiles',
  },
  {
    id: 'showImages',
    active: false,
    kind: 'Toggle',
    view: VisTypes.Files,
    value: false,
    label: 'ShowImages',
  },
  {
    id: 'nodecolor',
    active: true,
    kind: 'Selector',
    value: 'category',
    label: 'ColorScheme',
    view: VisTypes.Timeline,
    options: ['category', 'subcategory', 'provider', 'location'],
    labels: ['Categories', 'Subcategories', 'Provider', 'GoogleLocation']
  }
];

// Initial state
export const initialState: OptionsState = {
  options: baseOptions,
  currentVis: VisTypes.Provider,
  openDrawerOnce: true,
  sidebarOpen: false,
  searchString: '',

  // Enable Mental Models Visualizations
  mentalModels: false,
  // Enable Export dataset feature
  enableExports: false,
  // Set the generate dataset feature to a fixed seed
  fixedDataset: false,

  version: 2
};

function updateObjectInArray(array: Item[], id: string, value: any) {
  return array.map((item) => {
    if (item.id !== id) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...{ value: value }
    };
  });
}

// The reducer function
const reducer: Reducer<OptionsState> = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case OptionsActionTypes.SELECT: {
      const { id, value } = action.payload;
      return Object.assign({}, state, {
        options: updateObjectInArray(state.options, id, value)
      });
    }
    case OptionsActionTypes.SWITCH_VIS: {
      const { label }: { label: VisTypes } = action.payload;
      const shouldOpen = state.currentVis === VisTypes.Provider &&
        label !== VisTypes.Provider &&
        state.openDrawerOnce;
      return Object.assign({}, state, {
        currentVis: label,
        sidebarOpen: shouldOpen ? true : state.sidebarOpen,
        openDrawerOnce: shouldOpen ? false : state.openDrawerOnce,
      });
    }
    case OptionsActionTypes.SEARCH: {
      // TODO: should generate the data in the store...
      const { label }: { label: string } = action.payload;
      return Object.assign({}, state, {
        searchString: label
      });
    }
    case OptionsActionTypes.SIDEBAR: {
      const { label }: { label: boolean | undefined } = action.payload;
      return Object.assign({}, state, {
        sidebarOpen: label ? label : !state.sidebarOpen
      });
    }
    case OptionsActionTypes.TOGGLE: {
      return Object.assign({}, state, initialState);
    }
    case OptionsActionTypes.EXPORTS: {
      const { label }: { label: boolean } = action.payload;
      return Object.assign({}, state, { enableExports: state.enableExports || label });
    }
    case OptionsActionTypes.MENTALMODELS: {
      const { label }: { label: boolean } = action.payload;
      return Object.assign({}, state, { mentalModels: state.mentalModels || label });
    }
    case OptionsActionTypes.FIXEDDATASET: {
      const { label }: { label: boolean } = action.payload;
      return Object.assign({}, state, { fixedDataset: state.fixedDataset || label });
    }

    default: {
      return state;
    }
  }
};

export { reducer as optionsReducer };