import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';

import { ApplicationState } from 'store/index';
import { sidebarWidth, VisTypes } from 'constants/vis';
import useTranslations from 'containers/App/useLanguageHook';
import { useDispatch } from 'react-redux';
import { track } from '@store/tracking/actions';

type Question = {
  id: number,
  type: string,
  text: string
}

const SidebarRight: FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [state, setState] = React.useState<{ [key: number]: boolean }>({});

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(track('checkbox', event.target.checked ? 1 : -1));
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const vis = useSelector((state: ApplicationState) => state.options.currentVis);
  const active = useSelector((state: ApplicationState) => state.options.sidebarOpen);
  const { t } = useTranslations();
  const RIGHT_SIDEBAR_WIDTH = 240;

  function getQuestions() {
    if (vis === VisTypes.Timeline)
      return t.TimelineNotes.questions;
    if (vis === VisTypes.Files)
      return t.TreemapNotes.questions;
    return [];
  }

  function groupItems(d: Question[]): Question[][] {
    const t: { [key: string]: Question[] } = {};
    for (const item of d) {
      if (t[item.type])
        t[item.type] = [...t[item.type], item];
      else
        t[item.type] = [item];
    }
    return Object.values(t);
  }

  const items = getQuestions();
  const itemGroups = groupItems(items);

  return (
    <Drawer
      sx={{
        width: RIGHT_SIDEBAR_WIDTH,
        flexGrow: 1
      }}
      variant="persistent"
      open={active}
      anchor="right"
    >
      <Typography variant="subtitle1" sx={{
        alignSelf: 'center',
        fontWeight: 500,
        padding: 1,
        backgroundColor: theme.palette.background.paper,
        maxWidth: RIGHT_SIDEBAR_WIDTH,
        marginTop: '64px'
      }}>
        {t.TimelineNotes.title}
      </Typography>
      <Divider />
      <FormControl sx={{ maxWidth: RIGHT_SIDEBAR_WIDTH, p: 0.2, m: 0.2 }} component="fieldset" variant="standard">
        {
          itemGroups.map((questionList, index) =>
            <FormGroup key={index + '-' + questionList.length} sx={{ border: `1px solid ${theme.palette.info.main}` }}>
              {questionList.map((question) =>
                <FormControlLabel
                  key={question.id}
                  control={
                    <Checkbox checked={state[question.id]} onChange={handleChange} name={question.id + ''} />
                  }
                  sx={{
                    pt: 2,
                    m: 0,
                    'span': {
                      fontSize: '0.95rem',
                      lineHeight: '1.1',
                    }

                  }}
                  label={question.text}
                />
              )}
            </FormGroup>
          )
        }
      </FormControl>
    </Drawer>
  );
};

export default SidebarRight;
