import { Reducer, AnyAction } from 'redux';
import { ColorToggleItem, CategoriesState, CategoriesActionTypes, ColorCategories, CategoryKeyTypes } from './types';
import { COLOR_AND_FILES, COLOURS, FILE_COLOR } from 'constants/color';
import { FileCategories } from 'constants/filenames';

// Initial state
const initialState: CategoriesState = {
  catColors: createList(Object.entries(COLOURS)),
  subColors: [],
  locColors: [],
  provColors: [],
  fileColors: createList([...Object.entries(COLOURS), COLOR_AND_FILES[0] as [string, string], COLOR_AND_FILES[1] as [string, string]])


  /*[...createList(Object.entries(COLOURS)), ...Object.entries(FileCategories).map(d => {
  return {
    value: true,
    id: d[1].type,
    color: d[1].color,
    label: d[1].name,
    key: d[1].type
  }
})]*/
};

function switchToggleInArray(array: ColorToggleItem[], id: string) {
  return array
    .map((item) => (item.id !== id) ?
      item :
      {
        ...item,
        ...{
          value: !item.value
        }
      });
}

function createList(array: [string, string][]): ColorToggleItem[] {
  return array.map((item) => {
    return {
      value: true,
      id: item[0],
      color: item[1],
      label: item[0],
      key: item[0] as CategoryKeyTypes
    };
  });
}

// The reducer function
const reducer: Reducer<CategoriesState> = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case CategoriesActionTypes.TOGGLE_ITEM: {
      // actually handles select and deselect currently
      const { filter, id } = action.payload;
      switch (filter) {
        case ColorCategories.categories:
          return Object.assign({}, state, { catColors: switchToggleInArray(state.catColors, id) });
        case ColorCategories.subcategories:
          return Object.assign({}, state, { subColors: switchToggleInArray(state.subColors, id) });
        case ColorCategories.locations:
          return Object.assign({}, state, { locColors: switchToggleInArray(state.locColors, id) });
        case ColorCategories.providers:
          return Object.assign({}, state, { provColors: switchToggleInArray(state.provColors, id) });
        case ColorCategories.files:
          return Object.assign({}, state, { fileColors: switchToggleInArray(state.fileColors, id) });
        default:
          throw new Error(`Unkown filter label for CategoriesActionTypes.TOGGLE_ITEM: ${filter}, ${id}`);
      }
    }
    case CategoriesActionTypes.UPDATE_COLOR: {
      // handles deselect all folders and select all folders
      const { filter, newColors } = action.payload;
      switch (filter) {
        case ColorCategories.categories:
          return Object.assign({}, state, { catColors: createList(newColors) });
        case ColorCategories.subcategories:
          return Object.assign({}, state, { subColors: createList(newColors) });
        case ColorCategories.locations:
          return Object.assign({}, state, { locColors: createList(newColors) });
        case ColorCategories.providers:
          return Object.assign({}, state, { provColors: createList(newColors) });
        case ColorCategories.files:
          return Object.assign({}, state, { fileColors: createList(newColors) });
        default:
          throw new Error(`Unkown filter label for CategoriesActionTypes.UPDATE_COLOR: ${filter}, ${newColors}`);
      }
    }
    case CategoriesActionTypes.CREATE_SUBCATS: {
      const { list } = action.payload;

      return Object.assign({}, state, { subColors: createList(list) });
    }
    case CategoriesActionTypes.SELECT_ALL: {
      const { filter } = action.payload;
      switch (filter) {
        case ColorCategories.categories:
          return Object.assign({}, state, { catColors: state.catColors.map(d => { return { ...d, value: true }; }) });
        case ColorCategories.subcategories:
          return Object.assign({}, state, { subColors: state.subColors.map(d => { return { ...d, value: true }; }) });
        case ColorCategories.locations:
          return Object.assign({}, state, { locColors: state.locColors.map(d => { return { ...d, value: true }; }) });
        case ColorCategories.providers:
          return Object.assign({}, state, { provColors: state.provColors.map(d => { return { ...d, value: true }; }) });
        case ColorCategories.files:
          return Object.assign({}, state, { fileColors: state.fileColors.map(d => { return { ...d, value: true }; }) });
        default:
          throw new Error(`Unkown filter label for CategoriesActionTypes.UPDATE_COLOR: ${filter}`);
      }
    }
    case CategoriesActionTypes.DESELECT_ALL: {
      const { filter } = action.payload;
      switch (filter) {
        case ColorCategories.categories:
          return Object.assign({}, state, { catColors: state.catColors.map(d => { return { ...d, value: false }; }) });
        case ColorCategories.subcategories:
          return Object.assign({}, state, { subColors: state.subColors.map(d => { return { ...d, value: false }; }) });
        case ColorCategories.locations:
          return Object.assign({}, state, { locColors: state.locColors.map(d => { return { ...d, value: false }; }) });
        case ColorCategories.providers:
          return Object.assign({}, state, { provColors: state.provColors.map(d => { return { ...d, value: false }; }) });
        case ColorCategories.files:
          return Object.assign({}, state, { fileColors: state.fileColors.map(d => { return { ...d, value: false }; }) });
        default:
          throw new Error(`Unkown filter label for CategoriesActionTypes.UPDATE_COLOR: ${filter}`);
      }
    }
    default: {
      return state;
    }
  }
};

export { reducer as categoriesReducer };