
//  maximal supported size for data exports
const MaxFileSize = 512 * 1024 * 1024 * 1024; // 512MB
// File Extensions
const AudioExtensions = ['.mp3', '.wav', '.mid', '.mp1', '.mp2', '.m4a', '.flac', '.ogg'];
const DocumentsExtension = ['.doc', '.docx', '.pdf', '.rtf', '.wpd', '.wps', '.ppt', '.ods', '.xls', '.pptx', '.key', '.tex'];
const PictureExtensions = ['.jpg', '.png', '.gif', '.jpeg', '.bmp', '.webp', '.ico', '.tif'];
const VideoExtensions = ['.mp4', '.3gp', '.mpg', '.mpeg', '.mov', '.avi', '.wmv', '.asf', '.mkv', '.webm', '.flv', '.mp2'];
const ParseableExtensions = ['.json', '.js', '.txt', '.xml', '.ics', '.html', '.csv', '.vcf', '.tcx', '.zip'];
// Mime Types
const AudioMimeTypes = ['application/ogg', 'application/mp3'];
const DocumentsMimeTypes = ['application/pdf'];
const PictureMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'imag/tif'];
const VideoMimeTypes = ['video/mp4', 'video/3gp', 'video/mpeg', 'video/webm'];
const ParseableMimeTypes = ['application/json', 'text/javascript', 'text/plain', 'text/html', 'text/csv'];

enum FileCategory {
  //  Audio = "Audio",
  //  Document = "Document",
  Picture = 'Picture',
  Text = 'Text',
  Video = 'Video',
  Other = 'Other'
}

const FileCategories = [
  //  { mimeType: AudioMimeTypes, type: FileCategory.Audio, color: "#f4cae4", name: "Audio File", list: AudioExtensions },
  //  { mimeType: DocumentsMimeTypes, type: FileCategory.Document, color: "#b3e2cd", name: "Document File", list: DocumentsExtension },
  { mimeType: PictureMimeTypes, type: FileCategory.Picture, color: '#fdcdac', name: 'Picture File', list: PictureExtensions },
  { mimeType: ParseableMimeTypes, type: FileCategory.Text, color: '#fff2ae', name: 'Data File', list: ParseableExtensions },
  { mimeType: VideoMimeTypes, type: FileCategory.Video, color: '#e6f5c9', name: 'Video File', list: VideoExtensions },
  //{ mimeType: [], type: FileCategory.Other, color: "#fbf5e8", name: "Other File", list: [] }
];

export {
  ParseableExtensions,
  PictureExtensions,
  MaxFileSize,
  FileCategory,
  FileCategories
};