import React, { Component, FC, useState } from 'react';

import { Typography, Link, Tabs, Tab, Box } from '@mui/material';
import useLanguageHook from 'containers/App/useLanguageHook';

export type GdprConfiguration = {
  projectName: string;
  projectUrl: string;
  partnerDescription?: string;
  contactFormEnabled?: boolean;
  webanalytics?: WebanalyticsType;
  youtube: boolean;
  social?: SocialType;
};

type WebanalyticsType = {
  googleanalytics?: boolean;
  matomo?: boolean;
  wireminds?: boolean;
};

type SocialType = {
  facebook?: boolean;
  pinterest?: boolean;
  twitter?: boolean;
  googleplus?: boolean;
  xing?: boolean;
};

const Gdpr: FC = () => {
  const { lang } = useLanguageHook();
  const [isenglish, setIsEnglish] = useState(lang === 'en' ? 0 : 1);

  const GdprConfiguration = {
    projectName: 'TransparencyEval',
    projectUrl: 'https://transparencyvis-eval-edition.vx.igd.fraunhofer.de/',
    partnerDescription: '',
    contactFormEnabled: false,
    webanalytics: {
      googleanalytics: false,
      matomo: false,
      wireminds: false
    },
    youtube: false,
    social: {
      facebook: false,
      pinterest: false,
      twitter: false,
      googleplus: false,
      xing: false
    }
  };


  return (
    <div>
      <Tabs
        value={isenglish}
        onChange={(_, newValue) => setIsEnglish(newValue)}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="English" {...a11yProps(0)} />
        <Tab label="German" {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={isenglish} index={0}>
        {english()}
      </TabPanel>
      <TabPanel value={isenglish} index={1}>
        {deutsch()}
      </TabPanel>
    </div>

  );

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3} maxWidth={1280} style={{ margin: 'auto' }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function deutsch() {
    const {
      projectName,
      partnerDescription,
      webanalytics,
      social,
      youtube,
      contactFormEnabled,
      projectUrl
    } = GdprConfiguration;

    return (
      <div style={{ paddingLeft: '5vw', paddingRight: '5vw' }}>
        <Typography variant="h4" align="center">
          Datenschutzinformation {projectName}
        </Typography>
        <Typography variant="h6" align="center">
          Statement of Data Protection Conditions for the Internet
        </Typography>
        <hr />
        <Typography align="justify">
          This statement (in German) is conforming with the General Data
          Protection Regulation (GDPR) of the European Union.
          <br />
          <br />
          {projectName} ist ein Projekt des
          Fraunhofer-Institut für Graphische Datenverarbeitung IGD{' '} und des
          Nationalen Forschungszentrums für angewandte Cybersicherheit ATHENE.
          <br />
          <br />
          Im Rahmen der Nutzung dieser Webseite werden personenbezogene Daten
          von Ihnen durch uns als den für die Datenverarbeitung Verantwortlichen
          verarbeitet und für die Dauer gespeichert, die zur Erfüllung der
          festgelegten Zwecke und gesetzlicher Verpflichtungen erforderlich ist.
          Im Folgenden informieren wir Sie darüber, um welche Daten es sich
          dabei handelt, auf welche Weise sie verarbeitet werden und welche
          Rechte Ihnen diesbezüglich zustehen.
          <br />
          <br />
          Personenbezogene Daten sind gemäß Art. 4 Nr. 1
          Datenschutzgrundverordnung (DSGVO) alle Informationen, die sich auf
          eine identifizierte oder identifizierbare natürliche Person beziehen.
        </Typography>
        <br />
        <br />

        <Typography align="left">
          <Link href="#kontaktdaten" underline="always">
            1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen
            sowie des betrieblichen Datenschutzbeauftragten
          </Link>
          <br />
          <Link href="#verarbeitung" underline="always">
            2. Verarbeitung personenbezogener Daten und Zwecke der Verarbeitung
          </Link>
          <br />
          <Link href="#cookies" underline="always">
            3. Cookies
          </Link>
          <br />
          <Link href="#webanalyse" underline="always">
            4. Webanalyse/Tracking
          </Link>
          <br />
          <Link href="#betroffenenrechte" underline="always">
            5. Betroffenenrechte
          </Link>
          <br />
          <Link href="#widerspruch" underline="always">
            6. Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO
          </Link>
          <br />
          <Link href="#datensicherheit" underline="always">
            7. Datensicherheit
          </Link>
          <br />
          <Link href="#aktualitaet" underline="always">
            8. Aktualität und Änderung dieser Datenschutzinformation
          </Link>
          <br />
          <Link href="#klausel" underline="always">
            9. Salvatorische Klausel
          </Link>
        </Typography>
        <br />
        <br />

        <Typography variant="h6" align="left">
          <a id="kontaktdaten">
            1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen
            sowie des betrieblichen Datenschutzbeauftragten
          </a>
        </Typography>
        <Typography align="justify" >
          Diese Datenschutzinformation gilt für die Datenverarbeitung unter{' '}
          {projectUrl} durch den Verantwortlichen:
          <br />
          <br />
          Fraunhofer-Gesellschaft
          <br />
          zur Förderung der angewandten Forschung e.V.
          <br />
          Hansastraße 27 c,
          <br />
          80686 München
          <br />
          <br />
          für das Fraunhofer-Institut für Graphische Datenverarbeitung IGD:
          <br />
          <br />
          E-Mail:{' '}
          <Link href="mailto:info@igd.fraunhofer.de" underline="always">
            info@igd.fraunhofer.de
          </Link>
          <br />
          Telefon: +49 6151 155-0
          <br />
          Fax: +49 6151 155-199
          <br />
          <br />
          Der Datenschutzbeauftragte von Fraunhofer ist unter der o.g.
          Anschrift, zu Hd. Datenschutzbeauftragter bzw. unter{' '}
          <Link href="mailto:datenschutz@zv.fraunhofer.de" underline="always">
            datenschutz@zv.fraunhofer.de
          </Link>
          {' '}erreichbar.
          <br />
          Sie können sich jederzeit bei Fragen zum Datenschutzrecht oder Ihren
          Betroffenenrechten direkt an unseren Datenschutzbeauftragten wenden.
        </Typography>
        <br />

        <Typography variant="h6" align="left">
          <a id="verarbeitung">
            2. Verarbeitung personenbezogener Daten und Zwecke der Verarbeitung
          </a>
        </Typography>
        <Typography variant="h6" align="justify">
          a) Beim Besuch der Webseite
        </Typography>
        <Typography align="justify">
          Wenn Sie unsere Webseiten besuchen, speichern die Webserver unserer
          Webseite temporär jeden Zugriff Ihres Endgerätes in einer
          Protokolldatei. Folgende Daten werden erfasst und bis zur
          automatisierten Löschung gespeichert:
        </Typography>
        <ul>
          <li>
            <Typography align="justify">
              IP-Adresse des anfragenden Rechners
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              Datum und Uhrzeit des Zugriffs
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              Name und URL der abgerufenen Daten
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              Übertragene Datenmenge <br />
              Meldung, ob der Abruf erfolgreich war
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              Verwendeter Browser- und Betriebssystem
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              Name des Internet-Zugangs-Providers
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              Webseite, von der aus der Zugriff erfolgt (Referrer-URL)
            </Typography>
          </li>
        </ul>
        <Typography align="justify">
          Die Verarbeitung dieser Daten erfolgt zu folgenden Zwecken:
        </Typography>
        <ol>
          <li>
            <Typography align="justify">
              Ermöglichung der Nutzung der Webseite (Verbindungsaufbau)
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              Administration der Netzinfrastruktur
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              Angemessene technisch-organisatorische Maßnahmen zur IT-System-
              und Informationssicherheit unter Berücksichtigung des Stands der
              Technik
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              Gewährleistung der Nutzerfreundlichkeit der Nutzung
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              Optimierung des Internetangebotes
            </Typography>
          </li>
        </ol>
        <Typography align="justify">
          Rechtsgrundlagen für die vorstehenden Verarbeitungen sind
        </Typography>
        <ul>
          <li>
            <Typography align="justify">
              für die Verarbeitung für den Besuch der Webseiten nach den Nummern
              1-2 Art. 6 Abs. 1 S.1 lit. b (Erforderlichkeit für die Erfüllung
              des Webseiten-Nutzungvertragsverhältnisses),
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              für die Verarbeitungen nach Nummer 3 Art. 6 Abs. 1 S.1 lit. c
              DSGVO (rechtliche Verpflichtung zur Umsetzung
              technisch-organisatorischer Maßnahmen zur Sicherung der
              Datenverarbeitung nach Art. 32 DSGVO) und Art. 6 Abs. 1 S.1 lit. f
              DSGVO (berechtigte Interessen zur Datenverarbeitung für die Netz-
              und Informationssicherheit) sowie für
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              die Verarbeitungen nach den Nummern 4-5 Art. 6 Abs. 1 S.1 lit. f
              DSGVO (berechtigte Interessen). Die berechtigten Interessen
              unserer Datenverarbeitung bestehen darin, unser Angebot
              nutzerfreundlich zu gestalten und zu optimieren. [Im Rahmen der
              berechtigten Interessen können wir auch Direktwerbung ausliefern.]
            </Typography>
          </li>
        </ul>
        <Typography align="justify">
          Die vorstehend genannten Daten werden nach einer definierten Zeit vom
          Webserver automatisiert gelöscht, die 35 Tage beträgt. Sofern Daten zu
          Zwecken nach Nummern 2-5 länger verarbeitet werden, erfolgt die
          Anonymisierung oder Löschung, wenn die Speicherung für den jeweiligen
          Zweck nicht mehr erforderlich ist.
          <br />
          <br />
          Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie
          Analysedienste ein. Nähere Erläuterungen dazu erhalten Sie unter den
          Ziffern 4 und 5 dieser Datenschutzinformation.
        </Typography>
        <br />
        {/*kontaktformular*/}
        <Typography variant="h6" align="justify">
          b) Bei Nutzung von Kontaktformularen und mailto-Links
        </Typography>
        <Typography>
          Auf der Website ist kein Kontaktformular vorhanden.
          Besucher können über einen mailto-Link auf der Webseite Nachrichten an die Betreiber übermitteln.
          Um eine Antwort empfangen zu können, ist zumindest die Angabe einer gültigen E-Mail-Adresse erforderlich.
          Alle weiteren Angaben kann die anfragende Person freiwillig geben. Mit Absenden der Nachricht über den
          jeweiligen E-Mail-Client
          willigt der Besucher in die Verarbeitung der übermittelten personenbezogenen Daten ein.

          Die Datenverarbeitung erfolgt ausschließlich zu dem Zweck der Abwicklung und Beantwortung
          von Anfragen über den mailto-Link. Dies geschieht auf Basis der freiwillig erteilten Einwilligung
          gem. Art. 6 Abs. 1 Satz 1 Buchst. a) DSGVO. Die für die Benutzung des mailto-Links erhobenen
          personenbezogenen Daten könnend auf Anfrage gelöscht werden,
          sobald die Anfrage erledigt ist und keine Gründe für eine weitere Aufbewahrung gegeben sind.
        </Typography>
        {/*
          <Typography variant="h6" align="left">
            <a id="weitergabe">3. Weitergabe von personenbezogenen Daten</a>
          </Typography>
          <Typography align="justify">
            Entfällt
          </Typography> */}

        { /*
        <Typography align="justify">
          Außer in den zuvor genannten Fällen einer Verarbeitung im Auftrag
          (Anmeldung für Veranstaltungen, Anmeldung zu einem Newsletter) geben
          wir Ihre personenbezogenen Daten nur an Dritte, d.h. andere natürliche
          oder juristische Personen außer Ihnen (der betroffenen Person), dem
          Verantwortlichen oder dem Auftragsverarbeiter und deren zur
          Datenverarbeitung befugten Mitarbeiter weiter, wenn:
        </Typography>
        <ul>
          <li>
            <Typography align="justify">
              Sie gem. Art. 6 Abs. 1 S. 1 lit. a DSGVO Ihre ausdrückliche
              Einwilligung dazu erteilt haben;
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              dies gem. Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Erfüllung eines
              Vertrages mit Ihnen erforderlich ist,
            </Typography>
            <ul>
              <li>
                <Typography align="justify">
                  Weitergabe an Versandunternehmen zum Zwecke der Lieferung der
                  von Ihnen bestellten Ware,
                </Typography>
              </li>
              <li>
                <Typography align="justify">
                  Weitergabe von Zahlungsdaten an Zahlungsdienstleister bzw.
                  Kreditinstitute, um einen Zahlungsvorgang durchzuführen;
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography align="justify">
              für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit.
              c DSGVO eine gesetzliche Verpflichtung besteht, bspw. an Finanz-
              oder Strafverfolgungsbehörden;
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              die Weitergabe ist nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
              erforderlich und es besteht kein Grund zur Annahme, dass Sie ein
              überwiegendes schutzwürdiges Interesse an der Nichtweitergabe
              Ihrer Daten haben; eine solche Weitergabe kann bspw. im Falle von
              Angriffen auf unsere IT-Systeme an staatliche Einrichtungen und
              Strafverfolgungsbehörden erfolgen.
            </Typography>
          </li>
        </ul>
        <Typography align="justify">
          Die weitergegebenen Daten dürfen von dem Dritten ausschließlich zu den
          genannten Zwecken verwendet werden.
          <br />
          <br />
          Wenn Sie sich für eine Veranstaltung angemeldet haben, kann es im
          Rahmen der Vertragserfüllung erforderlich sein, dass Ihre
          personenbezogenen Daten an einen externen Veranstalter übermittelt
          werden müssen. Im Zusammenhang mit einer Veranstaltungsanmeldung
          werden Sie darüber informiert, wer Veranstalter ist und ob es sich
          dabei um einen externen Veranstalter handelt. Dieser wird
          personenbezogenen Daten im Rahmen der Veranstaltung und insbesondere
          zur Teilnehmerverwaltung verarbeiten
          <br />
          <br />
          Eine Übermittlung von personenbezogenen Daten an ein Drittland
          (außerhalb der EU) oder eine internationale Organisation ist
          ausgeschlossen.
        </Typography>
        <br />
*/}
        <Typography variant="h6" align="left">
          <a id="cookies">3. Cookies</a>
        </Typography>
        <Typography align="justify">
          Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um
          kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem
          Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn
          Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen
          Schaden an, enthalten keine Viren, Trojaner oder sonstige
          Schadsoftware.
          <br />
          <br />
          In dem Cookie werden Informationen abgelegt, die sich jeweils im
          Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies
          bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer
          Identität erhalten.
          <br />
          <br />
          Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres
          Angebots für Sie angenehmer zu gestalten. So setzen wir sogenannte
          Session-Cookies ein, um die Sitzungssteuerung zu ermöglichen, bspw.
          Formulareingaben oder Warenkörbe während der Sitzung zu speichern.
          Session-Cookies werden spätestens mit dem Schließen Ihres Webbrowsers
          gelöscht.
          <br />
          <br />
          Darüber hinaus setzen wir ebenfalls zur Optimierung der
          Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten
          festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen
          Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird
          automatisch erkannt, dass Sie bereits bei uns waren und welche
          Eingaben und Einstellungen sie getätigt haben, um diese nicht noch
          einmal eingeben zu müssen.
          <br />
          <br />
          Zum anderen setzen wir Cookies ein, um die Nutzung unserer Website
          statistisch zu erfassen und zum Zwecke der Optimierung unseres
          Angebotes für Sie auszuwerten (siehe Ziffer 5). Diese Cookies
          ermöglichen es uns, bei einem erneuten Besuch unserer Seite
          automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies
          werden nach einer jeweils definierten Zeit automatisch gelöscht.
          <br />
          <br />
          Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke
          zur Wahrung unserer berechtigten Interessen sowie der Dritter nach
          Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.
          <br />
          <br />
          Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren
          Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer
          gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer
          Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann
          jedoch dazu führen, dass Sie nicht alle Funktionen unserer Website
          nutzen können.
        </Typography>
        <br />
        { }<Typography variant="h6" align="justify">
          <a id="webanalyse">4. Webanalyse/Tracking</a>
        </Typography>
        <Typography variant="h6" align="justify">
          c) Matomo
        </Typography>
        <Typography align="justify">
          Wir setzen auf unserer Webseite den Open-Source-Dienst{' '}
          <Link href="https://matomo.org/" underline="always">
            Matomo von InnoCraft Ltd
          </Link>
          {' '}aus
          Neuseeland ein, um das Nutzerverhalten bei Besuch unserer Seite zu
          analysieren und unsere Seite und deren Inhalte entsprechend darauf
          basierend zu optimieren. Dabei erhalten wir keine Informationen,
          die Sie unmittelbar identifizieren.
          <br />
          <br />
          Im Zusammenhang mit dem Einsatz von Matomo werden Cookies
          eingesetzt, die eine statistische Analyse der Nutzung dieser
          Website durch Ihre Besuche ermöglichen. In dem Cookie werden
          Informationen – einschließlich personenbezogener Informationen -zu
          Ihrem Besucherverhalten abgelegt und unter Verwendung eines
          Pseudonyms in einem Nutzungsprofil zum Zwecke der Analyse
          verarbeitet. Da Matomo auf unseren eigenen Server gehostet wird,
          ist für die Analyse eine Verarbeitung durch Dritte nicht
          erforderlich.
          <br />
          <br />
          Die dabei gewonnenen Daten werden ohne Ihre gesondert erteilte
          Zustimmung nicht benutzt, um Sie persönlich zu identifizieren und
          die Daten werden nicht mit personenbezogenen Daten über Sie als
          Träger des Pseudonyms zusammengeführt.
          <br />
          <br />
          Soweit IP-Adressen erhoben werden, werden diese unverzüglich nach
          Erhebung durch Löschen des letzten Nummernblocks anonymisiert.
          Weitere personenbezogene Daten in dem Cookie werden nach 13
          Monaten gelöscht.
          <br />
          <br />
          Die Datenverarbeitung erfolgt auf Grund unseres berechtigten
          Interesses gemäß Art. 6 Abs.1 lit. f DSGVO an der Optimierung
          unseres Online-Angebotes und unseres Webauftritts.
          <br />
          <br />
        </Typography>

        {/*
            Ihr Besuch dieser Webseite wird aktuell von der Matomo Webanalyse
            erfasst. Klicken Sie{" "}
            <Link href="https://matomo.org/docs/privacy/" underline="always"> hier </Link>, um zu
            erfahren, wie Ihr Besuch nicht mehr erfasst wird. */}

        <br />
        <Typography variant="h6" align="left">
          <a id="betroffenenrechte">5. Betroffenenrechte</a>
        </Typography>
        <Typography align="justify">Sie haben das Recht:</Typography>
        <ul>
          <li>
            <Typography align="justify">
              gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung
              jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass
              wir die Datenverarbeitung, die auf dieser Einwilligung beruhte,
              für die Zukunft nicht mehr fortführen dürfen;
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten
              personenbezogenen Daten zu verlangen. Insbesondere können Sie
              Auskunft über die Verarbeitungszwecke, die Kategorie der
              personenbezogenen Daten, die Kategorien von Empfängern, gegenüber
              denen Ihre Daten offengelegt wurden oder werden, die geplante
              Speicherdauer, das Bestehen eines Rechts auf Berichtigung,
              Löschung, Einschränkung der Verarbeitung oder Widerspruch, das
              Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern
              diese nicht bei uns erhoben wurden, sowie über das Bestehen einer
              automatisierten Entscheidungsfindung einschließlich Profiling und
              ggf. aussagekräftigen Informationen zu deren Einzelheiten
              verlangen;
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder
              Vervollständigung Ihrer bei uns gespeicherten personenbezogenen
              Daten zu verlangen;
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten
              personenbezogenen Daten zu verlangen, soweit nicht die
              Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung
              und Information, zur Erfüllung einer rechtlichen Verpflichtung,
              aus Gründen des öffentlichen Interesses oder zur Geltendmachung,
              Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
              <br />
              Meldung, ob der Abruf erfolgreich war
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen, soweit die Richtigkeit der
              Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig
              ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr
              benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder
              Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21
              DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns
              bereitgestellt haben, in einem strukturierten, gängigen und
              maschinenlesebaren Format zu erhalten oder die Übermittlung an
              einen anderen Verantwortlichen zu verlangen und
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren.
              In der Regel können Sie sich hierfür an die Aufsichtsbehörde ihres
              üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres
              Unternehmenssitzes wenden.
            </Typography>
          </li>
        </ul>

        <Typography variant="h6" align="left">
          <a id="widerspruch">
            6. Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO
          </a>
        </Typography>
        <Typography align="justify">
          Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
          Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
          personenbezogener Daten, die aufgrund von Artikel 6 Absatz 1 Buchstabe
          e DSGVO (Datenverarbeitung im öffentlichen Interesse) und Artikel 6
          Absatz 1 Buchstabe f DSGVO (Datenverarbeitung auf der Grundlage einer
          Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch
          für ein auf diese Bestimmung gestütztes Profiling von Artikel 4 Nr. 4
          DSGVO.
          <br />
          <br />
          Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten
          nicht mehr verarbeiten, es sei denn, wir können zwingende
          schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
          Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung
          dient der Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen.
          <br />
          <br />
          Sofern sich Ihr Widerspruch gegen eine Verarbeitung von Daten zum
          Zwecke der Direktwerbung richtet, so werden wir die Verarbeitung
          umgehend einstellen. In diesem Fall ist die Angabe einer besonderen
          Situation nicht erforderlich. Dies gilt auch für das Profiling, soweit
          es mit solcher Direktwerbung in Verbindung steht.
          <br />
          <br />
          Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen, genügt eine
          E-Mail an{' '}
          <Link href="mailto:datenschutz@zv.fraunhofer.de" underline="always">
            datenschutz@zv.fraunhofer.de
          </Link>
          .
        </Typography>
        <br />

        <Typography variant="h6" align="left">
          <a id="datensicherheit">7. Datensicherheit</a>
        </Typography>
        <Typography align="justify">
          Alle von Ihnen persönlich übermittelten Daten werden mit dem allgemein
          üblichen und sicheren Standard TLS (Transport Layer Security)
          verschlüsselt übertragen. TLS ist ein sicherer und erprobter Standard,
          der z.B. auch beim Onlinebanking Verwendung findet. Sie erkennen eine
          sichere TLS-Verbindung unter anderem an dem angehängten s am http
          (also https://..) in der Adressleiste Ihres Browsers oder am
          Schloss-Symbol im unteren Bereich Ihres Browsers.
          <br />
          <br />
          Wir bedienen uns im Übrigen geeigneter technischer und
          organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige
          oder vorsätzliche Manipulationen, teilweisen oder vollständigen
          Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu
          schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der
          technologischen Entwicklung fortlaufend verbessert.
        </Typography>
        <br />

        <Typography variant="h6" align="left">
          <a id="aktualitaet">
            8. Aktualität und Änderung dieser Datenschutzinformation
          </a>
        </Typography>
        <Typography align="justify">
          Diese Datenschutzinformation ist aktuell gültig und hat den Stand Mai
          2018.
          <br />
          Durch die Weiterentwicklung unserer Webseite und Angebote darüber oder
          aufgrund geänderter gesetzlicher bzw. behördlicher Vorgaben kann es
          notwendig werden, diese Datenschutzinformation zu ändern. Die jeweils
          aktuelle Datenschutzinformation kann jederzeit auf der Webseite unter
          {' '}
          <Link href="https://www.igd.fraunhofer.de/datenschutzinformation" underline="always">
            https://www.igd.fraunhofer.de/datenschutzinformation
          </Link>
          {' '}
          von Ihnen abgerufen und ausgedruckt werden.
        </Typography>
        <br />

        <Typography variant="h6" align="left">
          <a id="klausel">9. Salvatorische Klausel</a>
        </Typography>
        <Typography align="justify">
          Sollten einzelne Bestimmungen dieser Datenschutzerklärung ganz oder in
          Teilen unwirksam oder undurchführbar sein oder werden, berührt dies
          nicht die Wirksamkeit der übrigen Bestimmungen. Entsprechendes gilt im
          Fall von Lücken.
        </Typography>

        <br />
        <br />
        <br />
      </div>
    );
  }

  function english() {
    const {
      projectName,
      partnerDescription,
      webanalytics,
      social,
      youtube,
      contactFormEnabled,
      projectUrl
    } = GdprConfiguration;

    return (
      <div style={{ paddingLeft: '5vw', paddingRight: '5vw' }}>
        <Typography variant="h4" align="center">
          Data Protection Information {projectName}
        </Typography>
        <Typography variant="h6" align="center">
          Statement of Data Protection Conditions for the Internet
        </Typography>
        <hr />
        <Typography align="justify">
          This statement is conforming with the General Data
          Protection Regulation (GDPR) of the European Union.
          <br />
          <br />
          {projectName} is a project of Fraunhofer-Institute IGD and
          National Research Center for Applied Cybersecurity ATHENE.
          <br />
          <br />
          The following pertains to the use of this website. As the party responsible for the data processing (controller),
          we process the personal data collected via our website and store them for the period,
          which is required to achieve the specified purpose and to comply with the statutory requirements.
          The following text informs you of the data we collect and the way we process the collected data.
          We also inform you about on data privacy rights as they pertain to the use of our website.
          <br />
          <br />
          Pursuant to Article 4 No. 1 GDPR,
          personal data are all data referring to a specific or identifiable natural person.
        </Typography>
        <br />
        <br />
        <Typography align="left">
          <Link href="#kontaktdaten" underline="always">
            1. Name and contact information of the person who signs responsible for the data processing (controller)
            and of the society’s data protection officer
          </Link>
          <br />
          <Link href="#verarbeitung" underline="always">
            2. Processing of Personal Data and Purposes of the Data Processing
          </Link>
          <br />
          <Link href="#cookies" underline="always">
            3. Cookies
          </Link>
          <br />
          <Link href="#webanalyse" underline="always">
            4. Web analysis/Tracking
          </Link>
          <br />
          <Link href="#betroffenenrechte" underline="always">
            5. Your Rights as Affected Person
          </Link>
          <br />
          <Link href="#widerspruch" underline="always">
            6. Information on your Right of Objections according to Article 21 GDPR
          </Link>
          <br />
          <Link href="#datensicherheit" underline="always">
            7. Data Security
          </Link>
          <br />
          <Link href="#aktualitaet" underline="always">
            8. Timeliness of the Data and Amendments to this Data Protection Information
          </Link>
          <br />
          <Link href="#klausel" underline="always">
            9. Severability
          </Link>
        </Typography>
        <br />
        <br />

        <Typography variant="h6" align="left">
          <a id="kontaktdaten">
            1. Name and contact information of the person who signs responsible for the data processing (controller)
            and of the society’s data protection officer
          </a>
        </Typography>
        <Typography align="justify">
          This data protection information shall apply to the processing of data on{' '}
          {projectUrl} by the controller, the:
          <br />
          <br />
          Fraunhofer Society for the Advancement of Applied Research
          <br />
          Fraunhofer Gesellschaft zur Förderung der angewandten Forschung e.V.
          <br />
          Hansastraße 27 c,
          <br />
          D-80686 München (Munich, Germany)
          <br />
          <br />
          On behalf of your Fraunhofer Institute for Computer Graphics Research IGD:
          <br />
          <br />
          E-Mail:{' '}
          <Link href="mailto:info@igd.fraunhofer.de" underline="always">info@igd.fraunhofer.de</Link>
          <br />
          Telefon: +49 6151 155-0
          <br />
          Fax: +49 6151 155-199
          <br />
          <br />
          You can reach the data protection representative of the Fraunhofer Institute at the above address c/o
          Data Protection Officer or at {' '}
          <Link href="mailto:datenschutz@zv.fraunhofer.de" underline="always">datenschutz@zv.fraunhofer.de</Link>{' '}
          erreichbar.
          <br />
          Please feel free to contact the data protection officer directly at any time with your questions concerning
          your data protection rights and/or your rights as affected person.
        </Typography>
        <br />

        <Typography variant="h6" align="left">
          <a id="verarbeitung">
            2. Processing of Personal Data and Purposes of the Data Processing
          </a>
        </Typography>
        <Typography variant="h6" align="justify">
          a) During your Website Visit
        </Typography>
        <Typography align="justify">
          Every time you visit our web pages, our website servers save a protocol of your device accessing our website.
          This storage is temporary and lasts only until the automated deletion.
          Our website server saves the following access data until their automated deletion:
        </Typography>
        <ul>
          <li>
            <Typography align="justify">
              The IP address of the requesting device
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              Access date and time
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              Name and URL of the accessed data
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              The transmitted data volume <br />
              The message whether the access was successful
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              The used browser and operating system
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              Name of the Internet Provider (ISP)
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              The referring website (referring URL)
            </Typography>
          </li>
        </ul>
        <Typography align="justify">
          The server processes these data for the following purposes:
        </Typography>
        <ol>
          <li>
            <Typography align="justify">
              To enable the use of the website (link connection [forward setup])
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              Administration of the network infrastructure
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              Appropriate technical and organisational measures to ensure IT systems and data security
              commensurate with the available state of the art technology
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              To offer user-friendly service
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              To optimize the Internet offering
            </Typography>
          </li>
        </ol>
        <Typography align="justify">
          Legal foundations for the above processing purposes:
        </Typography>
        <ul>
          <li>
            <Typography align="justify">
              Processing in response to a website visitor according to numbers 1-2 Article 6 para. 1, page1,
              lit. b (Requirement for compliance with provisions of the website user contract)
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              Processing pursuant to numbers 3, Article 6 para. 1, page 1, lit. c GDPR
              (legal obligation to implement technical and organisational measures to ensure secure data processing
              according to Article 32 GDPR and Article 6 para. 1, page 1, lit. f GDPR
              (legitimate interests in data processing for the network and information security) as well as
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              Data processing pursuant to numbers 4 – 5, Article 6 para. 1, page1 lit. f GDPR (legitimate interests) -
              our legitimate interests in the processing of data are based in our desire to offer
              user-friendly optimised web pages
              [our legitimate interests also include direct advertising.]
            </Typography>
          </li>
        </ul>
        <Typography align="justify">
          After the specified period of 35 days, our web server automatically deletes the above-mentioned data.
          To the extent that data are processed longer for purposes according to numbers 2 – 5,
          we will anonymise or delete the data as soon as their storage no longer serves the respective purpose(s).
          <br />
          <br />
          Furthermore, we place cookies on the devices of our website visitors and use analytical services.
          For more information on the use of cookies and analytical services,
          please see Sections 4 and 5 of this Data Protection Statement
        </Typography>
        <br />
        {/*kontaktformular*/}
        <Typography variant="h6" align="justify">
          b) When using contact forms and mailto links
        </Typography>
        <Typography>
          There is no contact form available on the website. Visitors can transmit messages to the provider
          of the website by using a mailto link. In order to receive an answer, at least a valid e-mail address must be
          provided. Further information can be provided on a voluntary basis by the person requesting. When transmitting
          the message with the respective e-mail client, the visitor gives his/her consent that the personal data is
          being processed. These data are processed exclusively for the purpose of answering the request via the mailto
          link. This is based on the voluntarily given consent in accordance with article 6, paragraph 1, sentence 1,
          letter a) of the GDPR. The Personal data transmitted when using the mailto link can be deleted upon request as
          soon as the purpose of the request has been served and no more reasons for keeping the data exists.
        </Typography>
        {/*
          <Typography variant="h6" align="left">
            <a id="weitergabe">3. Weitergabe von personenbezogenen Daten</a>
          </Typography>
          <Typography align="justify">
            Entfällt
          </Typography> */}

        { /*
        <Typography align="justify">
          Außer in den zuvor genannten Fällen einer Verarbeitung im Auftrag
          (Anmeldung für Veranstaltungen, Anmeldung zu einem Newsletter) geben
          wir Ihre personenbezogenen Daten nur an Dritte, d.h. andere natürliche
          oder juristische Personen außer Ihnen (der betroffenen Person), dem
          Verantwortlichen oder dem Auftragsverarbeiter und deren zur
          Datenverarbeitung befugten Mitarbeiter weiter, wenn:
        </Typography>
        <ul>
          <li>
            <Typography align="justify">
              Sie gem. Art. 6 Abs. 1 S. 1 lit. a DSGVO Ihre ausdrückliche
              Einwilligung dazu erteilt haben;
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              dies gem. Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Erfüllung eines
              Vertrages mit Ihnen erforderlich ist,
            </Typography>
            <ul>
              <li>
                <Typography align="justify">
                  Weitergabe an Versandunternehmen zum Zwecke der Lieferung der
                  von Ihnen bestellten Ware,
                </Typography>
              </li>
              <li>
                <Typography align="justify">
                  Weitergabe von Zahlungsdaten an Zahlungsdienstleister bzw.
                  Kreditinstitute, um einen Zahlungsvorgang durchzuführen;
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography align="justify">
              für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit.
              c DSGVO eine gesetzliche Verpflichtung besteht, bspw. an Finanz-
              oder Strafverfolgungsbehörden;
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              die Weitergabe ist nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
              erforderlich und es besteht kein Grund zur Annahme, dass Sie ein
              überwiegendes schutzwürdiges Interesse an der Nichtweitergabe
              Ihrer Daten haben; eine solche Weitergabe kann bspw. im Falle von
              Angriffen auf unsere IT-Systeme an staatliche Einrichtungen und
              Strafverfolgungsbehörden erfolgen.
            </Typography>
          </li>
        </ul>
        <Typography align="justify">
          Die weitergegebenen Daten dürfen von dem Dritten ausschließlich zu den
          genannten Zwecken verwendet werden.
          <br />
          <br />
          Wenn Sie sich für eine Veranstaltung angemeldet haben, kann es im
          Rahmen der Vertragserfüllung erforderlich sein, dass Ihre
          personenbezogenen Daten an einen externen Veranstalter übermittelt
          werden müssen. Im Zusammenhang mit einer Veranstaltungsanmeldung
          werden Sie darüber informiert, wer Veranstalter ist und ob es sich
          dabei um einen externen Veranstalter handelt. Dieser wird
          personenbezogenen Daten im Rahmen der Veranstaltung und insbesondere
          zur Teilnehmerverwaltung verarbeiten
          <br />
          <br />
          Eine Übermittlung von personenbezogenen Daten an ein Drittland
          (außerhalb der EU) oder eine internationale Organisation ist
          ausgeschlossen.
        </Typography>
        <br />
*/}
        <Typography variant="h6" align="left">
          <a id="cookies">3. Cookies</a>
        </Typography>
        <Typography align="justify">
          We use server-side cookies. Cookies are small files, which are automatically created by the browser
          of the user device and stored in your device (PC, laptop, tablet, smartphone or similar device)
          when you visit our website. Cookies do not harm your computer, and they do not contain viruses,
          Trojans or other malware.
          <br />
          <br />
          Cookies contain information pertaining to the specific device, which accessed our website.
          However, this does not give us direct knowledge of your identity.
          <br />
          <br />
          One reason for us to use cookies is making the use of our website more convenient for you.
          We use session cookies to allow session controls such as controls for inserting data into forms
          or saving shopping carts.
          At the latest, session cookies are deleted when you close your browser.
          <br />
          <br />
          We also use temporary cookies to optimise the user-friendliness of our website.
          Your device stores these cookies temporarily for a specific time.
          The next time you visit our website, our server will recognise your device as prior visitor
          and remembers your settings and preferences. You will not have to enter these parameters again.
          <br />
          <br />
          We also use cookies to gather data for our website statistics.
          This helps us to evaluate and optimise our web offerings (see Section 5).
          These cookies allow us to recognise repeat visits from your device.
          These cookies will be deleted automatically after a specific time.
          <br />
          <br />
          The data obtained with the help of cookies serve us to pursue our legitimate interests as website owners
          and serve the legitimate interests of third parties according to Article 6 para. 1, page 1 lit. f GDPR.
          <br />
          <br />
          Most browsers accept cookies automatically.
          However, you are able to configure your browser in such a way that the application does not
          store cookies on your computer or always shows an alert before storing new cookies.
          The complete deactivation of cookies may prevent you from using all functions on our website.
        </Typography>
        <br />
        <Typography variant="h6" align="justify">
          <a id="webanalyse">4. Web analysis/Tracking </a>
        </Typography>
        <Typography variant="h6" align="justify">
          Matomo
        </Typography>
        <Typography align="justify">
          On our website, we use the open source service{' '}
          <Link href="https://matomo.org/" underline="always"> Matomo by InnoCraft Ltd </Link> in New Zealand
          to analyse the activities of our website users and to optimise our website and its
          content based on this analysis.
          In the process, we do not receive any information, which identifies our users.
          <br />
          <br />
          In context with using the Matomo service, we use cookies,
          which enable the service to analyse the activities of our website users.
          The cookie contains information, including personal information, which characterises
          your visit on our website. Under a pseudonym,
          Matomo creates your user profile for analytical purposes.
          Since we host the Matomo services on our own server,
          the analysis does not require the processing of data by third parties.
          <br />
          <br />
          Without your specific permission, we do not use the collected data to identify you personally
          and will not combine the data with personal data about you under a pseudonym associated with you.
          <br />
          <br />
          To the extent that we collect IP addresses in our analysis, these addresses are stripped of their last
          control number block upon collection to anonymise the IP addresses instantly.
          We delete further personal data stored in the cookie after 13 months.
          <br />
          <br />
          We process the statistical data based on our legitimate interests in the optimisation of our online
          offerings and our web presence according to Article 6 para.1 lit. f GDPR.
          <br />
          <br />
        </Typography>
        <iframe
          style={{ border: 0, height: '200px', width: '600px' }}
          src="https://analytics.vx.igd.fraunhofer.de/index.php?module=CoreAdminHome&action=optOut&language=en&backgroundColor=&fontColor=ffffff&fontSize=&fontFamily="
        ></iframe>
        {/*
            Ihr Besuch dieser Webseite wird aktuell von der Matomo Webanalyse
            erfasst. Klicken Sie{" "}
            <Link href="https://matomo.org/docs/privacy/" underline="always"> hier </Link>, um zu
            erfahren, wie Ihr Besuch nicht mehr erfasst wird. */}

        <br />
        <Typography variant="h6" align="left">
          <a id="betroffenenrechte">5. Your Rights as Affected Person</a>
        </Typography>
        <Typography align="justify">You have the following rights:</Typography>
        <ul>
          <li>
            <Typography align="justify">
              According to Article 7 para. 3 GDPR, you have the right to revoke any consent declaration at any time,
              which you may have given to us before.
              This has the consequence that we are no longer privileged to continue the respective activity.
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              According to Article 15 GDPR, you have the right to demand information on your personal data,
              which we have processed. In particular, you have the right to information on the following:
              <br />
              Purposes of the data processing
              <br />
              The categories of personal data,
              <br />
              The categories of recipients to which we transmitted/disclosed or will transmit your data
              <br />
              The planned storage periods of data
              <br />
              The existence of the right to correction, deletion, restriction of processing and objection
              <br />
              The right to appeal
              <br />
              The right to know the origin of data in the event that we did not collect these data
              <br />
              The right to meaningful and detailed information on the existence on automated decision-making including profiling
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              According to Article 16 GDPR, you have the right to the correction of incorrect and/or the completion
              of incomplete personal data in storage at the Fraunhofer Society
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              According to  Article 17 GDPR, you have the right to the deletion of your personal
              data providing the deletion does not interfere with the execution of the right
              to the free expression of opinions and with the compliance with legal obligations,
              providing the deletion is not against public interest
              and providing the deletion does not hamper the enforcement, execution or defence of legal claims
              <br />
              Meldung, ob der Abruf erfolgreich war
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              According to Article 18 GDPR, you have the right to restrict the processing
              of your personal data if you contest or challenge the accuracy of these data,
              the processing of the data is illegal but you reject the deletion of these
              data and we no longer need the data while you still need the data to enforce, execute or defend legal
              claims or you have raised an objection against the data processing according to Article 21 GDPR
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              According to Article 20 GDPR, you have the right to receive the personal data you provided
              to us in a structured, well-established and machine-readable format or to demand the transmission
              to another authority and
            </Typography>
          </li>
          <li>
            <Typography align="justify">
              According to Article 77 GDPR, you have the right to complain to a superior authority.
              As a rule, you may find such authority at your place of residence, your workplace or our company domicile.
            </Typography>
          </li>
        </ul>

        <Typography variant="h6" align="left">
          <a id="widerspruch">
            6. Information on your Right of Objections according to Article 21 GDPR
          </a>
        </Typography>
        <Typography align="justify">
          You have the right to object at any time against the processing of your personal data,
          based on Article 6 para. 1, lit. f GDPR (data processing in the public interest)
          and Article 6, para. 1, lit. e GDPR (data processing based on fair balancing) for reasons,
          which arise from your specific situation.
          This shall also apply to the profiling as prescribed by Article 4 No. 4 GDPR,
          which is supported by this provision.
          <br />
          <br />
          Once you file an objection, we will no longer process your personal data unless
          we can establish compelling evidence, which is worthy of protection and outweighs
          your interests, rights and privileges, or unless the processing serves the enforcement,
          exercise or defence of legal claims.
          <br />
          <br />
          To the extent that your objection addresses the processing of data for direct advertising,
          we will stop the processing immediately. In this case,
          citing a special situation is not required. This shall also apply to profiling in
          as far as it does not relate to such direct advertising.
          <br />
          <br />
          If you like to claim your right to object, an email to{' '}
          <Link href="mailto:datenschutz@fraunhofer.de" underline="always">
            datenschutz@fraunhofer.de
          </Link> will suffice.
        </Typography>
        <br />

        <Typography variant="h6" align="left">
          <a id="datensicherheit">7. Data Security</a>
        </Typography>
        <Typography align="justify">
          We transmit all your personal data using the generally used and secure encryption standard TLS
          (Transport Layer Security). The TLS protocol is a proven and secure standard.
          Bankers use the standard for online banking transactions. You recognise a secure
          TLS connection by the s following the http (https://...)
          in your browser URL or by the lock symbol in the lower section of your browser.
          <br />
          <br />
          By the way, we use suitable technical and organisational safety procedures to protect your data
          against inadvertent or wilful manipulation, partial or complete loss,
          destruction or against the unauthorised access by third parties.
          We constantly improve these security measures as the technology advances.
        </Typography>
        <br />

        <Typography variant="h6" align="left">
          <a id="aktualitaet">
            8. Timeliness of the Data and Amendments to this Data Protection Information
          </a>
        </Typography>
        <Typography align="justify">
          This data protection information as amended on October 2020 is currently applicable.
          <br />
          Due to improvements of our website and website offers or based on statutory or government standard,
          it may become necessary to amend this data protection information.
          You find the latest applicable data protection information by clicking the link on this website:{' '}
          <Link href="https://www.igd.fraunhofer.de/datenschutzinformation" underline="always">
            https://www.igd.fraunhofer.de/datenschutzinformation
          </Link>
          {' '}.
          You may read or print this updated and amended version at any time.
        </Typography>
        <br />

        <Typography variant="h6" align="left">
          <a id="klausel">9. Severability</a>
        </Typography>
        <Typography align="justify">
          Should individual provisions of this data protection declaration be
          or become invalid either in part or in its entirety or prove infeasible at any time,
          this shall not affect the remaining provisions of this data protection declaration.
          This shall apply accordingly to gaps in this declaration.
        </Typography>

        <br />
        <br />
        <br />
      </div>
    );
  }

};

export default Gdpr;