import React, { FC } from 'react';
import { useLocation, Link } from 'react-router-dom';

import {
  AppBar,
  Toolbar,
  Button,
  Tabs,
  Tab,
  Hidden,
  Drawer,
  Typography,
  Link as MUILink,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Menu, OpenInNew } from '@mui/icons-material';

import { FOOTER_LINK } from 'constants/link';
import LanguageSwitch from './LanguageSwitch';
import ThemeConfigComponent from './ThemeConfigComponent';
import LinkButton from './LinkButton';
import routeConfig from '@config/routes';
import useLanguageHook from 'containers/App/useLanguageHook';

const Header: FC = () => {
  const location = useLocation();
  const theme = useTheme();


  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const closeDrawer = () => setDrawerOpen(false);
  const pathname = '/' + location.pathname.toLowerCase().replaceAll('/', '');
  const { t } = useLanguageHook();

  return (
    <React.Fragment>
      <AppBar position="absolute" color="primary" sx={{
        zIndex: theme.zIndex.drawer + 1,
        position: 'fixed',
        width: '100%',
        background: theme.palette.background.default,
        '@media print': {
          display: 'none'
        }
      }}>
        <Toolbar variant="dense">
          <Hidden mdDown>{renderDesktopTabs()}</Hidden>
          <Hidden mdUp>
            <Button size="large" onClick={() => setDrawerOpen(!drawerOpen)}>
              <Menu />
            </Button>
            <Typography sx={{
              paddingLeft: '1em',
              textTransform: 'initial'
            }}>TransparencyVis</Typography>
            <Drawer
              sx={{
                width: '300px',
                zIndex: theme.zIndex.drawer + 2
              }}
              anchor={'top'}
              open={drawerOpen}
              onClose={closeDrawer}
            >
              {renderMobileDrawer()}
            </Drawer>
            <div style={{ flexGrow: 1 }} />
            <LanguageSwitch />
          </Hidden>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );

  function renderMobileDrawer() {
    return (
      <React.Fragment>
        {/*<LinkButton
          size="large"
          sx={{ color: theme.palette.text.primary }} 
          onClick={closeDrawer}
          to={routeConfig.root}
        >
          {/*<img src={Favicon} className={classes.icon}/>* /}
          {t.UI.About}
        </LinkButton>
        <LinkButton
          size="large"
          sx={{ color: theme.palette.text.primary }} 
          onClick={closeDrawer}
          to={routeConfig.vis}
        >
          <Typography className={classes.logo}>
            {t.UI.TransparencyVis}
          </Typography>
        </LinkButton>*/}
        {/*
      <LinkButton size="large" className={classes.whiteText} onClick={closeDrawer} to={routeConfig.about}>
        {t.UI.About}
      </LinkButton>
      */}
        {/*<LinkButton className={classes.whiteText} to={routeConfig.upload}>{appConfig.upload}</LinkButton>*/}
        {/*<div className={classes.flex} />*/}
        {/*<SettingsComponent />*/}
        <LinkButton
          size="large"
          sx={{ color: theme.palette.text.primary }}
          onClick={closeDrawer}
          to={routeConfig.imprint}
        >
          {t.UI.Imprint}
        </LinkButton>
        <LinkButton
          size="large"
          sx={{ color: theme.palette.text.primary }}
          onClick={closeDrawer}
          to={routeConfig.dsgvo}
        >
          {t.UI.PrivacyPolicy}
        </LinkButton>
        <Button
          size="large"
          sx={{ color: theme.palette.text.primary }}
          href={FOOTER_LINK}
          target="_blank"
          rel="noopener"
          disableRipple
        >
          &copy; {new Date().getFullYear()} Fraunhofer IGD
          <OpenInNew />
        </Button>
        {/*<ThemeConfigComponent />*/}
      </React.Fragment>
    );
  }

  function renderDesktopTabs() {
    return (
      <Tabs sx={{
        width: '100%',
        display: 'flex',
        '& div.MuiTabs-flexContainer': {
          width: '100%'
        }
      }}
      centered
      value={pathname === '/' ? '/visualization' : pathname}
      >
        {/*label={<div><img src={Favicon} className={classes.icon}/> TransparencyVis</div>}*/}
        {/*<Tab className={classes.whiteText} component={Link} label={t.UI.About} to={routeConfig.root} value={routeConfig.root} />
        <Tab className={classes.whiteText} component={Link} label={t.UI.TransparencyVis} to={routeConfig.vis} value={routeConfig.vis} />*/}
        <Tab sx={{ color: theme.palette.text.primary }} component={Link} label={t.UI.About} to={routeConfig.vis} value={'/visualization'} />
        {/*
        <Tab className={classes.whiteText} component={Link} label={t.UI.About} to={routeConfig.about} value={routeConfig.about} />
        */}
        <Tab label="" disabled
          sx={{
            flexGrow: 1,
            [theme.breakpoints.down('lg')]: { display: 'none' },
          }}
        />


        {/* <ThemeConfigComponent /> */}
        <LanguageSwitch />
        <Tab
          sx={{ color: theme.palette.text.primary }}
          component={Link}
          label={t.UI.Imprint}
          to={routeConfig.imprint}
          value={routeConfig.imprint}
        />
        <Tab
          sx={{ color: theme.palette.text.primary }}
          component={Link}
          label={t.UI.PrivacyPolicy}
          to={routeConfig.dsgvo}
          value={routeConfig.dsgvo}
        />
        <Tab
          value=""
          label={
            <Button
              component={MUILink}
              href={FOOTER_LINK}
              target="_blank"
              rel="noopener"
              disableRipple
            >
              &copy; {new Date().getFullYear()} Fraunhofer IGD
            </Button>
          }
        />
      </Tabs>
    );
  }
};

export default Header;
