import { Dispatch } from 'redux';
import { action } from 'typesafe-actions';

import { addNewData } from '@store/providers/actions';
import { ProviderDefinition, WorkerStatus, ProviderFile } from 'providers/types';
import dataGenerator from 'utils/dataGenerator';

import { ApplicationState } from '../index';
import { FileDropActionTypes } from './types';

//import ProviderParserWorker from 'worker-loader!providers/super.worker';

/**
* Triggers a file to be added to the store
*
*/
export function dropFile(files: File[]): any {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    dispatch(action(FileDropActionTypes.FILE_ADD, { file: files }));

    if (!getState().files.loading) {
      dispatch(processNextFile());
    }
  };
}

export function initWorker(): any {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    const currentWorker = getState().files.worker;
    if (currentWorker) {
      currentWorker.terminate();
    }
    const worker = new Worker(new URL('../../providers/super.worker', import.meta.url));

    console.log('[WORKER] Initialization: ', (worker !== undefined ? 'Successful' : 'Failure'));

    // attach Event Listener when worker responds with progress or success
    worker.addEventListener('message', (event: any) => {
      const type: WorkerStatus = event.data.type;
      const message = event.data.message;

      if (type === WorkerStatus.Provider) {
        dispatch(action(FileDropActionTypes.ITER_PROVIDER, message as ProviderDefinition));
      } else if (type === WorkerStatus.Progress) {
        dispatch(action(FileDropActionTypes.ITER_PROGRESS, message as number));
      } else if (type === WorkerStatus.Success) {
        console.log(`[WORKER] Successfully parsed ${message.data.length} files and ${getDataPointsLength(message.data)} data points inside ${message.name}.`);
        dispatch(addNewData(message.name, message.id, message.data as ProviderFile[]));
        dispatch(action(FileDropActionTypes.ITER_SUCCESS, message.name));
        dispatch(processNextFile());
      } else if (type === WorkerStatus.Failure) {
        console.error('[WORKER] ', message);
        dispatch(action(FileDropActionTypes.ITER_FAILURE, message as string));
        dispatch(processNextFile());
      } else if (type === WorkerStatus.Log) {
        console.log('[WORKER] ', message);
      } else if (type === WorkerStatus.Warn) {
        console.warn('[WORKER] ', message);
      }
    });
    dispatch(action(FileDropActionTypes.INIT_WORKER, worker));
  };
}

export function processNextFile(): any {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    if (getState().files.file.length > 0) {
      dispatch(action(FileDropActionTypes.ITER_START));
      const nextFile = getState().files.file[0];
      dispatch(action(FileDropActionTypes.FILE_POP));
      const worker = getState().files.worker;
      console.log('Parsing file: ' + nextFile.name + ' with worker: ' + worker);
      if (worker) {
        worker.postMessage({ type: WorkerStatus.Start, file: nextFile });
      } else {
        const msg = 'Worker is not initialized, if this persists, please try to reload the page.';
        dispatch(action(FileDropActionTypes.ITER_FAILURE, msg));
        dispatch(resetState());
      }
    }
  };
}

export function loadSampleDataset(): any {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    if (getState().files.loading) {
      return;
    }
    const id = getState().files.sampleDatasetId;
    const seed = id === 0 ? 5 : undefined;
    const filename = 'sample_data_' + id;
    const lang = getState().language.language;
    console.time('Generating Sample Dataset');
    dispatch(action(FileDropActionTypes.ITER_SUCCESS, filename));
    console.log('Generating Sample Dataset with settings, seed: ' + seed + ' and lang: ' + lang);
    const result = dataGenerator.generate(seed, lang);
    console.timeEnd('Generating Sample Dataset');
    console.log('Genereated new sample sample with ' + result.length + ' files and ' + getDataPointsLength(result) + ' data points.');
    dispatch(addNewData(filename, 'sample', result));
  };
}

export function resetState(): any {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    // Check if worker is currently running and terminate if it exists
    const { worker } = getState().files;
    if (worker) {
      worker.terminate();
    }
    dispatch(action(FileDropActionTypes.RESET_STATE));
    dispatch(initWorker());
  };
}

export function clearError(): any {
  return (dispatch: Dispatch) => {
    dispatch(action(FileDropActionTypes.CLEAR_ERROR));
  };
}

export function clearLastFilename(): any {
  return (dispatch: Dispatch) => {
    dispatch(action(FileDropActionTypes.CLEAR_FILENAME));
  };
}

function getDataPointsLength(data: ProviderFile[]) {
  return data.reduce((pv, cv) => pv + cv.data.length, 0);
}
