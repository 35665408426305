import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import useTranslations from "containers/App/useLanguageHook";

export type TimeDataPoint = { x: number; y: number };
export type TimeSeries = {
  category: string;
  color: string;
  points: TimeDataPoint[];
};

/**
 * Computes the linear scales for the x and y dimension for the given data and chart sizes
 */
function getScales(
  range: { rangeX: number[]; rangeY: number[] },
  chartWidth: number,
  chartHeight: number
) {
  return {
    xScale: d3.scaleTime().domain(range.rangeX).range([0, chartWidth]).nice(),
    yScale: d3
      .scaleLinear()
      .domain(range.rangeY)
      .range([chartHeight, 0])
      .nice(),
  };
}

const redLineDate = new Date(2023, 0, 25);

/**
 * Returns the data ranges (min/max) for the x and y dimension
 */
function computeDataRange(data: TimeSeries[]): {
  rangeX: number[];
  rangeY: number[];
} {
  if (data.length === 0) {
    throw new Error("Input data array is empty");
  }
  let minX = data[0].points[0].x;
  let maxX = data[0].points[0].x;
  let minY = data[0].points[0].y;
  let maxY = data[0].points[0].y;

  for (const timeSeries of data) {
    for (const point of timeSeries.points) {
      if (point.x < minX) {
        minX = point.x;
      }
      if (point.x > maxX) {
        maxX = point.x;
      }
      if (point.y < minY) {
        minY = point.y;
      }
      if (point.y > maxY) {
        maxY = point.y;
      }
    }
  }
  return {
    rangeX: [minX, maxX],
    rangeY: [minY, maxY],
  };
}

interface ComponentProps {
  id?: string;
  data: TimeSeries[];
  width: number;
  height: number;
}

const margin = { top: 10, right: 20, bottom: 20, left: 40 };
const formatTime = d3.timeFormat("%B %Y");

/**
 * Returns the width, height of the actual chart drawing area (total SVG size minus margins)
 */
function getChartSize(props: ComponentProps) {
  return {
    chartWidth: props.width - margin.left - margin.right,
    chartHeight: props.height - margin.top - margin.bottom,
  };
}

const BrushableMultiLineChart = (props: ComponentProps) => {
  const range = React.useMemo(() => computeDataRange(props.data), [props.data]);
  const { t } = useTranslations();
  const { chartWidth, chartHeight } = React.useMemo(
    () => getChartSize(props),
    [props]
  );
  const { xScale, yScale } = React.useMemo(
    () => getScales(range, chartWidth, chartHeight),
    [range, chartWidth, chartHeight]
  );
  const d3ScaleX = useRef<d3.ScaleTime<number, number, never>>(xScale);
  const d3Brush = useRef<d3.BrushBehavior<unknown> | null>(null);
  const d3Container = useRef<SVGSVGElement>(null);

  /**
   * Callback function that is called when a mouse-brush selection is completed
   */
  function onBrushEnd(event: d3.D3BrushEvent<unknown>) {
    // Define type to 1D range (number[])
    const extent = event.selection as number[] | null;
    if (extent) {
      if (d3Container.current) {
        const svg = d3.select(d3Container.current);
        // This removes the grey brush area as soon as the selection has been done
        // Remove this if you want the selection area to persist
        const brushEle = svg.select<SVGGElement>(".brush");
        if (d3Brush.current) {
          d3Brush.current.clear(brushEle);
        }
      }
      if (d3ScaleX.current) {
        const selection = [
          d3ScaleX.current.invert(extent[0]),
          d3ScaleX.current.invert(extent[1]),
        ];
        d3ScaleX.current = d3ScaleX.current.domain(selection);
        updateChart();
      }
    } else {
      // Selection reset
      if (event.sourceEvent) {
        d3ScaleX.current = getScales(range, chartWidth, chartHeight).xScale;
        updateChart();
      }
    }
  }

  function updateChart() {
    if (d3Container.current && d3ScaleX.current) {
      const svg = d3.select(d3Container.current);
      // Update time series data
      const timeSeriesGroup = svg.select(".timeSeries");

      const timeSeriesPaths = timeSeriesGroup
        .selectAll<SVGGElement, TimeSeries>("path")
        .data(props.data, (datum) => datum.category);

      updatePaths(timeSeriesPaths, d3ScaleX.current, yScale);

      // Update X Axis
      svg
        .select<SVGGElement>(".xAxis")
        .transition()
        .duration(350)
        .call(d3.axisBottom(d3ScaleX.current));
    }
  }

  function drawRedline() {
    if (d3Container.current) {
      const svg = d3.select(d3Container.current);

      svg
        .select<SVGGElement>(".last-evaluation-line")
        .transition()
        .duration(350)
        .attr("transform", `translate(${d3ScaleX.current(redLineDate)}, 0)`);

      svg
        .select<SVGTextElement>(".evaltext")
        .attr("fill", "#fff")
        .attr("y", 8)
        .attr("x", 4)
        .attr("font-family", '"Fira Sans Condensed"')
        .text(t.SurveyFinal.lastEval);

      svg
        .select<SVGRectElement>(".redline")
        .attr("fill", "#f00")
        .attr("opacity", 0.8)
        .attr("shape-rendering", "crisp-edges")
        .attr("pointer-events", "none")
        .attr("height", chartHeight)
        .attr("width", 2);
    }
  }

  /**
   * This useEffect block is run only once after the component mounts. We will do the initial D3 setup here.
   */
  useEffect(
    () => {
      if (d3Container.current) {
        const svg = d3.select(d3Container.current);

        // Add the chart drawing area (group with margin translations)
        const svgChart = svg
          .append("g")
          .attr("transform", `translate(${margin.left}, ${margin.top})`);

        // Add group that will later hold the time series paths
        svgChart.append("g").attr("class", "timeSeries");

        const last = svgChart
          .append("g")
          .attr("class", "last-evaluation-line")
          .attr("transform", `translate(${d3ScaleX.current(redLineDate)}, 0)`);
        last
          .append("rect")
          .attr("class", "redline")
          .attr("fill", "#f00")
          .attr("opacity", 0.8)
          .attr("shape-rendering", "crisp-edges")
          .attr("pointer-events", "none")
          .attr("height", chartHeight)
          .attr("width", 1);
        last.append("text").attr("class", "evaltext");

        // Create and add brushing
        const brush = d3
          .brushX<unknown>()
          .extent([
            [0, 0],
            [chartWidth, chartHeight],
          ])
          .on("end", onBrushEnd);

        svgChart.append("g").attr("class", "brush").call(brush);

        // Store brush in ref, so we can access it in onBrushEnd function
        d3Brush.current = brush;

        // Add X axis
        svgChart
          .append("g")
          .attr("class", "xAxis")
          .attr("transform", `translate(0, ${chartHeight})`)
          .call(
            d3
              .axisBottom(d3ScaleX.current)
              .tickFormat((d) => formatTime(d as Date))
          );

        // Add Y axis
        svgChart.append("g").attr("class", "yAxis").call(d3.axisLeft(yScale));
      }
    },
    /* The empty dependency array of useEffect. This block will run only once after mount. */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * This useEffect block is run every time the data or component sizes change. We will do every chart updates here.
   */
  useEffect(
    () => {
      if (d3Container.current && d3ScaleX.current) {
        const svg = d3.select(d3Container.current);

        // Update X Axis
        svg
          .select<SVGGElement>(".xAxis")
          .attr("transform", "translate(0," + chartHeight + ")")
          .call(d3.axisBottom(d3ScaleX.current));

        // Update Y Axis
        svg.select<SVGGElement>(".yAxis").call(d3.axisLeft(yScale));

        // Update time series data
        const timeSeriesGroup = svg.select(".timeSeries");

        const timeSeriesPaths = timeSeriesGroup
          .selectAll<SVGGElement, TimeSeries>("path")
          .data(props.data, (datum) => datum.category);

        updatePaths(timeSeriesPaths, d3ScaleX.current, yScale);

        // Update brush
        if (d3Brush.current) {
          d3Brush.current
            .extent([
              [0, 0],
              [chartWidth, chartHeight],
            ])
            .on("end", onBrushEnd);

          svg.select<SVGGElement>(".brush").call(d3Brush.current);
        }
      }
    },
    /* The dependency array of useEffect. This block will run every time the input data or size change */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [d3Brush.current, props.data, props.height, props.width]
  );

  function updatePaths(
    paths: d3.Selection<SVGGElement, TimeSeries, d3.BaseType, unknown>,
    xScale: d3.ScaleTime<number, number, never>,
    yScale: d3.ScaleLinear<number, number, never>
  ) {
    drawRedline();
    const lineFunc = d3
      .line<TimeDataPoint>()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y));

    // Enter new elements
    paths
      .enter()
      .append("path")
      .attr("fill", "none")
      .attr("stroke", (d) => d.color)
      .attr("stroke-width", 1.0)
      .attr("d", (d) => lineFunc(d.points));

    // Update existing elements
    paths
      .transition()
      .duration(350)
      .attr("stroke", (d) => d.color)
      .attr("d", (d) => lineFunc(d.points));

    // Remove old elements
    paths
      .exit()
      .attr("opacity", 1)
      .transition()
      .duration(350)
      .attr("opacity", 0)
      .remove();
  }

  return (
    <svg
      id={props.id}
      width={props.width}
      height={props.height}
      ref={d3Container}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    />
  );
};

export default BrushableMultiLineChart;
