import { Reducer, AnyAction } from 'redux';
import { ApplicationLayoutState, ApplicationLayoutActionTypes, ThemeColors } from './types';

// Initial state
const initialState: ApplicationLayoutState = {
  type: 'dark',
  primaryColor: ThemeColors.lightGreen,
  secondaryColor: ThemeColors.blue,
};

// The reducer function
const reducer: Reducer<ApplicationLayoutState> = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ApplicationLayoutActionTypes.SET_THEME: {
      return Object.assign({}, state, {type: action.payload});
    }
    case ApplicationLayoutActionTypes.SET_PRIMARY: {
      return Object.assign({}, state, {primaryColor: action.payload});
    }
    case ApplicationLayoutActionTypes.SET_SECONDARY: {
      return Object.assign({}, state, {secondaryColor: action.payload});
    }

    default: {
      return state;
    }
  }
};

export { reducer as applicationLayoutReducer };