import { Item } from 'store/selection/types';
import { VisTypes } from 'constants/vis';

// State definition
export interface OptionsState {
  options: Item[],

  currentVis: VisTypes,
  openDrawerOnce: boolean,
  sidebarOpen: boolean,
  searchString: string,

  // Enable Mental Models Visualizations
  mentalModels: boolean,
  // Enable Export dataset feature
  enableExports: boolean,
  // Set the generate dataset feature to a fixed seed
  fixedDataset: boolean,

  // version of optionstate, increase to delete older stores that are saved in persistent state
  version: number
}

export function isOptionsState(obj: unknown): obj is OptionsState {
  return obj !== null && Array.isArray((obj as OptionsState).options) &&
    typeof (obj as OptionsState).openDrawerOnce === 'boolean' &&
    typeof (obj as OptionsState).searchString === 'string' && 
    typeof (obj as OptionsState).mentalModels === 'boolean' &&
    typeof (obj as OptionsState).version === 'number';
}

// Action types definition
export enum OptionsActionTypes {
  SWITCH_VIS = '@@options/SWITCH_VIS',
  SELECT = '@@options/SELECT',
  TOGGLE = '@@options/TOGGLE',
  SEARCH = '@@options/SEARCH',
  SIDEBAR = '@@options/SIDEBAR',
  EXPORTS = '@@options/EXPORTS',
  MENTALMODELS = '@@options/MENTALMODELS',
  FIXEDDATASET = '@@options/FIXEDDATASET',
}