import { combineReducers, Dispatch, Action, AnyAction } from 'redux';

import { applicationLayoutReducer } from './applicationLayout/reducer';
import { ApplicationLayoutState } from './applicationLayout/types';

import { fileReducer } from './files/reducer';
import { FileState } from './files/types';

import { providerReducer } from './providers/reducer';
import { ProviderState } from './providers/types';

import { selectionReducer } from './selection/reducer';
import { SelectionState } from './selection/types';

import { optionsReducer } from './options/reducer';
import { OptionsState } from './options/types';

import { languageReducer } from './language/reducer';
import { LanguageState } from './language/types';

import { categoriesReducer } from './categories/reducer';
import { CategoriesState } from './categories/types';

import { trackingReducer } from './tracking/reducer';
import { trackingState } from './tracking/types';


// The top-level store state definition
export interface ApplicationState {
  applicationLayout: ApplicationLayoutState;
  files: FileState;
  options: OptionsState;
  provider: ProviderState;
  categories: CategoriesState;
  language: LanguageState;
  selection: SelectionState;
  tracking: trackingState;
}

// Additional props for connected React components. This prop is passed by default with `connect()`
export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

// Setup root reducer from split reducer functions
export const rootReducer = () => combineReducers<ApplicationState>({
  applicationLayout: applicationLayoutReducer,
  files: fileReducer,
  provider: providerReducer,
  selection: selectionReducer,
  language: languageReducer,
  categories: categoriesReducer,
  tracking: trackingReducer,
  options: optionsReducer,
});