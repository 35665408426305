import React, { FC, useEffect } from 'react';
import * as d3 from 'd3';

import { Category, DataPoint } from 'providers/types';
import { catColor } from 'constants/color';
import { D3BrushEvent } from 'd3';

export interface HistogramSettings {
  width: number,
  height: number,
  padding: { left: number, top: number, right: number, bottom: number },
  bins: d3.TimeInterval | number,
  transitionDuration: number,
  xAxesHidden: boolean,
  yAxesHidden: boolean,
  yAxisTicks: number,
  enableBrushing: boolean
}

export const HistogramSettingsDefaults: HistogramSettings = {
  width: 1024,
  height: 140,
  padding: { left: 40, top: 10, right: 30, bottom: 20 },
  bins: d3.timeMonth.every(3) || d3.timeMonth,
  transitionDuration: 300,
  xAxesHidden: false,
  yAxesHidden: false,
  yAxisTicks: 4,
  enableBrushing: false
};

type CategorizedBin = {
  values: { [key in Category]: number },
  x0: Date,
  x1: Date
}

// Component-specific props.
interface ComponentProps {
  data: DataPoint[],
  settings: Partial<HistogramSettings>,
  onBrush?: {
    (selection: [Date, Date] | null): void
  }
}

const Histogram: FC<ComponentProps> = (props: ComponentProps) => {
  const xAxisElement = React.createRef<SVGGElement>();
  const yAxisElement = React.createRef<SVGGElement>();
  const brushElement = React.createRef<SVGGElement>();

  const settings = getSettings();

  const chartWidth = settings.width - settings.padding.left - settings.padding.right;
  const chartHeight = settings.height - settings.padding.top - settings.padding.bottom;

  const timeDomain = d3.extent(props.data, d => d.date);

  const xScale = d3.scaleTime<number, number>()
    .domain([
      timeDomain[0] || new Date(Date.now() - 365 * 24 * 60 * 60 * 1000),
      timeDomain[1] || new Date()
    ])
    .range([0, chartWidth]);

  const [yScaleDomain, setYScaleDomain] = React.useState([0, chartHeight]);

  const yScale = d3.scaleLinear<number, number>()
    .domain(yScaleDomain)
    .range([chartHeight, 0]);

  const brush = d3.brushX()
    .on('end', (e) => onBrush(e))
    .extent([[0, 0], [chartWidth, chartHeight]]);

  const [stackedBars, setStackedBars] = React.useState<d3.Series<CategorizedBin, string>[]>([]);

  useEffect(() => {
    generateData();
    drawAxis();
  }, [props.data]);

  useEffect(() => {
    attachBrushing();

    if (settings.enableBrushing) {
      if (brushElement.current) {
        d3.select(brushElement.current)
          .call(brush.move, [0, settings.width - settings.padding.left - settings.padding.right]);
      }
    }
  }, []);

  function generateData() {

    // define histogram generator
    const histogram = d3.bin<DataPoint, Date>()
      .value(d => new Date(d.date))
      .thresholds(xScale.ticks(settings.bins as any));

    // generate bins
    const bins = histogram(props.data);

    // count data points per category in each bin
    const binsCategorized: Array<CategorizedBin> = bins.map(b => ({
      values: b.reduce(
        (acc: any, val: DataPoint) => (val.category in acc) ?
          { ...acc, [val.category]: acc[val.category] + 1 } :
          { ...acc, [val.category]: 1 },
        {}
      ),
      x0: b.x0 || new Date(),
      x1: b.x1 || new Date()
    }));

    const stack = d3.stack<CategorizedBin>()
      .keys(Object.values(Category))
      .value((d, key) => d.values[key as Category] || 0)
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone);

    const stackedBars = stack(binsCategorized);

    setYScaleDomain([0, Math.max(...bins.map(b => b.length))]);
    setStackedBars(stackedBars);
  }

  function drawAxis() {
    if (!settings.xAxesHidden && xAxisElement.current)
      d3.select(xAxisElement.current).call(d3.axisBottom(xScale));

    if (!settings.yAxesHidden && yAxisElement.current)
      d3.select(yAxisElement.current).call(d3.axisLeft(yScale).ticks(settings.yAxisTicks));

  }

  function attachBrushing() {
    if (settings.enableBrushing && brushElement.current) {
      const brushElement2 = d3.select(brushElement.current);
      brushElement2.call(brush);

      brushElement2.selectAll('.selection')
        .attr('fill-opacity', 0.5)
        .attr('fill', '#eee')
        .attr('stroke', 'none');

      brushElement2.selectAll('.handle')
        .style('fill', '#7acc92');
    }
  }

  function getSettings(): HistogramSettings {
    return {
      ...HistogramSettingsDefaults,
      ...props.settings
    };
  }

  function onBrush(e: d3.D3BrushEvent<Date>) {
    // callback
    if (props.onBrush) {
      const [x0, x1]: [number, number] = e.selection ? e.selection as [number, number] : [0, chartWidth];
      const selection: [Date, Date] = [xScale.invert(x0), xScale.invert(x1)];
      props.onBrush(selection);
    }
  }

  return (
    <svg className="timeline-selector" width="100%" viewBox={`0 0 ${settings.width} ${settings.height}`}>
      <g transform={`translate(${[settings.padding.left, settings.padding.top]})`}>
        {(!settings.xAxesHidden) &&
          <g ref={xAxisElement} transform={`translate(${[0, settings.height - settings.padding.top - settings.padding.bottom]})`} />
        }
        {(!settings.yAxesHidden) &&
          <g ref={yAxisElement} />
        }
        {stackedBars.map((series, index) => (
          <g key={index} className="timeline-selector-series" fill={catColor(series.key)}>
            {series.map((rect, index) => (
              <rect
                key={index}
                x={xScale(rect.data.x0 || 0)}
                y={yScale(rect[1])}
                width={Math.max((xScale(rect.data.x1 || 0)) - (xScale(rect.data.x0 || 0)), 0)}
                height={Math.max((yScale(rect[0])) - (yScale(rect[1])), 0)}
              />
            ))}
          </g>
        ))}

        {(settings.enableBrushing) &&
          <g ref={brushElement} className="brush" />
        }
      </g>
    </svg>
  );
};

export default Histogram;