import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, LinearProgress, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import HighlightOff from '@mui/icons-material/HighlightOff';

import { useDropzone } from 'react-dropzone';

import { dropFile } from '@store/files/actions';
import { clearError } from '@store/files/actions';
import { ApplicationState } from 'store/index';
import useLanguageHook from 'containers/App/useLanguageHook';
import { FileOpen } from '@mui/icons-material';

const MyDropzone: FC = () => {
  const dispatch = useDispatch();
  const { t } = useLanguageHook();
  const theme = useTheme();

  const lastFilename = useSelector((state: ApplicationState) => state.files.lastFilename);
  const loading = useSelector((state: ApplicationState) => state.files.loading);
  const error = useSelector((state: ApplicationState) => state.files.error);
  const progress = useSelector((state: ApplicationState) => state.files.progress);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Do something with the files
    dispatch(dropFile(acceptedFiles));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    accept: {
      'application/zip': ['.zip'],
      'application/octet-stream': ['.zip'],
      'application/x-zip-compressed': ['.zip']
    }
  });

  return (
    <Box sx={{
      position: 'relative',
      width: '100%',
      overflow: 'hidden',
      minHeight: '200px'
    }}>
      <Box sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px',
        borderWidth: 3,
        borderRadius: 5,
        borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
        //backgroundColor: error ? theme.palette.error.light : theme.palette.primary.light,
        //color: theme.palette.primary.contrastText,
        borderStyle: 'dashed',
        position: 'relative',
        height: '100%',
        zIndex: 5,
        //color: theme.palette.primary.main,
        outline: 'none',
        cursor: 'pointer',
        transition: 'all .2s ease-in-out',
        minWidth: '400px',
        '&:hover': {
          //background: theme.palette.primary.dark,
          backgroundColor: theme.palette.background.paper
        },
        fontSize: '1.1em'
      }} {...getRootProps()}>
        <input {...getInputProps()} />
        {renderText()}
      </Box>
    </Box>
  );

  function renderText(): JSX.Element {
    if (isDragActive)
      return (
        <>
          <Typography>{t.Dropzone.Drop}.</Typography>
        </>
      );
    if (error)
      return (
        <>
          <Typography color="error">{t.Dropzone.Error} "{fileName()}"</Typography>
          <Typography color="warning">{error + ''} {renderErrorClear()}</Typography>
        </>
      );
    if (loading)
      return (
        <>
          <Typography>{t.Dropzone.Loading}</Typography>
          <Typography>{fileName()} {t.Dropzone.Processed}.</Typography>
          <LinearProgress sx={{ width: '100%', height: 18, borderRadius: 2 }}
            variant="determinate" value={progress} />
        </>
      );
    if (lastFilename !== '')
      return (
        <>
          <Typography>{t.Dropzone.Loaded}: {fileName()}</Typography>
          <Typography>{t.Dropzone.Add}.</Typography>
          <LinearProgress sx={{ width: '100%', height: 18, borderRadius: 2 }}
            variant="determinate" value={progress} />
        </>
      );
    return (
      <>
        <Typography color="default" variant="h4">
          {t.Dropzone.Enter1}
          {/*<Box sx={{
            background: theme.palette.primary.light,
            paddingLeft: "8px",
            paddingRight: "8px",
            borderRadius: "10%",
            display: "initial"
          }}>
            .zip
        </Box>*/}
          <br />
          {' '}<FileOpen />{t.Dropzone.Enter2}.
        </Typography>
        <br />
        <Typography variant="h4" dangerouslySetInnerHTML={{ __html: t.Dropzone.NoUpload }}></Typography>
      </>
    );
  }

  function fileName() {
    const name = lastFilename;
    if (name.length > 30)
      return name.slice(0, 30) + name.slice(-4);
    return name;
  }

  function renderErrorClear() {
    return <Button color="primary" onClick={(e) => {
      e.stopPropagation();
      dispatch(clearError());
    }}>
      <HighlightOff />
    </Button>;
  }

};

export default MyDropzone;
