import React, { FC, useEffect, } from 'react';
import { useSelector } from 'react-redux';
import * as d3 from 'd3';

import { DataPoint, ProviderFile } from 'providers/types';
import { ApplicationState } from '@store/index';
import Histogram from './histogram';

export interface TimelineSelectorSettings {
  width: number,
  height: number,
  padding: { left: number, top: number, right: number, bottom: number },
  brushWidth: number,
  brushHeight: number,
  brushPadding: { left: number, top: number, right: number, bottom: number },
  bins: d3.TimeInterval | number,
  binsMaxSize: d3.TimeInterval,
  transitionDuration: number
}

export const VisualizationSettingsDefaults: TimelineSelectorSettings = {
  width: 1024,
  height: 140,
  padding: { left: 40, top: 10, right: 30, bottom: 40 },
  brushWidth: 1024,
  brushHeight: 70,
  brushPadding: { left: 40, top: 10, right: 30, bottom: 40 },
  bins: 20, // d3.timeMonth
  binsMaxSize: d3.timeMonth.every(3) as d3.TimeInterval,
  transitionDuration: 300
};

// Component-specific props.
interface ComponentProps {
  settings: Partial<TimelineSelectorSettings>,
  onSelected?: (selection: [Date, Date] | null) => void
}

const TimelineSelector: FC<ComponentProps> = (props: ComponentProps) => {
  const settings = getSettings();
  const items = useSelector((state: ApplicationState) => state.provider.items);
  const categoryFilter = useSelector((state: ApplicationState) => state.categories.catColors);

  const data = getData();
  const [selection, setSelection] = React.useState<[Date, Date]>(getMinMax());

  // determine number of bins for histogram
  const [min, max]: [Date, Date] = selection;

  const bins = (settings.binsMaxSize.range(min, max).length >= settings.bins) ? settings.binsMaxSize : settings.bins;

  function getMinMax(): [Date, Date] {
    const datums = d3.extent(data, d => d.date);
    if (datums[0] && datums[1])
      return datums;
    return [new Date(Date.now() - 365 * 24 * 60 * 60 * 1000), new Date()];
  }

  function getSettings(): TimelineSelectorSettings {
    return {
      ...VisualizationSettingsDefaults,
      ...props.settings
    };
  }

  function getCategoryFilter() {
    return categoryFilter
      .filter(d => d.value)
      .map(d => d.id);
  }

  function getData(): DataPoint[] {
    const categoryFilter = getCategoryFilter();
    return items
      .reduce((acc: ProviderFile[], val) => val.active ? acc.concat(val.children) : acc, [])
      .reduce((acc: DataPoint[], val) => acc.concat(val.data.filter(d => categoryFilter.includes(d.category))), [])
      .sort((a, b) => a.date.getTime() - b.date.getTime());
  }

  function brushed(selection: [Date, Date] | null) {
    // apply selection
    if (selection && !isNaN(selection[0].getTime()) && !isNaN(selection[1].getTime())) {
      setSelection(selection);
      // callback
      if (props.onSelected) {
        props.onSelected(selection);
      }
    } else {
      const tmp = getMinMax();
      setSelection(tmp);
      // callback
      if (props.onSelected) {
        props.onSelected(tmp);
      }
    }
  }

  return (
    <div>
      <Histogram
        data={data.filter(d =>
          d.date.getTime() >= selection[0].getTime() &&
        d.date.getTime() <= selection[1].getTime())}
        settings={{
          width: settings.width,
          height: 120,
          bins: bins
        }}
      />
      <Histogram
        data={data}
        settings={{
          width: settings.width,
          height: 60,
          yAxesHidden: true,
          enableBrushing: true
        }}
        onBrush={(selection) => brushed(selection)}
      />
    </div>
  );
};

export default TimelineSelector;