import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

export type KeyValuePair = { key: string, value: number, color?: string };

/**
 * Computes the linear scales for the x and y dimension for the given data and chart sizes
 */
function getScales(data: KeyValuePair[], chartWidth: number, chartHeight: number) {
  const { keys, valueRange } = computeDataRange(data);

  return {
    xScale: d3.scaleBand().domain(keys).range([0, chartWidth]).padding(0.2),
    yScale: d3.scaleLinear().domain(valueRange).range([chartHeight, 0]).nice(),
  };
}

/**
 * Returns the data ranges (min/max) for the x and y dimension
 */
function computeDataRange(data: KeyValuePair[]): { keys: string[], valueRange: number[] } {
  if (data.length === 0) {
    throw new Error('Input data array is empty');
  }
  const keySet = new Set<string>();
  let maxValue = data[0].value;

  for (const point of data) {
    keySet.add(point.key);
    if (point.value > maxValue) { maxValue = point.value; }
  }
  return {
    keys: Array.from(keySet),
    valueRange: [0, maxValue],  // Always set 0 as min value
  };
}

type ComponentProps = {
  id?: string;
  data: KeyValuePair[];
  size: {
    width: number,
    height: number
  }
};

const margin = { top: 10, right: 20, bottom: 20, left: 60 };
const defaultColor = '#5FAF77';

function BarChart(props: ComponentProps) {
  /* The useRef Hook creates a variable that "holds on" to a value across rendering
     passes. In this case it will hold our component's SVG DOM element. It's
     initialized null and React will assign it later (see the return statement) */
  const d3Container = useRef<SVGSVGElement>(null);

  /** References to the brush object and xScale, so they can be accessed in the brush callback functions */
  const d3Brush = useRef<d3.BrushBehavior<unknown> | null>(null);
  const d3ScaleX = useRef<d3.ScaleBand<string> | null>(null);
  const { chartWidth, chartHeight } = getChartSize();
  const { xScale, yScale } = getScales(props.data, chartWidth, chartHeight);

  /**
   * Returns the width, height of the actual chart drawing area (total SVG size minus margins)
   */
  function getChartSize() {
    return {
      chartWidth: props.size.width - margin.left - margin.right,
      chartHeight: props.size.height - margin.top - margin.bottom,
    };
  }

  function getTransition() {
    return d3.transition().duration(150).ease(d3.easeLinear);
  }

  /**
   * This useEffect block is run only once after the component mounts. We will do the initial D3 setup here.
   */
  useEffect(() => {
    if (d3Container.current) {
      const svg = d3.select(d3Container.current);


      // Add the chart drawing area (group with margin translations)
      const svgChart = svg.append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      // Create group that will later hold the bars' <rect> elements
      svgChart.append('g')
        .attr('class', 'bars');

      // Add X axis
      svgChart.append('g')
        .attr('class', 'xAxis')
        .attr('transform', `translate(0, ${chartHeight})`)
        .call(d3.axisBottom(xScale));
      // Enable this, if you want 45° rotated axis markers
      // .selectAll("text")
      // .attr("transform", "translate(-10,0)rotate(-45)")
      // .style("text-anchor", "end");

      d3ScaleX.current = xScale;

      // Add Y axis
      svgChart.append('g')
        .attr('class', 'yAxis')
        .call(d3.axisLeft(yScale));
    }
  }, []);

  /**
   * This useEffect block is run every time the data or component sizes change. We will do every chart updates here.
   */
  useEffect(() => {
    if (d3Container.current) {
      const { xScale, yScale } = getScales(props.data, chartWidth, chartHeight);

      const svg = d3.select(d3Container.current);

      // Update X Axis (Note: instead of .select we could also store a ref for this object - likely more efficient)
      svg.select<SVGGElement>('.xAxis')
        .attr('transform', 'translate(0,' + chartHeight + ')')
        .attr('font-size', 26)
        .call(d3.axisBottom(xScale));

      // Update Y Axis
      svg.select<SVGGElement>('.yAxis')
        .attr('font-size', 26)
        .call(d3.axisLeft(yScale));
      // Enable this, if you want 45° rotated axis markers
      // .selectAll("text")
      // .attr("transform", "translate(-10,0)rotate(-45)")
      // .style("text-anchor", "end");

      d3ScaleX.current = xScale;

      // Update brush
      if (d3Brush.current) {
        d3Brush.current.extent([[0, 0], [chartWidth, chartHeight]]);

        svg.select<SVGGElement>('.brush')
          .call(d3Brush.current);
      }

      // Update data point rects (Note: instead of .select we could also store a ref for this object - likely more efficient)
      const barsGroup = svg.select<SVGGElement>('.bars');

      const bars = barsGroup
        .selectAll('rect')
        .data(props.data);

      // Enter new elements
      bars
        .enter()
        .append<SVGRectElement>('rect')
        .attr('shape-rendering', 'crispEdges')
        .attr('x', d => xScale(d.key) || null)
        .attr('y', d => yScale(d.value))
        .attr('height', d => chartHeight - yScale(d.value))
        .attr('width', xScale.bandwidth())
        .style('fill', d => d.color ?? defaultColor);

      // Update existing  elements
      bars
        .transition(getTransition())
        .attr('x', d => xScale(d.key) || null)
        .attr('y', d => yScale(d.value))
        .attr('height', d => chartHeight - yScale(d.value))
        .attr('width', xScale.bandwidth());

      // Remove old elements
      bars.exit()
        .remove();
    }
  }, [chartHeight, chartWidth, props.data, props.size]);

  return (
    <svg
      width={props.size.width}
      height={props.size.height}
      ref={d3Container}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    />
  );
}


export default BarChart;