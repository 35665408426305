import React from 'react';
import { Route, Routes } from 'react-router-dom';

import LandingPage from '@pages/LandingPage';
import VisPage from '@pages/VisPage';
import ImprintPage from '@pages/TemplatePages/ImprintPage';
import NotFoundPage from '@pages/TemplatePages/NotFoundPage';
import DSGVOPage from '@pages/TemplatePages/DSGVOPage';

import routeConfig from '@config/routes';

const Router: React.FC = () => {
  return <Routes>
    <Route path={routeConfig.vis} element={<VisPage />} />
    <Route path={routeConfig.imprint} element={<ImprintPage />} />
    <Route path={routeConfig.dsgvo} element={<DSGVOPage />} />
    <Route path={routeConfig.root} element={<LandingPage />} />
    <Route path='*' element={<NotFoundPage />} />
  </Routes>;
};

export default Router;
