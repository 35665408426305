import React, { FC, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { HierarchyRectangularNode } from 'd3';

import { ApplicationState } from '@store/index';
import { FileCategory, ProviderFile } from 'providers/types';
import { getProvider } from 'providers';
import { Item } from '@store/selection/types';
import { removeDuplicates } from '@utils/array';
import Treemap from './treemap';

export interface FileSettings {
  width: number;
  height: number;
  margin: number;

  size: string;
  color: string;
  filesize: boolean;
  leftSidebarOpen: boolean;

  groupingSize: boolean;
  private: boolean;
  tooltipWidth: number;
  showImages: boolean;
  showFolderHierarchy: boolean;
}

const defaultSettings = {
  width: 1300,
  height: 800,
  size: 'uncompressed',
  color: 'fileType',
  filesize: true,
  leftSidebarOpen: false,
  groupingSize: false,
  private: false,
  tooltipWidth: 400,
  showImages: false,
  margin: 20,
  showFolderHierarchy: false,
};

export interface FilePiece extends ProviderFile {
  val: number;
  category: string;
  files: string[];
  sum: boolean;
  datacategories: [string, number][];
  datalength: number;
  relPath: string;
  id: number;
}

export type Selection = (name: string, action: string, value: any) => void;

interface ComponentProps {
  selected: Item[];
  settings: Partial<FileSettings>;
  selection: Selection;
}

export const getFileSize = (num: number): string => {
  if (num < 1024) return Math.floor(num) + ' Bytes';
  num = num / 1024;
  if (num < 1024) return Math.floor(num * 10) / 10 + ' KB';
  num = num / 1024;
  if (num < 1024) return Math.floor(num * 10) / 10 + ' MB';
  num = num / 1024;
  return Math.floor(num * 10) / 10 + ' GB';
};

const FileView: FC<ComponentProps> = (props: ComponentProps) => {
  const providerItems = useSelector((s: ApplicationState) => s.provider.items);
  const fileFilter = useSelector((s: ApplicationState) => s.categories.fileColors);
  const settings = Object.assign({}, defaultSettings, props.settings);

  /**
   * Supposed size in the treemap based on applied settings and
   * filters.
   * @param f File element to calculate the size from
   * @returns Numeric size value
   */
  const getValue = (f: ProviderFile): number => {
    if (f.isDir) return 0;
    switch (settings.size) {
      case 'length':
        return f.data.length;
      case 'min':
        return Math.max(Math.min(f.data.length, 1000), 10);
      case 'uncompressed':
        return f.uncompressedSize;
      case 'log':
        return f.data.length === 0 ? 0 : Math.log(f.data.length);
      case 'compressed':
        return f.compressedSize;
      case 'images':
        return f.fileType === FileCategory.Picture ? 1 : 0;
      case 'evenly':
      default:
        return 1;
    }
  };

  /**
   * Extends providerfile in order to create FilePiece.
   * @param f ProviderFile
   * @param id id of the ProviderFile/FilePiece
   * @returns FilePiece
   */
  const mkFilePiece = (f: ProviderFile, id: number): FilePiece => ({
    ...f,
    id,
    val: getValue(f),
    category: f.fileType,
    files: [f.groupBy === 'folder' ? f.folder : f.fileName],
    sum: false,
    datacategories: [f.data[0] && [f.data[0].subcategory, f.data.length]], // TODO: Expect files to only have one data category
    relPath: f.path,
    datalength: f.data.length,
  });

  /**
   * Returns array of FilePieces to display in treemap.
   * Applies filters to FilePieces.
   */
  const data = useMemo(() => {
    const cats = fileFilter.filter((d) => d.value).map((d) => d.id);
    console.log(cats);
    return providerItems
      .filter((d) => d.active)
      .reduce(
        (acc, val) =>
          acc.concat(
            val.children.map((d) => ({
              ...d,
              /* path: {ZIP-filename}/{d.path without d.filename} */
              path: `${val.filename}/${d.path.substring(
                0,
                d.path.length - (d.fileName.length + 1)
              )}`,
            }))
          ),
        Array<ProviderFile>()
      )
      .filter((d) => cats.includes(d.fileType) || cats.includes(d.dataCategory))
      .map<FilePiece>((f, id) => mkFilePiece(f, id))
      .filter((f) => f.val > 0);
  }, [providerItems, fileFilter, settings.size]);

  /**
   * List of FilePieces to render in a flat treemap.
   */
  const fileList = useMemo(() => {
    const d = data;

    const con: FilePiece[] = [];

    const max = d.reduce((prev, cur) => (prev > cur.val ? prev : cur.val), 0);
    const minElemSize = Math.max(0, max * (settings.groupingSize ? 0.02 : 0.0));

    for (let i = 0; i < d.length; i++) {
      if (d[i].val < minElemSize) {
        const groupIx = con.findIndex(
          (_, j) =>
            (d[i].groupBy === 'root' &&
              con[j].providerId === d[i].providerId &&
              con[j].category === d[i].category &&
              con[j].sum) ||
            (d[i].groupBy === 'folder' &&
              con[j].folder === d[i].folder &&
              con[j].fileType === d[i].fileType &&
              con[j].sum)
        );

        if (groupIx > -1) {
          // Add to existing group
          con[groupIx].val += d[i].val;
          con[groupIx].files.push(d[i].path);
        } else {
          // Create new group
          con.push({ ...d[i], sum: true });
        }
      } else {
        con.push(d[i]);
      }
    }
    return con;
  }, [settings.groupingSize, data]);
  /**
   * List of FilePieces to render in an hierarchical treemap.
   * Adds a "treemap" root node which ought not be rendered an
   * a FilePiece for each directory found.
   */
  const hierarchyData = useMemo(() => {
    const d = data;

    const dirs = removeDuplicates(
      d.reduce(
        (arr, f) => {
          const p = f.path.split('/');
          return [
            ...arr,
            ...[getProvider(f.providerId).name, ...p].reduce<
              Array<[string, string]>
            >(
              (path, el: string) => [
                ...path,
                [
                  getProvider(f.providerId).name,
                  `${path[path.length - 1]?.[1] ?? 'treemap'}/${el}`,
                ],
              ],
              []
            ),
          ];
        },
        [['', 'treemap']]
      )
    ).map((path, id) =>
      mkFilePiece(
        {
          id: id,
          itemId: 0,
          providerId: path[0],
          compressedSize: 0,
          fileName: path[1].substring(path[1].lastIndexOf('/') + 1),
          fileType: FileCategory.Other,
          dataCategory: '',
          folder: '',
          hash: '',
          isDir: true,
          path: path[1],
          data: [],
          uncompressedSize: 0,
          groupBy: '',
          date: new Date(),
          binaryData: null,
          mimeType: 'unknown/unknown'
        },
        id
      )
    );

    const res = [
      ...dirs,
      ...d
        .map((f) => ({
          ...f,
          path: `treemap/${getProvider(f.providerId).name}/${f.path}/${f.fileName
          }`,
        }))
        .map((f) => ({
          ...f,
        })),
    ];
    return res;
  }, [data]);


  const treemap = useMemo(
    () => (
      <Treemap
        settings={settings}
        hierarchyData={hierarchyData}
        fileList={fileList}
      />
    ),
    [settings, hierarchyData, fileList]
  );

  return (
    <div>
      {data.length > 0 ? (treemap) : (
        <p
          style={{
            transform: 'translate(-50%,-50%)',
            position: 'absolute',
            top: '50%',
            left: '50%',
            fontSize: '2.2em',
            textAnchor: 'middle',
          }}
        >
          No Data found. Select your data export or use the demo dataset.
        </p>
      )}
    </div>
  );
};

export default FileView;
