import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Card, CardContent, CardHeader, Grid, Paper } from '@mui/material';

import * as d3 from 'd3';

import { ApplicationState } from '@store/index';
import { Category, DataPoint, ProviderFile } from 'providers/types';
import { catColor } from 'constants/color';
import SparkLine from './SparkLine';
import TimelineSelector from '../TimelineSelector';



export interface VisualizationSettings {
  width: number,
  height: number,
  spacing: number,
  cardHeight: number,
  bins: d3.TimeInterval,
}

export const VisualizationSettingsDefaults: VisualizationSettings = {
  width: 1024,
  height: 140,
  spacing: 8,
  cardHeight: 320,
  bins: d3.timeMonth.every(3) || d3.timeMonth
};

type CategorizedBin = {
  values: { [key: string]: number },
  date: Date
}

// Component-specific props.
interface ComponentProps {
  //data: ProviderFile[],
  settings: Partial<VisualizationSettings>
}

const SparkLines: FC<ComponentProps> = (props: ComponentProps) => {
  const categories = useSelector((state: ApplicationState) => state.categories.catColors);
  const items = useSelector((state: ApplicationState) => state.provider.items);

  const cats = categories.filter(d => d.value).map(d => d.id);
  const data = selector();

  const [selection, setSelection] = useState<[Date, Date] | null>(null);
  const [subCategories, setSubCategories] = useState<{ [key in Category]?: Set<string> }>({});
  const [binsCategorized, setBinsCategorized] = useState<CategorizedBin[]>([]);
  const settings = getSettings(props);
  const cardWidth = settings.width * 2 / Object.keys(Category).length - settings.spacing;

  useEffect(() => {
    updateData();
  }, [items.length, data.length, selection, settings.bins]);

  function selector() {
    return items
      .filter(d => d.active)
      .map(d => d.children)
      .reduce((acc, val) => acc.concat(val), [])
      .filter(d => cats.includes(d.dataCategory));
  }

  function getSettings(props: Partial<ComponentProps>): VisualizationSettings {
    return {
      ...VisualizationSettingsDefaults,
      ...props.settings
    };
  }

  function updateData() {
    const dataPoints = data.reduce(
      (acc: Array<DataPoint>, val: ProviderFile) => (val.data) ? acc.concat(val.data) : acc,
      Array<DataPoint>());

    //const dataSelected = (selection) ? dataPoints.filter(d => d.date >= selection[0] && d.date <= selection[1]) : dataPoints;
    const dataSelected = (selection) ?
      dataPoints.filter(d =>
        d.date.getTime() >= selection[0].getTime() &&
        d.date.getTime() <= selection[1].getTime()) : dataPoints;

    const [minDate, maxDate] = d3.extent(dataSelected, (d: DataPoint) => d.date);

    // setup x scale
    const xScale = d3.scaleTime()
      .domain([
        minDate || new Date(Date.now() - 365 * 24 * 60 * 60 * 1000),
        maxDate || new Date()
      ]);

    // define histogram generator
    const generator = d3.bin<DataPoint, Date>()
      .value(d => d.date)
      .thresholds(xScale.ticks(settings.bins));

    // generate bins
    const bins = generator(dataSelected);

    // count data points per subcategory in each bin
    const binsCategorized: Array<CategorizedBin> = bins.map(b => ({
      values: b.reduce(
        (acc: any, val: DataPoint) => (val.subcategory in acc) ?
          { ...acc, [val.subcategory]: acc[val.subcategory] + 1 } :
          { ...acc, [val.subcategory]: 1 },
        {}
      ),
      date: (b.x0 && b.x1) ? new Date(b.x0.getTime() + (b.x1.getTime() - b.x0.getTime()) / 2) : b.x0 as Date
    }));

    const subCategories = dataSelected.reduce((acc: any, d: DataPoint) => ({
      ...acc,
      [d.category]: (d.category in acc) ? acc[d.category].add(d.subcategory) : new Set([d.subcategory])
    }), {});


    setBinsCategorized(binsCategorized);
    setSubCategories(subCategories);
  }

  return (
    <div>
      <TimelineSelector
        settings={{
          width: props.settings.width,
          brushWidth: props.settings.width
        }}
        onSelected={(e) => setSelection(e)}
      />

      <Grid container justifyContent="space-between" style={{ margin: '2em 0' }}>
        {Object
          .values(Category)
          .map((cat: Category, index: number) => (
            <Grid key={index} item style={{ padding: `${settings.spacing}px 0` }}>
              <Card style={{ width: cardWidth, backgroundColor: catColor(cat) }}>
                <CardHeader title={cat} style={{ paddingTop: '0.8em', paddingBottom: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} />
                <CardContent style={{ height: `${settings.cardHeight}px`, overflowY: 'auto', scrollbarColor: `#eee ${catColor(cat)}` }}>
                  {(subCategories[cat] === undefined)
                    ?
                    <p style={{ textAlign: 'center', fontSize: '1.25em' }}>No data found</p>
                    :
                    Array.from(subCategories[cat] || []).map((sc: string, index: number) =>
                      <Paper key={index} style={{ padding: '4px', marginBottom: '10px', wordWrap: 'break-word' }}>
                        <span>{sc} ({binsCategorized.reduce((sum, c) => sum + (c.values[sc] || 0), 0)})</span>
                        <SparkLine
                          data={binsCategorized.map(c => ({ value: c.values[sc] || 0, date: c.date }))}
                          settings={{
                            lineColor: catColor(cat)
                          }}
                        />
                      </Paper>
                    )
                  }
                </CardContent>
              </Card>
            </Grid>
          ))
        }
      </Grid>
    </div>
  );
};

export default SparkLines;