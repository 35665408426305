import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Theme } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';

import { initWorker } from '@store/files/actions';
import DragAndDrop from '../Components/DragAndDrop';
import Header from './Header';
import Router from './Router';
import ThemeProvider from './ThemeProvider';
import ScrollToTop from './ScrollToTop';


const App: FC = () => {
  const dispatch = useDispatch();

  // initialize the worker thread
  useEffect(() => {
    dispatch(initWorker());
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <DragAndDrop>
          <Header />
          <main style={{ marginTop: '60px' }}>
            <Router />
            <ScrollToTop />
          </main>
          {/*<Footer />*/}
        </DragAndDrop>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;