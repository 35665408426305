
export const sidebarWidth = 240; // in px

export enum VisTypes {
  All = 'All',
  Provider = 'Provider',
  Files = 'Files',
  Timeline = 'Timeline',
  Details = 'Details',
  Sparklines = 'Sparklines',
  Piechart = 'Piechart',
  Sunburst = 'Sunburst',
  Export = 'Export',
  Finalization = 'Finalization'
}