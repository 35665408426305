import { DataPoint, UnparsedFile } from 'providers/types';

export default class Provider {

  /**
   * add files or folder names which are in the specific datadownload. They are used to recognize the service.
   */
  static fileStructure: string[] = [];

  /**
   * optional files which are used for matching
   */
  static optionalFileStructure: string[] = [];

  /**
   * Main File Parsing function for every provider.
   * Add rules for parsing each individual file and return a array of DataPoints.
   * 
   * @param path file path to file inside data export
   * @param data array with data as uint8 as any format, has to be parsed into json, csv, etc
   * @param warnCallback if any errors occur during parsing use warnCallback to send messages to the frontend
   * @returns array of DataPoints that were parsed from this file
   */
  static processFile(path: string, data: Uint8Array, warnCallback: (str: string) => void, getFile: (path: string) => undefined | UnparsedFile): DataPoint[] {
    return [];
  }
}