import translations from 'constants/translations.json';

// State definition
export interface LanguageState {
  language: LanguageTypes;
  supportedLangs: { name: LanguageTypes, text: string }[]
}

// Action types definition
export enum LanguageActionTypes {
  SET_LANGUAGE = '@@language/SET_LANGUAGE'
}

export type OptionLabelTypes = keyof typeof translations.en.Options;

// Additional definitions used in action objects
export type LanguageTypes = keyof typeof translations;