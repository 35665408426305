import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ListItem, ListItemButton, ListItemIcon, ListItemText, Theme } from '@mui/material';
import List from '@mui/material/List';
import Checkbox from '@mui/material/Checkbox';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

import { ApplicationState } from 'store/index';
import { ProviderDatum } from 'store/providers/types';
import { switchActive } from 'store/providers/actions';
import useTranslations from 'containers/App/useLanguageHook';

const FilesList: FC = () => {
  const items = useSelector((state: ApplicationState) => state.provider.items);
  const providers = useSelector((state: ApplicationState) => state.provider.providers);
  const dispatch = useDispatch();
  const { t } = useTranslations();

  return (
    <List dense component="div" disablePadding>
      {items.length === 0 ?
        <Box>
          <Typography sx={{ p: 1 }}>{t.Vis.DataExportsNonLoaded}</Typography>
        </Box>
        :
        items.map((d) => renderLoadedFile(d))
      }
    </List>
  );

  function shortFileName(name: string) {
    return (name.length > 20 ? name.substr(0, 15) + '...' + name.substr(-5) : name);
  }

  function renderLoadedFile(item: ProviderDatum) {
    return <ListItem key={item.id}>
      <ListItemButton role={undefined} onClick={() => dispatch(switchActive(item.id))} dense>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={item.active}
          />
        </ListItemIcon>
        <ListItemText primary={
          <Tooltip title={
            <Box>
              <Typography>TODO: Click to show or hide this Data Export</Typography>
              {/*
              <Typography><strong>Filename</strong>: {item.filename}</Typography>
              <Typography><strong>Files</strong>: {item.children.length}</Typography>
              <Typography><strong>Service</strong>: {providers.find(d => d.id === item.providerId)?.name || "Unknown"}</Typography>
              */}
            </Box>
          }>
            <Typography align="center" sx={{ overflowWrap: 'break-word', width: '100%' }}>
              {shortFileName(item.filename)}
            </Typography>
          </Tooltip>
        } />
      </ListItemButton>
    </ListItem>;
  }
};

export default FilesList;
