// State definition
export interface FileState {
  readonly file: File[];
  readonly loading: boolean;
  readonly error: string | null;
  readonly worker: Worker | undefined;

  readonly lastFilename: string;
  readonly progress: number;
  readonly sampleDatasetId: number;

}

// Action types definition
export enum FileDropActionTypes {
  INIT_WORKER = '@@files/INIT_WORKER',

  FILE_ADD = '@@files/FILE_DROP_ADD',
  FILE_POP = '@@files/FILE_POP',

  ITER_START = '@@files/FILE_DROP_START',
  ITER_FILES = '@@files/FILE_DROP_FILES',
  ITER_PROGRESS = '@@files/FILE_DROP_PROGRESS',
  ITER_PROVIDER = '@@files/FILE_DROP_PROVIDER',
  ITER_SUCCESS = '@@files/FILE_DROP_SUCCESS',
  ITER_FAILURE = '@@files/FILE_DROP_FAILURE',

  RESET_STATE = '@@files/RESET_STATE',
  CLEAR_ERROR = '@@files/CLEAR_ERROR',
  CLEAR_FILENAME = '@@files/CLEAR_FILENAME',
}