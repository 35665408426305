import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Grid } from '@mui/material';

import { ApplicationState } from '@store/index';
import useLanguageHook from 'containers/App/useLanguageHook';

const NotFoundPage: FC = () => {
  const { t } = useLanguageHook();
  const locationPath = window.location.pathname;

  return <>
    <div style={{ height: '60px' }} />
    <Grid container justifyContent="center" spacing={2} xs={12}>
      <Grid item xs={10} md={5}>
        <Typography variant="h4">
          <strong>Error 404</strong>
        </Typography>
        <hr />
        <Typography>
          {t.UI.NotFound}: {locationPath}
        </Typography>
      </Grid>
    </Grid>
  </>;

};


export default NotFoundPage;
