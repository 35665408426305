// State definition
export interface ApplicationLayoutState {
  readonly type: ThemeTypes;
  readonly primaryColor: ThemeColors;
  readonly secondaryColor: ThemeColors;
}

// Action types definition
export enum ApplicationLayoutActionTypes {
  SET_THEME = '@@applicationLayout/SET_THEME',
  SET_PRIMARY = '@@applicationLayout/SET_PRIMARY',
  SET_SECONDARY = '@@applicationLayout/SET_SECONDARY'
}

// Additional definitions used in action objects
export type ThemeTypes = 'light' | 'dark';

export enum ThemeColors {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
}

