import React, {  FC } from 'react';
import * as d3 from 'd3';

export interface VisualizationSettings {
  chartWidth: number,
  chartHeight: number,
  chartPadding: { left: number, top: number, right: number, bottom: number },
  lineColor: string,
  lineWidth: number,
  bins: d3.TimeInterval | number,
  yDomain?: [number, number]
}

export const VisualizationSettingsDefaults: VisualizationSettings = {
  chartWidth: 256,
  chartHeight: 32,
  chartPadding: { left: 2, top: 2, right: 2, bottom: 2 },
  lineColor: 'white',
  lineWidth: 1.5,
  bins: d3.timeMonth.every(3) || d3.timeMonth
};

type SparkLinePoint = {
  value: number,
  date: Date
}

// Component-specific props.
interface ComponentProps {
  data: SparkLinePoint[],
  settings: Partial<VisualizationSettings>
}

const SparkLine: FC<ComponentProps> = (props: ComponentProps) => {
  const settings = getSettings();

  const chartWidth = settings.chartWidth - settings.chartPadding.left - settings.chartPadding.right;
  const chartHeight = settings.chartHeight - settings.chartPadding.top - settings.chartPadding.bottom;

  const { xScale, yScale } = getScales(props.data, chartWidth, chartHeight);

  // setup line generator
  const lineGenerator = d3.line<SparkLinePoint>()
    .x(d => xScale(d.date))
    .y(d => yScale(d.value))
    .curve(d3.curveBasis);

  function getScales(data: SparkLinePoint[], chartWidth: number, chartHeight: number) {
    const { rangeX, rangeY } = computeDataRange(data);

    return {
      xScale: d3.scaleTime().domain(rangeX).range([0, chartWidth]),
      yScale: d3.scaleLinear<number>().domain(rangeY).range([chartHeight, 0])
    };
  }

  function computeDataRange(data: SparkLinePoint[]) {
    let minX = data[0].date;
    let maxX = data[0].date;
    let minY = data[0].value;
    let maxY = data[0].value;

    for (const point of data) {
      if (point.date < minX) { minX = point.date; }
      if (point.date > maxX) { maxX = point.date; }
      if (point.value < minY) { minY = point.value; }
      if (point.value > maxY) { maxY = point.value; }
    }
    return {
      rangeX: [minX, maxX],
      rangeY: [minY, maxY],
    };
  }

  function getSettings(): VisualizationSettings {
    return {
      ...VisualizationSettingsDefaults,
      ...props.settings
    };
  }

  return (
    <svg width="100%" viewBox={`0 0 ${settings.chartWidth} ${settings.chartHeight}`}>
      <g className="chart"
        transform={`translate(${[
          settings.chartPadding.left,
          settings.chartPadding.top
        ]})`} fill="grey">
        <path
          stroke={settings.lineColor}
          strokeWidth={settings.lineWidth}
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d={lineGenerator(props.data) || ''}
        />
      </g>
    </svg>
  );
};

export default SparkLine;