import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Stepper,
  Step,
  StepLabel,
  Modal,
  Stack,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ApplicationState } from '@store/index';
import { DataPoint, ProviderFile } from 'providers/types';
import { sidebarWidth, VisTypes } from 'constants/vis';
import * as optionsActions from '@store/options/actions';
import CollapseButton from '../Components/SidebarComponents/CollapseButton';
import DetailView from '../Components/DetailView';
import ExportView from 'containers/Components/ExportView';
import ProviderView from '../Components/ProviderView';
import SearchField from '../Components/SearchField';
import SidebarLeft from '../Components/SidebarComponents/SidebarLeft';
import SidebarRight from '../Components/SidebarComponents/SidebarRight';
import useTranslations from 'containers/App/useLanguageHook';

import Timeline from '../Visuals/Timeline';
import SparkLinesVis from '../Visuals/SparkLines';
import SunBurstVis from '../Visuals/SunBurst';
import { useWindowSize } from 'containers/hooks/useWindowSize';
import FinalizationView from 'containers/Components/FinalizationView';
import FileView from 'containers/Visuals/TreeMap';
import { track, trackVisited } from '@store/tracking/actions';
import { useInterval } from 'containers/hooks/useInterval';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const VisPage: React.FC = () => {
  const theme = useTheme();
  const { width, height } = useWindowSize();
  const dispatch = useDispatch();
  const { t } = useTranslations();
  const navigate = useNavigate();
  const isDevelopmentEnvironment = process.env.NODE_ENV === 'development';

  const timelineRef = React.useRef<undefined | HTMLDivElement>(undefined);

  const [stayOnPageModal, setStayOnPageModal] = useState(false);

  // URL query support
  const query = useQuery();

  const folderFilter = useSelector((state: ApplicationState) => state.selection.folderFilter);
  const options = useSelector((state: ApplicationState) => state.options.options);
  const searchString = useSelector((state: ApplicationState) => state.options.searchString);
  const sidebarOpen = useSelector((state: ApplicationState) => state.options.sidebarOpen);

  const providerItems = useSelector((state: ApplicationState) => state.provider.items);
  const categoryFilter = useSelector((state: ApplicationState) => state.categories.catColors);
  const currentVis = useSelector((state: ApplicationState) => state.options.currentVis);
  const visited = useSelector((state: ApplicationState) => state.tracking.visited);
  //const subColors = useSelector((state: ApplicationState) => state.categories.subColors);
  //const currentVis = useSelector((state: ApplicationState) => state.options.currentVis);

  // useEffect(() => {
  //   if (currentVis !== VisTypes.Provider && providerItems.length == 0) {
  //     navigate("/?tic=" + query.get("tic"));
  //   }
  // }, [currentVis, providerItems]);

  const tracking = useSelector((state: ApplicationState) => state.tracking);
  const timeToNextPageInSeconds = isDevelopmentEnvironment ? 1 : 20;
  const twoQuestionChecked = currentVis === VisTypes.Files ?
    (tracking.treemap.checkbox > 2) :
    (currentVis === VisTypes.Timeline ?
      (tracking.timeline.checkbox > 2) :
      true);

  const [startedTime, setStartedTime] = useState<number>(() => Date.now());
  const [timeOnPage, setTimeOnPage] = useState<number>(Infinity);
  const showSidebars = currentVis === VisTypes.Files || currentVis === VisTypes.Timeline;

  useEffect(() => setStartedTime(Date.now()), [currentVis]);
  useInterval(() => {
    setTimeOnPage(Math.ceil((Date.now() - startedTime) / 1000));
  }, 1000);

  function getSidebarSize() {
    return sidebarOpen ? getSidebarLeft() + getSidebarRight() : 0;
  }

  function getSidebarLeft() {
    return sidebarOpen ? sidebarWidth : 0;
  }

  function getSidebarRight() {
    return sidebarOpen ? 240 : 0;
  }

  function getVisSettings() {
    if (document.fullscreenElement) {
      return Object.assign(
        {
          width: width - 48 - getSidebarSize(),
          height: height - (40 + 56 + 8 + 24 + 37 + 24),
          //subcolormap: transformSubs(),
          leftSidebarOpen: false,
        },
        transformOptions()
      );
    }
    return Object.assign(
      {
        width: width - (48 + 8 + getSidebarSize()),
        height: height - (200 + 40 + 37 + 24),
        //subcolormap: transformSubs(),
        leftSidebarOpen: sidebarOpen,
      },
      transformOptions()
    );
  }

  function transformOptions() {
    //TODO: refactor this to a better place
    const res: { [label: string]: boolean | string | number | number[] } = {};

    options.forEach((d) => {
      if (d.kind === 'Toggle') res[d.id] = d.value;
      if (d.kind === 'Slider') res[d.id] = d.value;
      if (d.kind === 'Selector') res[d.id] = d.value;
      if (d.kind === 'Lasso' && d.value) res[d.id] = d.items;
      if (d.kind === 'Range' && d.value >= 0) res[d.id] = d.value;
    });
    return res;
  }

  function searchFilter(d: DataPoint): boolean {
    const search = searchString.toLowerCase();
    if (search === '') return true;

    return (
      d.text.toLowerCase().includes(search) ||
      d.category.toLowerCase().includes(search) ||
      d.subcategory.toLowerCase().includes(search)
    );
  }

  function getCategoryFilter(): (d: DataPoint) => boolean {
    const filter2 = categoryFilter.filter((d) => d.value).map((d) => d.id);
    return (d: DataPoint) => filter2.includes(d.category);
  }

  function generateTimelineData() {
    const cats = getCategoryFilter();
    return (
      providerItems
        .reduce(
          (acc: ProviderFile[], val) =>
            val.active ? acc.concat(val.children) : acc,
          []
        )
        //.filter(d => cats.includes(d.dataCategory))
        .reduce(
          (acc: DataPoint[], val) =>
            acc.concat(val.data.filter((d) => cats(d) && searchFilter(d))),
          []
        )
        .sort((a, b) => a.date.getTime() - b.date.getTime())
    );
  }

  /**
   * The component's render method
   */
  function renderTimeline() {
    return (
      <Box sx={{ backgroundColor: 'background.default' }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h4" style={{ marginRight: 15 }}>
              {t.Vis.TheTimeline}
            </Typography>
            <Typography variant="body2">
              {t.Vis.TheTimelineDesc}
            </Typography>
          </Grid>
          <Grid item>
            <SearchField />
          </Grid>
        </Grid>
        <Timeline data={generateTimelineData()} settings={getVisSettings()} />
        <Typography sx={{ fontSize: '0.7em', color: 'grey' }}>{t.Vis.Notice}</Typography>
        {renderButtons(VisTypes.Files, VisTypes.Timeline, VisTypes.Finalization)}
      </Box>
    );
  }

  function renderContent() {
    if (currentVis === VisTypes.Provider) {
      return <ProviderView />;
    } else if (currentVis === VisTypes.Files) {
      return (
        <>
          <Box textAlign="center">
            <Typography variant="h4">{t.Vis.TheFiles}</Typography>
            <Typography variant="body2">
              {t.Vis.TheFilesDesc}
            </Typography>
          </Box>
          <FileView
            selected={folderFilter.items}
            settings={getVisSettings()}
            selection={() => {
              // intentional
            }}
          />
          {renderButtons(VisTypes.Provider, VisTypes.Files, VisTypes.Timeline)}
        </>
      );
    } else if (currentVis === VisTypes.Timeline) {
      return renderTimeline();
    } else if (currentVis === VisTypes.Details) {
      return (
        <DetailView
          providerFiles={providerItems.reduce(
            (acc: ProviderFile[], val) =>
              val.active ? acc.concat(val.children) : acc,
            []
          )}
          private={transformOptions()['private'] as boolean}
          height={getVisSettings().height}
        />
      );
    } else if (currentVis === VisTypes.Sparklines) {
      return <SparkLinesVis settings={getVisSettings()} />;
    } else if (currentVis === VisTypes.Piechart) {
      return <>TODO</>;
    } else if (currentVis === VisTypes.Sunburst) {
      return <SunBurstVis settings={getVisSettings()} />;
    } else if (currentVis === VisTypes.Export) {
      return <ExportView />;
    } else if (currentVis === VisTypes.Finalization) {
      return <FinalizationView />;
    }
    return null;
  }

  function currentStepNumber(): number {
    switch (currentVis) {
      case VisTypes.Provider:
        return 0;
      case VisTypes.Files:
        return 1;
      case VisTypes.Timeline:
        return 2;
      default:
        return 3;
    }
    return 0;
  }

  function renderNavigation() {
    return (
      <Paper>
        <Stepper
          activeStep={currentStepNumber()}
          alternativeLabel={true}
          sx={{ padding: '20px 0' }}
        >
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
        </Stepper>
      </Paper>
    );
  }

  function renderButtons(prev: VisTypes, current: VisTypes, next: VisTypes) {
    return <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <Button
        variant="contained"
        onClick={() => {
          navigate('/visualization/?view=' + prev + '&tic=' + query.get('tic'));
          dispatch(optionsActions.switchVis(prev));
        }}
      >
        {t.Vis.PreviousPage}
      </Button>
      <Button
        variant="contained"
        disabled={isNextButtonDisabled()}
        onClick={() => {
          dispatch(trackVisited(currentVis));
          dispatch(track('viewsec', timeOnPage));
          setTimeOnPage(0);
          setStartedTime(Date.now());
          navigate(
            '/visualization/?view=' +
            next +
            '&tic=' +
            query.get('tic')
          );
          dispatch(optionsActions.switchVis(next));
        }}
      >
        {renderNextButtonText()}
      </Button>
    </Stack>;
  }

  function isNextButtonDisabled() {
    if (visited.includes(currentVis))
      return false;
    if (!twoQuestionChecked)
      return true;
    if (timeOnPage <= timeToNextPageInSeconds)
      return true;
    return false;
  }

  function renderNextButtonText() {
    if (visited.includes(currentVis))
      return t.Vis.NextPage;
    if (timeOnPage <= timeToNextPageInSeconds)
      return `${timeToNextPageInSeconds - timeOnPage} ${t.Vis.SecondsRemaining}`;
    if (!twoQuestionChecked)
      return t.Vis.AtLeast3;
    return t.Vis.NextPage;
  }

  return (
    <Box sx={{
      alignItems: 'center',
      background: theme.palette.background.default,
    }} ref={timelineRef}>
      {
        showSidebars &&
        <>
          <CollapseButton
            direction="left"
          />
          <SidebarLeft
            options={options}
            callback={(id: string, value: any) =>
              dispatch(optionsActions.selectItem(id, value))
            }
          />
          <SidebarRight />
        </>
      }
      <Box
        sx={{
          padding: theme.spacing(3),
          paddingBottom: 0,
          width: 'calc(100% - ' + (getSidebarSize() + 32) + 'px)',
          transition: '0ms cubic-bezier(0, 0, 0.2, 1) 0ms',
          marginLeft: getSidebarLeft() + 'px',
          marginRight: getSidebarRight()
        }}
      >
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            {renderNavigation()}
          </Grid>
          <Grid item xs={12}>
            {renderContent()}
          </Grid>
        </Grid>
      </Box>
      {/*
      <CollapseButton
        active={state.rightDrawerOpen}
        text='Selection'
        direction='right'
        onAnimationEnd={() => window.dispatchEvent(new CustomEvent('resize'))}
        callback={() => setState(prevState => {return {rightDrawerOpen: !prevState.rightDrawerOpen}})}
        />
      <RightSidebar
        active={state.rightDrawerOpen}
        vis={props.currentVis}
        colorType={transformOptions()["nodecolor"] as string}
      />
      <ModalHelper
        active={state.helpModalOpen}
        close={() => setState({helpModalOpen: false})}
        vis={props.currentVis}
        language={props.language}
      />
    */}
      <Modal open={stayOnPageModal} onClose={() => setStayOnPageModal(false)}>
        <Paper
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '30px 35px',
          }}
        >
          <Typography variant="h5">{t.Vis.StayLongerTitle}</Typography>
          <Typography variant="body1">{t.Vis.StayLongerText}</Typography>
          <br />
          <Button onClick={() => setStayOnPageModal(false)}>
            {t.Vis.StayLongerClose}
          </Button>
        </Paper>
      </Modal>
    </Box>
  );
};

export default VisPage;
