import React, { FC, useMemo } from "react";
import {
  Avatar,
  Button,
  Chip,
  Container,
  IconButton,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { ApplicationState } from "@store/index";
import { ContentCopy } from "@mui/icons-material";
import { Category, DataPoint, ProviderFile } from "providers/types";
import { UNIPARK_LINK } from "constants/link";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useTranslations from "containers/App/useLanguageHook";
import InfoIcon from "@mui/icons-material/Info";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CheckIcon from "@mui/icons-material/Check";
import { ProviderDatum } from "@store/providers/types";
import BrushableMultiLineChart, {
  TimeDataPoint,
  TimeSeries,
} from "containers/Visuals/MultiBarChart";
import { catColor } from "constants/color";
import Node from "containers/Components/SidebarComponents/Node";
import { ColorCategories } from "@store/categories/types";
import { selectItem } from "@store/categories/actions";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function formatDate(num: number): string {
  const d = new Date(num);
  return d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear();
}

function toStatisticsCsv(data: { [key: string]: [number, number][] }) {
  const amountOfFrames = Object.entries(data)[0][1].length || 365 * 2;
  const startDate = new Date(2022, 0, 1).getTime();
  const timeFrame = 1000 * 60 * 60 * 24; // one day
  const headers: string[] = [
    //'category',
    "subcategory",
    ...Array.from(
      { length: amountOfFrames },
      (_, i) => `${formatDate(startDate + i * timeFrame)} day${i}`
    ),
  ];
  const keys: string[] = Object.keys(data);
  let csv = headers.map((d) => d).join(",") + "\n";
  for (const line of keys) {
    csv +=
      line +
      "," +
      data[line].map(([, amount]: [number, number]) => amount).join(",") +
      "\n";
  }
  return csv;
}

function getAllDataPoints(items: ProviderDatum[]) {
  return items
    .reduce(
      (acc: ProviderFile[], val) =>
        val.active ? acc.concat(val.children) : acc,
      []
    )
    .reduce((acc: DataPoint[], val) => acc.concat(val.data), []);
}
function anonymizeSubcategory(name: string) {
  if (
    name.startsWith("F Chat") ||
    name.startsWith("I Chat") ||
    name.startsWith("I Messages") ||
    name.startsWith("I Nachrichten")
  )
    return "Chat";
  else return name.substring(2);
}

function within(min: number, num: number, max: number) {
  return Math.max(min, Math.min(num, max));
}

const FinalizationView: FC = () => {
  const { t } = useTranslations();
  const textFieldRef = React.useRef<HTMLDivElement | null>(null);
  const providerItems = useSelector((s: ApplicationState) => s.provider.items);
  const tracking = useSelector((s: ApplicationState) => s.tracking);
  const [isCopied, setIsCopied] = React.useState(false);
  const [showInfo, setShowInfo] = React.useState(false);
  const query = useQuery();
  const dispatch = useDispatch();
  const categoryColors = useSelector(
    (state: ApplicationState) => state.categories.catColors
  );

  const categoryMap = categoryColors.reduce<{ [key: string]: boolean }>(
    (result, { key, value }) => {
      result[key] = value;
      return result;
    },
    {}
  );

  const { statistics, timeSeries } = useMemo(() => {
    const startDate = new Date(2022, 0, 1).getTime();
    const timeFrame = 1000 * 60 * 60 * 24; // one day
    const now = new Date();
    const currentTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ).getTime();
    const today = currentTime - (currentTime % timeFrame);
    const diffDays = Math.round(
      Math.abs((startDate - currentTime) / timeFrame)
    );
    const oldStuff = Math.floor((today - startDate) / timeFrame);
    console.log(diffDays, oldStuff);
    const amountOfFrames = within(
      300,
      diffDays,
      //Math.floor((today - startDate) / timeFrame),
      1900
    );
    console.log("amountOfFrames ", amountOfFrames);

    const categoryTable: { [key: string]: Category } = {};
    const dataTable: { [key: string]: [number, number][] } = {};
    getAllDataPoints(providerItems).forEach((item) => {
      const subcategory = anonymizeSubcategory(item.subcategory);
      const key = subcategory;
      if (dataTable[key] === undefined) {
        dataTable[key] = Array.from(
          { length: amountOfFrames },
          (_, index: number) => {
            return [today - timeFrame * index, 0];
          }
        );
      }
      dataTable[key].forEach((weekInItem) => {
        if (
          item.date.getTime() >= weekInItem[0] &&
          item.date.getTime() <= weekInItem[0] + timeFrame
        ) {
          weekInItem[1] += 1;
        }
      });

      if (!categoryTable[subcategory]) {
        categoryTable[subcategory] = item.category;
      }
    });
    console.log(dataTable);

    const timeSeries: TimeSeries[] = [];
    const pointsByCategory: { [category: string]: { x: number; y: number }[] } =
      {};

    // Accumulate points for each category
    Object.entries(dataTable).forEach(([key, value]) => {
      const category = categoryTable[key];
      const points = value.map(([x, y]) => ({ x, y }));

      if (pointsByCategory[category]) {
        points.forEach((point, i) => {
          pointsByCategory[category][i].y += point.y;
        });
      } else {
        pointsByCategory[category] = points;
      }
    });

    // Convert accumulated points back to TimeSeries array format
    Object.entries(pointsByCategory)
      .filter((d) => categoryMap[d[0]])
      .forEach(([category, points]) => {
        const color = catColor(category);
        timeSeries.push({ category, color, points });
      });

    const statistics = toStatisticsCsv(dataTable);
    console.log(statistics);
    return { statistics, timeSeries };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryColors, providerItems]);

  const hash = useMemo(() => digestMessage(statistics), [statistics]);

  /*
  useEffect(() => {
    window.addEventListener('copy', (event) => {
      console.log("useEffect, copyied");
      navigator.clipboard.readText()
        .then(text => {
          console.log('useEffect, Pasted content: ', text);
        })
        .catch(err => {
          console.error('useEffect, Failed to read clipboard contents: ', err);
        });
      setIsCopied(!isCopied);
    });
  }, []);
  */

  function copyToClipboard() {
    try {
      if (!navigator.clipboard && textFieldRef) {
        textFieldRef.current?.focus();
        document.execCommand("copy");
      } else {
        navigator.clipboard.writeText(statistics);
      }
      setIsCopied(true);
    } catch (e) {
      console.error("Error while copying to clipboard:", e);
    }
  }

  function checkContents() {
    console.log("checking contents...");
    navigator.clipboard
      .readText()
      .then((text) => {
        console.log("useEffect, Pasted content: ", text);
        console.log(
          "is samesies?",
          digestMessage(text) === digestMessage(statistics)
        );
      })
      .catch((err) => {
        console.error("useEffect, Failed to read clipboard contents: ", err);
      });
  }

  function digestMessage(data: string): string {
    let hash = 0;
    if (data.length === 0) return "0000000000000000";
    for (let i = 0; i < data.length; i++) {
      hash = hash * 31 + data.charCodeAt(i);
      hash |= 0; // convert to 32bit int
    }
    return Math.abs(hash).toString(16).padStart(10, "0");
  }

  return (
    <Container maxWidth="lg">
      <Stack
        direction="column"
        spacing={3}
        alignItems="center"
        justifyContent="flex-start"
        pt={2}
        pb={2}
      >
        {/* INTRO TEXT */}
        <Typography variant="h4">
          <span
            dangerouslySetInnerHTML={{ __html: t.SurveyFinal.title }}
          ></span>
        </Typography>
        <Typography variant="body1">
          <span
            dangerouslySetInnerHTML={{ __html: t.SurveyFinal.instructionCopy }}
          ></span>
        </Typography>

        {/* INFO SECTION */}
        <IconButton onClick={() => setShowInfo(!showInfo)}>
          <InfoIcon />
        </IconButton>
        {showInfo && (
          <Container maxWidth="sm">
            <Paper sx={{ p: 1, m: 1 }}>
              <Typography variant="h5">
                {t.SurveyFinal.noticeQuestion}
              </Typography>
              <Typography variant="body1">{t.SurveyFinal.notice}</Typography>
            </Paper>
          </Container>
        )}

        {/* VISUALIZATION */}
        <Typography variant="h4">{t.SurveyFinal.StatisticDataVis}</Typography>
        <Stack direction={"row"} spacing={1}>
          {categoryColors.map((item) => (
            <Chip
              key={item.id}
              avatar={
                <Avatar
                  sx={{ bgcolor: item.value ? catColor(item.key) : "#666" }}
                >
                  {" "}
                </Avatar>
              }
              label={t.Categories[item.key as Category]}
              clickable
              onClick={() =>
                dispatch(selectItem(ColorCategories.categories, item.id))
              }
              variant={item.value ? "outlined" : undefined}
            />
          ))}
        </Stack>
        <BrushableMultiLineChart data={timeSeries} width={1150} height={600} />

        {/* COPY STATISTIC TEXT FIELDS */}
        <TextField
          disabled
          multiline
          ref={textFieldRef}
          sx={{
            minWidth: 500,
          }}
          onCopy={() => {
            setIsCopied(true);
            //console.log("TextField, COPIED!", event.clipboardData.getData("text/plain"));
          }}
          minRows={10}
          maxRows={10}
          label={t.SurveyFinal.statsLabel}
          value={statistics}
        />

        <Button
          sx={{ transform: "translateY(12px)" }}
          onClick={() => copyToClipboard()}
          variant="contained"
          color={!isCopied ? "primary" : "info"}
        >
          <ContentCopy /> {t.SurveyFinal.copyContent}
          {isCopied && <CheckIcon />}
        </Button>

        {/*
        <Typography variant="body1">{digestMessage(statistics)}</Typography>

        <Typography>
          {JSON.stringify(tracking, null, 2)}
        </Typography> */}

        <Button
          rel="noopener noreferrer"
          target="_blank"
          href={
            UNIPARK_LINK +
            query.get("tic") +
            `&c_002=${hash}` +
            `&c_002=${tracking.treemap.hover}` +
            `&c_003=${tracking.treemap.viewsec}` +
            `&c_004=${tracking.treemap.checkbox}` +
            `&c_005=${tracking.treemap.filter}` +
            `&c_006=${tracking.timeline.hover}` +
            `&c_007=${tracking.timeline.viewsec}` +
            `&c_008=${tracking.timeline.checkbox}` +
            `&c_009=${tracking.timeline.filter}`
          }
          variant="contained"
          disabled={!isCopied}
        >
          <OpenInNewIcon /> {t.SurveyFinal.backToSurvey}
        </Button>
      </Stack>
    </Container>
  );
};

export default FinalizationView;
