import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store/index';

import { Button, Box } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';

import { sidebar } from 'store/options/actions';
import { sidebarWidth } from 'constants/vis';

// Component-specific props.
interface ComponentProps {
  direction: 'left' | 'right'
}

export default function CollapseButton(props: ComponentProps) {
  const { direction } = props;
  const active = useSelector((state: ApplicationState) => state.options.sidebarOpen);
  const dispatch = useDispatch();

  return (
    <Box sx={{
      position: 'absolute',
      top: '69px',
      zIndex: 766,
      right: direction === 'right' ? 0 : sidebarWidth,
      left: direction === 'left' ? 0 : sidebarWidth,
      height: '50px',
      width: '64px',
      textAlign: 'left',
      transition: '250ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      transform: props.direction === 'left' ?
        (active ? 'translateX(' + sidebarWidth + 'px)' : 'none') :
        (active ? 'translateX(-' + sidebarWidth + 'px)' : 'none'),
    }} >
      <Button
        focusRipple
        variant="contained"
        sx={{ height: 50 }}
        onClick={() => dispatch(sidebar())}>
        <Box sx={{
          paddingTop: '5px',
          paddingLeft: '10px',
          fontSize: '2em',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          transition: '250ms linear 0ms',
          transform: direction === 'left' ?
            (!active ? 'rotate(-180deg)' : 'none') :
            (!active ? 'rotate(0deg)' : 'none'),
        }}>
          <ArrowBackIos />
        </Box>
      </Button>
    </Box>
  );
}
