import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto';
import '@fontsource/fira-sans-condensed';
import '@fontsource/open-sans';
import '@fontsource/raleway';
import '@fontsource/alex-brush';

import { ApplicationState } from 'store/index';

// Extend MuiTheme with custom properties in the Typescript way!
// Example code for a custom color integration into the material theme
declare module '@mui/material/styles' {
  interface Theme {
    neutral: {
      light: string;
      main: string;
      dark: string;
    };
  }
  // Allow configuration using `createTheme`
  interface ThemeOptions {
    neutral?: {
      light?: string;
      main?: string;
      dark?: string;
    };
  }

  interface TypographyVariants {
    code: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    code?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    code: true;
  }
}
/*********************************************************************
 *                          The dark theme
 ********************************************************************/

const origTheme = createTheme({
  // Define primary and secondary color
  palette: {
    mode: 'dark',
    primary: {
      main: '#5FAF77',
    },
    secondary: {
      main: '#55acff',
    },
    info: {
      main: '#fff',
    },
    success: {
      main: '#22DF22',
    },
    background: {
      default: '#303030',
      paper: '#424242',
    },
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontFamily: 'Fira Sans Condensed',
      fontSize: '3.75rem',
    },
    h2: {
      fontFamily: 'Fira Sans Condensed',
      fontSize: '3rem',
    },
    h3: {
      fontFamily: 'Fira Sans Condensed',
      fontSize: '2.5rem',
    },
    h4: {
      fontFamily: 'Fira Sans Condensed',
      fontSize: '2rem',
    },
    h5: {
      fontFamily: 'Fira Sans Condensed',
      fontSize: '1.5rem',
    },
    h6: {
      fontFamily: 'Fira Sans Condensed',
      fontSize: '1.25rem',
    },
    button: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '0.9rem',
    },
    code: {
      fontFamily: 'monospace',
      fontSize: '0.75rem',
      whiteSpace: 'break-spaces',
    },
  },

  // global style override of a mui component

  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#7daee1',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: '#303030',
          color: '#fff',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '0.7em',
          },
          '*::-webkit-scrollbar-track': {
            WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.4)',
            outline: '1px solid slategrey',
          },
        },
      },
    },
  },
});

/*********************************************************************
 *                          The dark theme
 ********************************************************************/

const darkTheme = createTheme({
  // Define primary and secondary color
  palette: {
    mode: 'dark',
    primary: {
      main: '#5FAF77',
    },
    secondary: {
      main: '#528593',
    },
    info: {
      main: '#fff',
    },
    success: {
      main: '#FFE796',
    },
    warning: {
      main: '#ED8F80',
    },
    background: {
      default: '#303030',
      paper: '#424242',
    },
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontFamily: 'Fira Sans Condensed',
      fontSize: '3.75rem',
    },
    h2: {
      fontFamily: 'Fira Sans Condensed',
      fontSize: '3rem',
    },
    h3: {
      fontFamily: 'Fira Sans Condensed',
      fontSize: '2.5rem',
    },
    h4: {
      fontFamily: 'Fira Sans Condensed',
      fontSize: '2rem',
    },
    h5: {
      fontFamily: 'Fira Sans Condensed',
      fontSize: '1.5rem',
    },
    h6: {
      fontFamily: 'Fira Sans Condensed',
      fontSize: '1.25rem',
    },
    button: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '0.9rem',
    },
    code: {
      fontFamily: 'monospace',
      fontSize: '0.75rem',
      whiteSpace: 'break-spaces',
    },
  },

  // global style override of a mui component

  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#7daee1',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: '#303030',
          color: '#fff',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '0.7em',
          },
          '*::-webkit-scrollbar-track': {
            WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.4)',
            outline: '1px solid slategrey',
          },
        },
      },
    },
  },
});

/*********************************************************************
 *                          The light theme
 ********************************************************************/

const lightTheme = createTheme({
  // Define primary and secondary color
  palette: {
    mode: 'light',
    primary: {
      main: '#5FAF77', //'#0B2F95'
    },
    secondary: {
      main: '#e26123',
    },
    info: {
      main: '#ccc',
    },
    success: {
      main: '#9b3c67',
    },
    /*
    background: {
      //default: '#fafafa',
      //paper: '#fff',
    },
    text: {
      //primary: 'rgba(0,0,0,1)',
      //secondary: 'rgba(0,0,0,0.6)',
      //disabled: 'rgba(0,0,0,0.4)',
    },
    */
  },
  neutral: {
    main: '#666',
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontFamily: 'Fira Sans Condensed',
      fontSize: '3.75rem',
    },
    h2: {
      fontFamily: 'Fira Sans Condensed',
      fontSize: '3rem',
    },
    h3: {
      fontFamily: 'Fira Sans Condensed',
      fontSize: '2.5rem',
    },
    h4: {
      fontFamily: 'Fira Sans Condensed',
      fontSize: '2rem',
    },
    h5: {
      fontFamily: 'Fira Sans Condensed',
      fontSize: '1.5rem',
    },
    h6: {
      fontFamily: 'Fira Sans Condensed',
      fontSize: '1.25rem',
    },
    button: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '0.9rem',
    },
    code: {
      fontFamily: 'monospace',
      fontSize: '0.75rem',
      whiteSpace: 'break-spaces',
    },
  },

  // global style override of a mui component

  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          // color: "#7daee1",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorInherit: {
          //  backgroundColor: '#303030',
          // color: '#000',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '0.7em',
          },
          '*::-webkit-scrollbar-track': {
            WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.4)',
            outline: '1px solid slategrey',
          },
        },
      },
    },
  },
});

const MyThemeProvider = (props: { children: React.ReactNode }) => {
  const type = useSelector(
    (state: ApplicationState) => state.applicationLayout.type
  );

  function getTheme() {
    if (type === 'dark') return origTheme;
    else return lightTheme;
  }

  return (
    <ThemeProvider theme={getTheme()}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
};

export default MyThemeProvider;
