import Provider from '../default/Provider';
import uint8ToObj from 'utils/uint8ToObj';
import { beautifyArray } from 'utils/array';
import { Category, DataPoint, toDataPoint } from 'providers/types';

type AdvertisersUsingYour = {
  ig_custom_audiences_all_types: IgCustomAudiencesAllTypes[]
}
type IgCustomAudiencesAllTypes = {
  advertiser_name: string,
  has_data_file_custom_audience: boolean,
  has_remarketing_custom_audience: boolean,
  has_in_person_store_visit: boolean
}

type AccountsYoureNotInterestedIn = {
  impressions_history_recs_hidden_authors: ImpressionsHistoryRecsHiddenAuthors[]
}
type ImpressionsHistoryRecsHiddenAuthors = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}


type IgtvInformation = {
  profile_IGTV: ProfileIGTV[]
}
type ProfileIGTV = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type AdsClicked = {
  impressions_history_ads_clicked: ImpressionsHistoryAdsClicked[]
}
type ImpressionsHistoryAdsClicked = {
  title: string,
  string_list_data: [StringListDataTimestamp]
}
type StringListDataTimestamp = {
  timestamp: number
}
type AdsViewed = {
  impressions_history_ads_seen: ImpressionsHistoryAdsSeen[]
}
type ImpressionsHistoryAdsSeen = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type PostsViewed = {
  impressions_history_posts_seen: ImpressionsHistoryPostsSeen[]
}
type ImpressionsHistoryPostsSeen = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type SuggestedAccountsViewed = {
  impressions_history_chaining_seen: ImpressionsHistoryChainingSeen[]
}
type ImpressionsHistoryChainingSeen = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type VideosWatched = {
  impressions_history_videos_watched: ImpressionsHistoryVideosWatched[]
}
type ImpressionsHistoryVideosWatched = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type PersonalInformation = {
  profile_user: ProfileUser[]
}
type ProfileUser = {
  title: string,
  media_map_data: {
    'Profile Photo': {
      uri: string,
      creation_timestamp: number,
      title: string
    }
  },
  string_map_data: StringMapData
}
type ActiveApps = {
  apps_and_websites_active_apps: AppsAndWebsitesActiveApps[]
}
type AppsAndWebsitesActiveApps = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type ExpiredApps = {
  apps_and_websites_expired_apps: AppsAndWebsitesExpiredApps[]
}
type AppsAndWebsitesExpiredApps = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type CommentsReported = {
  comments_comments_reported: CommentsCommentsReported[]
}
type CommentsCommentsReported = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}
type LiveVideoComments = {
  comments_live_comments: CommentsLiveComments[]
}
type CommentsLiveComments = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}
type PostComments = {
  comments_media_comments: CommentsMediaComments[]
}
type CommentsMediaComments = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}

type ArchivedPosts = {
  ig_archived_post_media: IgArchivedPostMedia[]
}
type IgArchivedPostMedia = {
  media: Media[],
  title?: string,
  creation_timestamp?: number
}
type Media = {
  uri: string,
  creation_timestamp: number,
  title: string,
  media_metadata?: MediaMetadata
}
type MediaMetadata = {
  photo_metadata?: {
    exif_data: ExifData[]
  },
  video_metadata?: {
    exif_data: ExifData[]
  }
}
type Stories = {
  ig_stories: IgStories[]
}
type IgStories = {
  uri: string,
  creation_timestamp: number,
  title: string,
  media_metadata?: MediaMetadata
}

type ExifData = ExifLotLang | ExifDevice

type ExifLotLang = {
  latitude: number,
  longitude: number
}
type ExifDevice = {
  device_id: string,
  source_type: string,
  camera_position?: string,
  date_time_original?: string,
  software?: string,
  scene_capture_type?: string
}

type Posts = Post[]
type Post = {
  media: Media[],
  title?: string,
  creation_timestamp?: number
}
type ProfilePhoto = {
  ig_profile_picture: IgProfilePicture[]
}
type IgProfilePicture = {
  uri: string,
  creation_timestamp: number,
  title: string,
  is_profile_picture: boolean
}

type Devices = {
  devices_devices: DevicesDevices[]
}
type DevicesDevices = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}

type BlockedAccounts = {
  relationships_blocked_users: RelationshipsBlockedUsers[]
}
type RelationshipsBlockedUsers = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}
type Followers = {
  relationships_followers: RelationshipsFollowers[]
}
type RelationshipsFollowers = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}
type Following = {
  relationships_following: RelationshipsFollowing[]
}
type RelationshipsFollowing = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}
type FollowingHashtags = {
  relationships_following_hashtags: RelationshipsFollowingHashtags[]
}
type RelationshipsFollowingHashtags = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}
type HideStoriesFrom = {
  relationships_hide_stories_from: RelationshipsHideStoriesFrom[]
}
type RelationshipsHideStoriesFrom = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}
type RestrictedAccounts = {
  relationships_restricted_users: RelationshipsRestrictedUsers[]
}
type RelationshipsRestrictedUsers = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}
type UnfollowedAccounts = {
  relationships_unfollowed_users: RelationshipsUnfollowedUsers[]
}
type RelationshipsUnfollowedUsers = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}
type RemovedSuggestions = {
  relationships_dismissed_suggested_users: RelationshipsDismissedSuggestedUsers[]
}
type RelationshipsDismissedSuggestedUsers = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}
type CloseFriends = {
  relationships_close_friends: RelationshipsCloseFriends[]
}
type RelationshipsCloseFriends = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}
type Guides = {
  guides_guides: GuidesGuides[]
}
type GuidesGuides = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type AccountsBasedIn = {
  inferred_data_primary_location: InferredDataPrimaryLocation[]
}
type InferredDataPrimaryLocation = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type AdsInterest = {
  inferred_data_ig_interest: InferredDataIgInterest[]
}
type InferredDataIgInterest = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type LikedComments = {
  likes_comment_likes: LikesCommentLikes[]
}
type LikesCommentLikes = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}
type LikedPosts = {
  likes_media_likes: LikesMediaLikes[]
}
type LikesMediaLikes = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}
type PrivacyChange = {
  account_history_account_privacy_history: AccountHistoryAccountPrivacyHistory[]
}
type AccountHistoryAccountPrivacyHistory = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type LoginActivity = {
  account_history_login_history: AccountHistoryLoginHistory[]
}
type AccountHistoryLoginHistory = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type LogoutActivity = {
  account_history_logout_history: AccountHistoryLogoutHistory[]
}
type AccountHistoryLogoutHistory = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type SignupInfo = {
  account_history_registration_info: AccountHistoryRegistrationInfo[]
}
type AccountHistoryRegistrationInfo = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type PasswordChangeHistory = {
  account_history_password_change_history: AccountHistoryPasswordChangeHistory[]
}
type AccountHistoryPasswordChangeHistory = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}

type Searches = {
  searches_user: SearchesUser[]
}
type SearchesUser = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type SavedCollections = {
  saved_saved_collections: SavedSavedCollections[]
}
type SavedSavedCollections = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type SavedPosts = {
  saved_saved_media: SavedSavedMedia[]
}
type SavedSavedMedia = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type Countdowns = {
  story_activities_countdowns: StoryActivitiesCountdowns[]
}
type StoryActivitiesCountdowns = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}
type StorySliders = {
  story_activities_emoji_sliders: StoryActivitiesEmojiSliders[]
}
type StoryActivitiesEmojiSliders = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}
type StoryPolls = {
  story_activities_polls: StoryActivitiesPolls[]
}
type StoryActivitiesPolls = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}
type StoryQuestions = {
  story_activities_questions: StoryActivitiesQuestions[]
}
type StoryActivitiesQuestions = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}
type StoryQuizzes = {
  story_activities_quizzes: StoryActivitiesQuizzes[]
}
type StoryActivitiesQuizzes = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}

type ProfileChange = {
  profile_profile_change: ProfileProfileChange[]
}
type ProfileProfileChange = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}

type Messages = {
  participants: Participants[],
  messages: Message[],
  title: string,
  is_still_participant: boolean,
  thread_type: string,
  thread_path: string,
  magic_words: any[]
}
type Participants = {
  name: string
}
type Message = {
  sender_name: string,
  timestamp_ms: number,
  type: string,
  is_unsent: boolean,
  content?: string,
  is_taken_down?: boolean,
  bumped_message_metadata?: {
    bumped_message: string,
    is_bumped: boolean
  },
  share?: {
    link: string,
    share_text?: string,
    original_content_owner?: string,
    profile_share_username?: string,
    profile_share_name?: string
  },
  reactions?: Reactions[],
  audio_files?: AudioFiles[],
  photos?: Photos[]
}
type Reactions = {
  reaction: string,
  actor: string
}
type AudioFiles = {
  uri: string,
  creation_timestamp: number
}
type Photos = {
  uri: string,
  creation_timestamp: number
}
type SyncedContacts = {
  contacts_contact_info: ContactsContactInfo[]
}
type ContactsContactInfo = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type CameraInformation = {
  devices_camera: DevicesCamera[]
}
type DevicesCamera = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type TwoFactorAuth = {
  devices_two_factor_authentication: DevicesTwoFactorAuthentication[]
}
type DevicesTwoFactorAuthentication = {
  string_map_data: StringMapData
}
type FollowRequests = {
  relationships_permanent_follow_requests: RelationshipsPermanentFollowRequests[]
}
type RelationshipsPermanentFollowRequests = {
  title: string,
  media_list_data: any[],
  string_list_data: StringListData[]
}
type RecentlyViewedItems = {
  checkout_saved_recently_viewed_products: CheckoutSavedRecentlyViewedProducts[]
}
type CheckoutSavedRecentlyViewedProducts = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type WishlistItems = {
  checkout_saved_products: CheckoutSavedProducts[]
}
type CheckoutSavedProducts = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type YourReelTopics = {
  topics_your_reels_topics: TopicsYourReelsTopics[]
}
type TopicsYourReelsTopics = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type YourReelSentiments = {
  topics_your_reels_emotions: TopicsYourReelsEmotions[]
}
type TopicsYourReelsEmotions = {
  title: string,
  media_map_data: {},
  string_map_data: StringMapData
}
type YourTopics = {
  topics_your_topics: TopicsYourTopics[]
}
type TopicsYourTopics = {
  title: string,
  media_map_data: {},
  string_map_data: {
    Name: {
      href: string,
      value: string,
      timestamp: number
    }
  }
}




//######################################################
type StringListData = {
  href: string,
  value: string,
  timestamp: number
}
type StringMapData = {
  [key: string]: StringListData
}

function getListTimestamp(d: StringListData[]): number {
  let res = 0;
  for (const s of d) {
    if (s.timestamp !== 0 && !isNaN(new Date(s.timestamp * 1000).getTime())) {
      if (res < s.timestamp)
        res = s.timestamp;
    }
  }
  return res;
}

function stringifyList(d: StringListData[]): string {
  let res = '';
  for (const s of d) {
    res += 'Link: ' + s.href + '\n';
    res += 'Value: ' + s.value + '\n';
  }
  return res;
}

function getMapTimestamp(d: StringMapData): number {
  let res = 0;
  for (const s in d) {
    if (d[s].timestamp !== 0 && !isNaN(new Date(d[s].timestamp * 1000).getTime())) {
      if (res < d[s].timestamp)
        res = d[s].timestamp;
    }
  }
  return res;
}

function stringifyMap(d: StringMapData): string {
  let res = '';
  for (const s in d) {
    // Either only one value is set or all three
    if (d[s].href === '' || d[s].value === '' || d[s].timestamp === 0) {
      const item = d[s].href !== '' ? d[s].href :
        (d[s].value !== '' ? d[s].value :
          (d[s].timestamp === 0 ? '' : new Date(d[s].timestamp * 1000).toLocaleDateString()));
      res += s + ': ' + item + '\n';
    } else {
      res += s + ': ' + d[s].value + '\n'
        + 'Href' + ': ' + d[s].href + '\n'
        + 'Timestamp' + ': ' + d[s].timestamp + '\n';
    }
  }
  return res;
}

function getUriFromMap(d: StringMapData) {
  let res;
  for (const s in d) {
    if (d[s].href !== '' && d[s].href !== undefined) {
      res = d[s].href;
    }
  }
  return res;
}

function getMediaAsText(d: MediaMetadata | undefined): string {
  let res = '';
  if (d) {
    if (d.photo_metadata) {
      res = '\nPhoto Media Metadata:\n';
      for (const s of d.photo_metadata.exif_data) {
        res += stringifyObject(s);
      }
    }
    if (d.video_metadata) {
      res = '\nVideo Media metadata:\n';
      for (const s of d.video_metadata.exif_data) {
        res += stringifyObject(s);
      }
    }
  }
  return res;
}

function transformToTitleCase(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function stringifyObject(obj: any) {
  return Object
    .entries(obj)
    .map(d => transformToTitleCase(d[0].replace('_', ' ')) + ': ' + d[1])
    .join('\n');
}


function getTextfromMessage(d: Message): string {
  let result = d.sender_name + ': ';
  if (d.content)
    result += d.content;
  //if (d.type)
  //result += "\nMessage Type: " + d.type;
  if (d.is_unsent)
    result += '\nMessage is unsent.';
  if (d.photos && d.photos.length > 0)
    result += '\nSent a photo.';
  if (d.audio_files && d.audio_files.length > 0)
    result += '\nSent a audio file.';
  if (d.share)
    result += '\nShared Link: ' + d.share.link +
      (d.share.share_text && d.share.link !== d.share.share_text ? 'Share Text: ' + d.share.share_text : '') +
      (d.share.original_content_owner ? '\nOriginal Content Owner: ' + d.share.original_content_owner : '');
  if (d.reactions && d.reactions.length > 0) {
    result += '\n\nReactions: ' + d.reactions.map(d => d.reaction + ' (' + d.actor + ')').join(', ');
  }
  if (!d.content && d.type === 'Share' && !d.share && !d.audio_files && !d.photos)
    result += 'Mentioned you/them in their/your story';
  return result;
}

/*
    {
      "sender_name": "user",
      "timestamp_ms": 1189819560187,
      "type": "Share",
      "is_unsent": false,
      "is_taken_down": false,
      "bumped_message_metadata": {
        "bumped_message": "",
        "is_bumped": false
      }
    },

    => Mentioned you in their story
*/

function getUriFromMessage(d: Message): string | undefined {
  return d.photos?.map(d => d.uri)[0] || d.audio_files?.map(d => d.uri)[0];
}

export default class Instagram extends Provider {

  static fileStructure = ['ads_and_businesses/',
    'ads_and_topics/',
    'apps_and_websites/',
    'autofill_information/',
    'avatars_store/',
    'blockchain_accounts/',
    'comments/',
    'comments_settings/',
    'contacts/',
    'content/',
    'device_information/',
    'events/',
    'followers_and_following/',
    'fundraisers/',
    'guides/',
    'information_about_you/',
    'likes/',
    'login_and_account_creation/',
    'loyalty_accounts/',
    'media/',
    'messages/',
    'monetization/',
    'past_instagram_insights/',
    'personal_information/',
    'recent_searches/',
    'reports/',
    'saved/',
    'shopping/',
    'story_sticker_interactions/',
    'your_topics/'
  ];
  static optionalFileStructure = [
    'index.html',
    'account_information/',
    'ads_and_content/',
  ];

  static processFile(path: string, data: Uint8Array, warnCallback: (str: string) => void): Array<DataPoint> {
    let result: Array<DataPoint> = [];
    const p = path.toLowerCase().split('/');

    if (p[p.length - 1] === 'no-data.txt') {
      return [];
    }
    switch (p[0]) {
      case 'ads_and_businesses': {
        if (p[1] === 'advertisers_using_your_activity_or_information.json') {
          const res: AdvertisersUsingYour = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          return [toDataPoint(
            new Date('unknown'),
            'Advertisers List:' +
            res.ig_custom_audiences_all_types.map((d: IgCustomAudiencesAllTypes) =>
              d.advertiser_name + '\n'),
            Category.Activity,
            'I Advertisers Using Your Activity Or Information'
          )];
        }
        break;
      }
      case 'ads_and_topics': {
        if (p[1] === 'accounts_you\'re_not_interested_in.json') {
          const res: AccountsYoureNotInterestedIn = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.impressions_history_recs_hidden_authors
            .map((d: ImpressionsHistoryRecsHiddenAuthors) => toDataPoint(
              new Date(getMapTimestamp(d.string_map_data) * 1000),
              d.title + '\n' + stringifyMap(d.string_map_data),
              Category.Activity,
              'I Ads Viewed History',
              getUriFromMap(d.string_map_data)
            ));
          return result;
        } else if (p[1] === 'ads_clicked.json') {
          // Legacy: Does not exist anymore
          const res: AdsClicked = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.impressions_history_ads_clicked.map((d: ImpressionsHistoryAdsClicked) => toDataPoint(
            new Date(d.string_list_data[0].timestamp * 1000),
            'Ad Clicked: ' + d.title,
            Category.Activity,
            'I Ads Clicked Impression History'
          ));
          return result;
        }
        else if (p[1] === 'ads_viewed.json') {
          const res: AdsViewed = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.impressions_history_ads_seen.map((d: ImpressionsHistoryAdsSeen) => toDataPoint(
            new Date(getMapTimestamp(d.string_map_data) * 1000),
            d.title + '\n' + stringifyMap(d.string_map_data),
            Category.Activity,
            'I Ads Viewed History',
            getUriFromMap(d.string_map_data)
          ));
          return result;
        } else if (p[1] === 'posts_viewed.json') {
          const res: PostsViewed = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.impressions_history_posts_seen.map((d: ImpressionsHistoryPostsSeen) => toDataPoint(
            new Date(getMapTimestamp(d.string_map_data) * 1000),
            d.title + '\n' + stringifyMap(d.string_map_data),
            Category.Activity,
            'I Posts Viewed History',
            getUriFromMap(d.string_map_data)
          ));
          return result;

        } else if (p[1] === 'suggested_accounts_viewed.json') {
          const res: SuggestedAccountsViewed = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.impressions_history_chaining_seen.map((d: ImpressionsHistoryChainingSeen) => toDataPoint(
            new Date(getMapTimestamp(d.string_map_data) * 1000),
            d.title + '\n' + stringifyMap(d.string_map_data),
            Category.Activity,
            'I Suggested Accounts viewed',
            getUriFromMap(d.string_map_data)
          ));
          return result;

        } else if (p[1] === 'videos_watched.json') {
          // Legacy: Does not exist anymore
          const res: VideosWatched = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.impressions_history_videos_watched.map((d: ImpressionsHistoryVideosWatched) => toDataPoint(
            new Date(d.string_map_data.Time.timestamp * 1000),
            'Viewed a video by ' + d.string_map_data.Author.value,
            Category.Activity,
            'I Videos Watched'
          ));
          return result;
        }
        break;
      }
      case 'apps_and_websites': {
        if (p[1] === 'active_apps.json' || p[1] === 'active_pps.json') {
          const res: ActiveApps = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.apps_and_websites_active_apps.map((d: AppsAndWebsitesActiveApps) => toDataPoint(
            new Date(getMapTimestamp(d.string_map_data) * 1000),
            stringifyMap(d.string_map_data),
            Category.Account,
            'I Active Apps',
            getUriFromMap(d.string_map_data)
          ));
          return result;
        } else if (p[1] === 'expired_apps.json' || p[1] === 'expired_pps.json') {
          const res: ExpiredApps = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.apps_and_websites_expired_apps.map((d: AppsAndWebsitesExpiredApps) => toDataPoint(
            new Date(getMapTimestamp(d.string_map_data) * 1000),
            stringifyMap(d.string_map_data),
            Category.Account,
            'I Expired Apps',
            getUriFromMap(d.string_map_data)
          ));
          return result;
        }
        break;
      }
      case 'autofill_information': {
        // empty for me
        break;
      }
      case 'avatars_store': {
        // empty for me
        break;
      }
      case 'blockchain_accounts': {
        // empty for me
        break;
      }
      case 'comments': {
        if (p[1] === 'comments_reported.json') {
          const res: CommentsReported = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.comments_comments_reported.map((d: CommentsCommentsReported) => toDataPoint(
            new Date(d.string_list_data[0].timestamp * 1000),
            'Reported a comment of ' + d.title,
            Category.PostsAndComments,
            'I Comments Reported'
          ));
          return result;
        } else if (p[1] === 'live_video_comments.json') {
          const res: LiveVideoComments = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          for (const item of res.comments_live_comments) {
            result.push(...item.string_list_data.map(d => toDataPoint(
              new Date(d.timestamp * 1000),
              'You posted a live comment to: ' + d.value + ':\n' + item.title,
              Category.PostsAndComments,
              'I Live Video Comments'
            )));
          }
          return result;
        } else if (p[1] === 'post_comments.json') {
          const res: PostComments = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.comments_media_comments.map((d: CommentsMediaComments) => toDataPoint(
            new Date(getMapTimestamp(d.string_map_data) * 1000),
            stringifyMap(d.string_map_data),
            Category.PostsAndComments,
            'I Post Comments',
            getUriFromMap(d.string_map_data)
          ));
          return result;
        }
        break;
      }
      case 'comments_settings': {
        // TODO: just some settings
        if (p[1] === 'comments_allowed_from.json') {
          return [];
        } else if (p[1] === 'comments_blocked_from.json') {
          return [];
        } else if (p[1] === 'manual_filter.json') {
          return [];
        } else if (p[1] === 'use_cross-app_messaging.json') {
          return [];
        }
        break;
      }
      case 'contacts': {
        if (p[1] === 'synced_contacts.json') {
          const res: SyncedContacts = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.contacts_contact_info.map((d: ContactsContactInfo) => toDataPoint(
            new Date((getMapTimestamp(d.string_map_data) * 1000) || 'unknown'),
            stringifyMap(d.string_map_data),
            Category.Contacts,
            'I Synced Contacts',
            getUriFromMap(d.string_map_data)
          ));
          return result;
        }
        break;
      }
      case 'content': {
        if (p[1] === 'archived_posts.json') {
          const res: ArchivedPosts = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.ig_archived_post_media.map((d: IgArchivedPostMedia) => toDataPoint(
            new Date(d.creation_timestamp ? d.creation_timestamp * 1000 : d.media[0].creation_timestamp * 1000),
            (d.title ? 'Post Caption: ' + d.title + '\n' : '') +
            d.media.map(t => t.title === '' ? '' : 'Caption: ' + t.title).join('\n') + '\n' +
            d.media.map(m => getMediaAsText(m.media_metadata)),
            Category.PostsAndComments,
            'I Archived Posts',
            d.media.at(0)?.uri
          ));
          return result;
        } else if (p[1].startsWith('posts_') && p[1].endsWith('.json')) {
          const res: Posts = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.map((d: Post) => toDataPoint(
            new Date(d.creation_timestamp ? d.creation_timestamp * 1000 : d.media[0].creation_timestamp * 1000),
            (d.title ? 'Post Caption: ' + d.title + '\n' : '') +
            d.media.map(t => t.title === '' ? '' : 'Caption: ' + t.title).join('\n') + '\n' +
            d.media.map(m => getMediaAsText(m.media_metadata)),
            Category.PostsAndComments,
            'I Posts',
            d.media.at(0)?.uri
          ));
          return result;
        } else if (p[1] === 'profile_photos.json') {
          const res: ProfilePhoto = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.ig_profile_picture.map((d: IgProfilePicture) => toDataPoint(
            new Date(d.creation_timestamp * 1000),
            'Profile Photo ' + d.title +
            '\nUsed as profile picture? ' + (d.is_profile_picture ? 'Yes' : 'No'),
            Category.Account,
            'I Profile Photos',
            d.uri
          ));
          return result;
        } else if (p[1] === 'stories.json') {
          const res: Stories = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.ig_stories.map((d: IgStories) => toDataPoint(
            new Date(d.creation_timestamp * 1000),
            (d.title ? `Title: ${d.title}\n` : '') + getMediaAsText(d.media_metadata),
            Category.Stories,
            'I Stories',
            d.uri
          ));
          return result;
        }

        break;
      }
      case 'device_information': {
        if (p[1] === 'camera_information.json') {
          const res: CameraInformation = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          const result = toDataPoint(
            new Date(),
            res.devices_camera.map((d: DevicesCamera) => stringifyMap(d.string_map_data)).join('\n'),
            Category.Security,
            'I Camera Information'
          );
          return [result];
        } else if (p[1] === 'devices.json') {
          const res: Devices = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.devices_devices.map((d: DevicesDevices) => toDataPoint(
            new Date(getMapTimestamp(d.string_map_data) * 1000),
            stringifyMap(d.string_map_data),
            Category.Security,
            'I Devices',
            getUriFromMap(d.string_map_data)
          ));
          return result;
        } else if (p[1] === 'two-factor_authentication.json') {
          const res: TwoFactorAuth = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          return [toDataPoint(
            new Date(),
            res.devices_two_factor_authentication
              .map((d: DevicesTwoFactorAuthentication) => stringifyMap(d.string_map_data)).join('\n'),
            Category.Security,
            'I Camera Information'
          )];
        }
        break;
      }
      case 'events': {
        // Empty for me
        break;
      }
      case 'followers_and_following': {
        if (p[1] === 'blocked_accounts.json') {
          const res: BlockedAccounts = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.relationships_blocked_users.map((d: RelationshipsBlockedUsers) => toDataPoint(
            new Date(getListTimestamp(d.string_list_data) * 1000),
            stringifyList(d.string_list_data),
            Category.Contacts,
            'I Blocked Users'
          ));
          return result;
        } else if (p[1] === 'close_friends.json') {
          const res: CloseFriends = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.relationships_close_friends.map((d: RelationshipsCloseFriends) => toDataPoint(
            new Date(getListTimestamp(d.string_list_data) * 1000),
            stringifyList(d.string_list_data),
            Category.Contacts,
            'I Close Friends'
          ));
          return result;
        } else if (p[1] === 'followers.json') {
          const res: Followers = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.relationships_followers.map((d: RelationshipsFollowers) => toDataPoint(
            new Date(getListTimestamp(d.string_list_data) * 1000),
            stringifyList(d.string_list_data),
            Category.Contacts,
            'I Followers'
          ));
          return result;
        } else if (p[1] === 'following.json') {
          const res: Following = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.relationships_following.map((d: RelationshipsFollowing) => toDataPoint(
            new Date(getListTimestamp(d.string_list_data) * 1000),
            stringifyList(d.string_list_data),
            Category.Contacts,
            'I Following'
          ));
          return result;
        } else if (p[1] === 'following_hashtags.json') {
          const res: FollowingHashtags = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.relationships_following_hashtags.map((d: RelationshipsFollowingHashtags) => toDataPoint(
            new Date(getListTimestamp(d.string_list_data) * 1000),
            stringifyList(d.string_list_data),
            Category.Contacts,
            'I Following Hashtags'
          ));
          return result;
        } else if (p[1] === 'hide_story_from.json') {
          const res: HideStoriesFrom = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.relationships_hide_stories_from.map((d: RelationshipsHideStoriesFrom) => toDataPoint(
            new Date(getListTimestamp(d.string_list_data) * 1000),
            stringifyList(d.string_list_data),
            Category.Contacts,
            'I Hide Stories From'
          ));
          return result;
        } else if (p[1] === 'recent_follow_requests.json') {
          const res: FollowRequests = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.relationships_permanent_follow_requests.map((d: RelationshipsPermanentFollowRequests) => toDataPoint(
            new Date(getListTimestamp(d.string_list_data) * 1000),
            stringifyList(d.string_list_data),
            Category.Contacts,
            'I Recent Follow Requests'
          ));
          return result;
        } else if (p[1] === 'removed_suggestions.json') {
          const res: RemovedSuggestions = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.relationships_dismissed_suggested_users.map((d: RelationshipsUnfollowedUsers) => toDataPoint(
            new Date(getListTimestamp(d.string_list_data) * 1000),
            stringifyList(d.string_list_data),
            Category.Contacts,
            'I Removed Followers Suggestions'
          ));
          return result;
        } else if (p[1] === 'restricted_accounts.json') {
          const res: RestrictedAccounts = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.relationships_restricted_users.map((d: RelationshipsRestrictedUsers) => toDataPoint(
            new Date(getListTimestamp(d.string_list_data) * 1000),
            stringifyList(d.string_list_data),
            Category.Contacts,
            'I Restricted Accounts'
          ));
          return result;
        } else if (p[1] === 'unfollowed_accounts.json') {
          const res: UnfollowedAccounts = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.relationships_unfollowed_users.map((d: RelationshipsUnfollowedUsers) => toDataPoint(
            new Date(getListTimestamp(d.string_list_data) * 1000),
            stringifyList(d.string_list_data),
            Category.Contacts,
            'I Unfollowed Accounts'
          ));
          return result;
        }
        break;
      }
      case 'fundraisers': {
        // Empty for me
        break;
      }
      case 'guides': {
        if (p[1] === 'guides.json') {
          const res: Guides = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.guides_guides.map((d: GuidesGuides) => toDataPoint(
            new Date(getMapTimestamp(d.string_map_data) * 1000),
            d.title + '\n' + stringifyMap(d.string_map_data),
            Category.PostsAndComments,
            'I Guides',
            getUriFromMap(d.string_map_data)
          ));
          return result;
        }
        break;
      }
      case 'information_about_you': {
        if (p[1] === 'account_based_in.json') {
          const res: AccountsBasedIn = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.inferred_data_primary_location.map((d: InferredDataPrimaryLocation) => toDataPoint(
            new Date('unknown'),
            stringifyMap(d.string_map_data),
            Category.Account,
            'I Inferred Account Location',
            getUriFromMap(d.string_map_data)
          ));
          return result;
        } else if (p[1] === 'ads_interests.json') {
          const res: AdsInterest = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.inferred_data_ig_interest.map((d: InferredDataIgInterest) => toDataPoint(
            new Date('unknown'),
            stringifyMap(d.string_map_data),
            Category.Account,
            'I Inferred Ads Interests',
            getUriFromMap(d.string_map_data)
          ));
          return result;
        }
        break;
      }
      case 'likes': {
        if (p[1] === 'liked_comments.json') {
          const res: LikedComments = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.likes_comment_likes.map((d: LikesCommentLikes) => toDataPoint(
            new Date(getListTimestamp(d.string_list_data) * 1000),
            'Username: ' + d.title + '\n' +
            stringifyList(d.string_list_data),
            Category.Likes,
            'I Liked Comments'
          ));
          return result;
        } else if (p[1] === 'liked_posts.json') {
          const res: LikedPosts = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.likes_media_likes.map((d: LikesMediaLikes) => toDataPoint(
            new Date(getListTimestamp(d.string_list_data) * 1000),
            'Username: ' + d.title + '\n' +
            stringifyList(d.string_list_data),
            Category.Likes,
            'I Liked Posts'
          ));
          return result;
        }
        break;
      }
      case 'login_and_account_creation': {
        if (p[1] === 'account_privacy_changes.json') {
          const res: PrivacyChange = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.account_history_account_privacy_history.map((d: AccountHistoryAccountPrivacyHistory) => toDataPoint(
            new Date(getMapTimestamp(d.string_map_data) * 1000),
            d.title,
            Category.Security,
            'I Account Privacy Change'
          ));
          return result;
        } else if (p[1] === 'login_activity.json') {
          const res: LoginActivity = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.account_history_login_history.map((d: AccountHistoryLoginHistory) => toDataPoint(
            new Date(getMapTimestamp(d.string_map_data) * 1000),
            stringifyMap(d.string_map_data),
            Category.Security,
            'I Login Activity',
            getUriFromMap(d.string_map_data)
          ));
          return result;
        } else if (p[1] === 'logout_activity.json') {
          const res: LogoutActivity = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.account_history_logout_history.map((d: AccountHistoryLogoutHistory) => toDataPoint(
            new Date(getMapTimestamp(d.string_map_data) * 1000),
            stringifyMap(d.string_map_data),
            Category.Security,
            'I Logout Activity',
            getUriFromMap(d.string_map_data)
          ));
          return result;
        } else if (p[1] === 'signup_information.json') {
          const res: SignupInfo = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.account_history_registration_info.map((d: AccountHistoryRegistrationInfo) => toDataPoint(
            new Date(getMapTimestamp(d.string_map_data) * 1000),
            stringifyMap(d.string_map_data),
            Category.Account,
            'I Signup Information',
            getUriFromMap(d.string_map_data)
          ));
          return result;
        } else if (p[1] === 'password_change_activity.json') {
          const res: PasswordChangeHistory = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.account_history_password_change_history.map((d: AccountHistoryPasswordChangeHistory) => toDataPoint(
            new Date(getMapTimestamp(d.string_map_data) * 1000),
            stringifyMap(d.string_map_data),
            Category.Security,
            'I Password Change Activity',
            getUriFromMap(d.string_map_data)
          ));
          return result;
        }
        break;
      }
      case 'loyalty_accounts': {
        // empty for me
        break;
      }
      case 'media': {
        // only images
        break;
      }
      case 'messages': {
        const re = new RegExp(/(message.*\.json)/);
        if (re.test(p[3])) {
          const res: Messages = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          const subcategory = beautifyArray(res.participants.map(d => d.name));
          result = res.messages.map((d: Message) => toDataPoint(
            new Date(d.timestamp_ms),
            getTextfromMessage(d),
            Category.Messages,
            'I Messages of ' + subcategory,
            getUriFromMessage(d)
          ));
          return result;
        } else if (p[1] === 'secret_groups.json') {
          // Empty for me
          break;
        }
        break;
      }
      case 'monetization': {
        if (p[1] === 'eligibility.json') {
          // no timestamp
          return [];
        }
        break;
      }
      case 'past_instagram_insights': {
        // empty for me
        break;
      }
      case 'personal_information': {
        switch (p[1]) {
          case 'account_information.json': {
            // some statistics of first login, first story, ...
            return [];
          }
          case 'igtv_information.json': {
            // Not included for me anymore
            const res: IgtvInformation = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
            result = res.profile_IGTV.map((d: ProfileIGTV) => toDataPoint(
              new Date('unknown'),
              d.title + '\n' + JSON.stringify(d.string_map_data),
              Category.Contacts,
              'I IGTV'
            ));
            return result;
          }
          case 'personal_information.json': {
            const res: PersonalInformation = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
            result = res.profile_user.map((d: ProfileUser) => toDataPoint(
              new Date(d.media_map_data['Profile Photo'].creation_timestamp * 1000 || 'unknown'),
              d.title + '\n' + stringifyMap(d.string_map_data),
              Category.Account,
              'I Personal Information',
              d.media_map_data['Profile Photo'].uri
            ));
            return result;
          }
          case 'professional_information.json': {
            // empty for me
            break;
          }
          case 'profile_changes.json': {
            const res: ProfileChange = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
            result = res.profile_profile_change.map((d: ProfileProfileChange) => toDataPoint(
              new Date(getMapTimestamp(d.string_map_data) * 1000 || 'unknown'),
              d.title + '\n' + stringifyMap(d.string_map_data),
              Category.Account,
              'I Profile Change',
              getUriFromMap(d.string_map_data)
            ));
            return result;
          }
        }
        break;
      }
      case 'recent_searches': {
        if (p[1] === 'account_searches.json') {
          const res: Searches = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.searches_user.map((d: SearchesUser) => toDataPoint(
            new Date(getMapTimestamp(d.string_map_data) * 1000),
            stringifyMap(d.string_map_data),
            Category.Activity,
            'I Searches',
            getUriFromMap(d.string_map_data)
          ));
          return result;
        }
        break;
      }
      case 'reports': {
        // empty for me
        break;
      }
      case 'saved': {
        if (p[1] === 'saved_collections.json') {
          const res: SavedCollections = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.saved_saved_collections.map((d: SavedSavedCollections) => toDataPoint(
            new Date(getMapTimestamp(d.string_map_data) * 1000),
            d.title + '\n' +
            stringifyMap(d.string_map_data),
            Category.Activity,
            'I Saved Collections',
            getUriFromMap(d.string_map_data)
          ));
          return result;
        } else if (p[1] === 'saved_posts.json') {
          const res: SavedPosts = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.saved_saved_media.map((d: SavedSavedMedia) => toDataPoint(
            new Date(getMapTimestamp(d.string_map_data) * 1000),
            stringifyMap(d.string_map_data),
            Category.Activity,
            'I Saved Posts',
            getUriFromMap(d.string_map_data)
          ));
          return result;
        }
        break;
      }
      case 'shopping': {
        if (p[1] === 'checkout_information.json') {
          // no timestamps
          return [];
        } else if (p[1] === 'recently_viewed_items.json') {
          const res: RecentlyViewedItems = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.checkout_saved_recently_viewed_products
            .map((d: CheckoutSavedRecentlyViewedProducts) => toDataPoint(
              new Date('unknown'),
              stringifyMap(d.string_map_data),
              Category.Activity,
              'I Recently Viewed Shopping Items',
              getUriFromMap(d.string_map_data)
            ));
          return result;
        } else if (p[1] === 'wishlist_items.json') {
          const res: WishlistItems = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.checkout_saved_products
            .map((d: CheckoutSavedProducts) => toDataPoint(
              new Date('unknown'),
              stringifyMap(d.string_map_data),
              Category.Activity,
              'I Saved Wishlisht Shopping Products',
              getUriFromMap(d.string_map_data)
            ));
          return result;
        }
        break;
      }
      case 'story_sticker_interactions': {
        if (p[1] === 'countdowns.json') {
          const res: Countdowns = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.story_activities_countdowns.map((d: StoryActivitiesCountdowns) => toDataPoint(
            new Date(getListTimestamp(d.string_list_data) * 1000),
            'Title: ' + d.title,
            Category.Stories,
            'I Story Countdowns'
          ));
          return result;
        } else if (p[1] === 'emoji_sliders.json') {
          const res: StorySliders = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.story_activities_emoji_sliders.map((d: StoryActivitiesEmojiSliders) => toDataPoint(
            new Date(getListTimestamp(d.string_list_data) * 1000),
            'Username: ' + d.title,
            Category.Stories,
            'I Story Emoji Sliders'
          ));
          return result;
        } else if (p[1] === 'polls.json') {
          const res: StoryPolls = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.story_activities_polls.map((d: StoryActivitiesPolls) => toDataPoint(
            new Date(getListTimestamp(d.string_list_data) * 1000),
            'Username: ' + d.title,
            Category.Stories,
            'I Story Polls'
          ));
          return result;
        } else if (p[1] === 'questions.json') {
          const res: StoryQuestions = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.story_activities_questions.map((d: StoryActivitiesQuestions) => toDataPoint(
            new Date(getListTimestamp(d.string_list_data) * 1000),
            'Username: ' + d.title,
            Category.Stories,
            'I Story Questions'
          ));
          return result;
        } else if (p[1] === 'quizzes.json') {
          const res: StoryQuizzes = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          result = res.story_activities_quizzes.map((d: StoryActivitiesQuizzes) => toDataPoint(
            new Date(getListTimestamp(d.string_list_data) * 1000),
            'Username: ' + d.title,
            Category.Stories,
            'I Story Quizzes'
          ));
          return result;
        }
        break;
      }
      case 'your_topics': {
        if (p[1] === 'your_reels_sentiments.json') {
          const res: YourReelSentiments = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          const str = res.topics_your_reels_emotions
            .map((d: TopicsYourReelsEmotions) => stringifyMap(d.string_map_data))
            .join('\n');

          return [
            toDataPoint(new Date('unkown'),
              str,
              Category.Account,
              'I Your Reels Emotions')
          ];
        } else if (p[1] === 'your_reels_topics.json') {
          const res: YourReelTopics = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          const str = res.topics_your_reels_topics
            .map((d: TopicsYourReelsTopics) => stringifyMap(d.string_map_data))
            .join('\n');

          return [
            toDataPoint(new Date('unkown'),
              str,
              Category.Account,
              'I Your Reels Topics')
          ];
        } else if (p[1] === 'your_topics.json') {
          const res: YourTopics = uint8ToObj.parse(data, { decodeStringsAsUtf8: true });
          const str = res.topics_your_topics
            .map((d: TopicsYourTopics) => stringifyMap(d.string_map_data))
            .join('\n');

          return [
            toDataPoint(new Date('unkown'),
              str,
              Category.Account,
              'I Your Topics')
          ];
        }
        break;
      }
      default:
        break;
    }
    warnCallback('Found unknown file or folder in Instagram data export: ' + path);
    return [];
  }
}